<template>
    <div class="dealer-list">
        <div class="row">
            <div class="col-12" v-html="secondary"></div>
        </div>

        <div v-if="!showContentPlaceholder && noDealers" class="row">
            <div class="col-12" v-html="noDealership"></div>
        </div>

        <div v-if="!showContentPlaceholder && !noDealers" class="dealer-list__card-list" data-cy="dealerList">

            <transition-group name="dealer-tiles" tag="section">
                <dealer-tile
                    v-for="(dealer, index) in showing"
                    :key="index"
                    :dealer="dealer"
                    :index="index"
                    :footnoteMap="footnoteMap"
                    @dealerSelected="selectDealer(dealer, index)"
                    class="row dealer-list__card"
                    data-cy="dealerCard"
                />
            </transition-group>

            <div class="row">
                <div class="col-12">
                    <pagination
                        :itemCount="locationList.length"
                        @paginate="paginateDealers"
                    />
                </div>
            </div>

            <div v-if="uniqueDisclaimers" class="row">
                <div class="col-12 dealer-list__financing-footer">
                    <ul v-for="(footnote, index) in uniqueDisclaimers" :key="index">
                        <li
                            v-html="
                                `<sup>${footnoteMap[footnote.code]}</sup>${footnote.disclaimer}`
                            "
                            class="finance-disclaimer"
                        ></li>
                    </ul>
                </div>
            </div>

            <div class="row">
                <div class="col-12" v-html="ad"></div>
            </div>
        </div>

        <div v-if="showContentPlaceholder" class="row">
            <div class="col-12 dealer__content-placeholder">
                <content-placeholder
                    v-for="(n, i) in 5"
                    :key="i"
                    :rows="placeholderRows"
                />
            </div>
        </div>
    </div>
</template>

<script>
import ContentPlaceholder from 'vue-content-placeholder';
import Pagination from '@/components/Pagination.vue';
import { mapState, mapActions, mapMutations } from 'vuex';
import DealerTile from '@/components/DealerTile.vue';
import { RudderstackDealersMixin } from '@/rudderstack/track/mixins/dealers';

export default {
	mixins: [RudderstackDealersMixin],

	components: {
		ContentPlaceholder,
		Pagination,
		DealerTile
	},

	filters: {
		dealerDistance(dealer) {
			return `${Number(dealer.distance).toFixed(1)} mi`;
		}
	},

	data() {
		return {
			dealer: {},
			footnoteMap: {},
			footnoteSymbols: ['*', '†', '‡', '§', '¶'],
			placeholderRows: [
				{
					height: '25px',
					boxes: [[0, '100px']]
				},
				{
					height: '25px',
					boxes: [
						[0, '100px'],
						['10%', 1]
					]
				},
				{
					height: '25px',
					boxes: [[0, '100px']]
				},
				{
					height: '25px',
					boxes: [
						[0, '100px'],
						['10%', 2]
					]
				},
				{
					height: '2rem',
					boxes: [[0, 0]]
				},
				{
					height: '1rem',
					boxes: [[0, 5]]
				},
				{
					height: '1rem',
					boxes: [[0, 0]]
				},
				{
					height: '1rem',
					boxes: [[0, '50%']]
				},
				{
					height: '1rem',
					boxes: [[0, 0]]
				},
				{
					height: '1rem',
					boxes: [[0, '10em']]
				}
			]
		};
	},

	computed: {
		...mapState('dealer', ['hasSearched', 'loading', 'locationList', 'selected', 'showing', 'zipCode']),
		...mapState('content/dealer', ['ad', 'noDealership', 'primary', 'secondary']),
		...mapState('consumerInfo', ['userLanguage']),
		noDealers() {
			// Checking to make sure that the user has searched so that the no-dealer content does not display on page load
			if (this.hasSearched) {
				if (!this.locationList.length) {
					return true;
				}
			}

			return false;
		},
		uniqueDisclaimers() {
			let output = [];
			let keys = [];

			this.showing.forEach(function (dealer) {
				let key = dealer.financePartnerCode;

				if (keys.indexOf(key) === -1 && dealer.financePartnerDisclaimer) {
					keys.push(key);
					output.push({
						code: dealer.financePartnerCode,
						disclaimer: dealer.financePartnerDisclaimer
					});
				}
			});

			return output;
		},
		showContentPlaceholder() {
			// only show the placeholder if we have no location's and we're currently fetching locations
			return this.locationList.length <= 0 && this.loading;
		}
	},

	methods: {
		...mapActions('dealer', ['allDetails']),
		...mapActions('content/dealer', ['fetchContent']),
		...mapMutations('search', ['setDealershipId']),
		paginateDealers({ clickedSet, itemsPerPage }) {
			const startIndex = clickedSet - itemsPerPage < 0 ? 0 : clickedSet - itemsPerPage;
			const dealers = this.locationList.slice(startIndex, clickedSet);

			this.allDetails(dealers);
		},
		selectDealer(dealer, index) {
			this.trackDealershipClicked({
				'dealership_id': dealer.dealershipid,
				'dealer_position': index,
				'dealer_latitude': dealer.latitude || null,
				'dealer_longitude': dealer.longitude || null,
				'dealer_distance': dealer.distance
			});

			this.$emit('dealerSelected', dealer);
		}
	},

	watch: {
		'$store.state.consumerInfo.userLanguage': function() {
			// true fed to fetchContent to trigger re-fetching of content if userLanguage is changed
			this.fetchContent(true);
		}
	},

	created() {
		this.fetchContent();
		// Had to move this to created() because it was not being triggered on initialization
		this.$watch('locationList', (newDealerships) => {
			this.paginateDealers({
				clickedSet: 5,
				itemsPerPage: 5
			});

			if (newDealerships.length > 0) {
				this.trackDealershipsViewed();
			}
		}, { immediate: true });

		// map symbols to dealer codes
		this.$watch('showing', () => {
			let codes = [];
			// collect unique finance partners by code

			this.showing.forEach(function (dealer) {
				let key = dealer.financePartnerCode;

				if (codes.indexOf(key) === -1 && dealer.financePartnerDisclaimer) {
					codes.push(key);
				}
			});
			// assign each code a footnote symbol
			let tempMap = {};
			let fs = this.footnoteSymbols;

			codes.forEach(function (code) {
				tempMap[code] = fs[Object.keys(tempMap).length];
			});
			this.footnoteMap = tempMap;
		}, { immediate: true });
	}
};
</script>
