import Language from '../Language';

const language = new Language(
	'Chinese_HK',
	['壹月', '贰月', '叁月', '肆月', '伍月', '陆月', '柒月', '捌月', '玖月', '拾月', '拾壹月', '拾贰月'],
	['壹月', '贰月', '叁月', '肆月', '伍月', '陆月', '柒月', '捌月', '玖月', '拾月', '拾壹月', '拾贰月'],
	['日', '壹', '贰', '叁', '肆', '伍', '陆']
);

language.yearSuffix = '年';

export default language;
