<template>
    <div class="product-tabs">
        <ul class="product-tabs__list" role="tablist">
            <!-- Tabs for TIRES and WINTER PACKAGES -->
            <li
                class="product-tabs__item"
                role="tab"
                v-for="(tab, index) in tabs"
                :key="index">
                <span
                    v-html="$t(tab.title)"
                    :class="buttonClass(index)"
                    @click.prevent="activate(index)">
                </span>
            </li>
        </ul>

        <!-- CONTENT AREA FOR PRODUCT TILES -->
        <div class="product-tabs__content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
	props: {
		active: {
			type: Number,
			default: 0
		}
	},
	data() {
		return {
			tabs: [],
			activated: this.active
		};
	},
	methods: {
		activate(index) {
			this.activated = index;

			this.$emit('activated', index);
		},
		buttonClass(index) {
			const isActive = this.activated === index;

			return [ 'product-tabs__btn',
				{
					'product-tabs__btn--is-active': isActive,
					'product-tabs__btn--is-not-active': !isActive
				}
			];
		}
	}
};
</script>
