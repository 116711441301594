'use strict';

module.exports = (number) => {
	let num = String(number);
	const numLen = num.length;

	if (num.indexOf(',') !== -1) {
		return num;
	}
	function hundred(n) {
		if (numLen === 6) {
			return `${n.substr(0, 3)},${n.substr(3, 3)}`;
		}

		return n;
	}

	function ten(n) {
		if (numLen === 5) {
			return `${n.substr(0, 2)},${n.substr(2, 3)}`;
		}

		return hundred(n);
	}

	function single(n) {
		if (numLen === 4) {
			return `${n.substr(0, 1)},${n.substr(1, 3)}`;
		}

		return ten(n);
	}

	return single(num);
};
