/* eslint-disable no-inline-comments */
/* eslint-disable line-comment-position */
/* eslint-disable complexity */
import { rudderAnalytics } from './setup';

const RudderstackPlugin = {
	install(Vue, options) {
		const { store } = options;

		function getVehicle() {
			let tiresizeObj = {};

			if (store.state.search.query && typeof store.state.search.query.tiresize !== 'undefined') {
				let tiresize_pattern = /(\d+)\/(\d+)\s?R(\d+)/gi;// 225/45R17

				if (store.state.search.query.tiresize !== '') {
					tiresizeObj = tiresize_pattern.exec(store.state.search.query.tiresize);

					if (tiresizeObj.length === 4) {
						tiresizeObj = {
							width: tiresizeObj[1],
							ratio: tiresizeObj[2],
							diameter: tiresizeObj[3]
						};
					} else {
						tiresizeObj = {
							width: '',
							ratio: '',
							diameter: ''
						};
					}

				} else {

					tiresizeObj = {
						width: ((typeof store.state.search.query.width !== 'undefined') ? store.state.search.query.width : ''),
						ratio: ((typeof store.state.search.query.ratio !== 'undefined') ? store.state.search.query.ratio : ''),
						diameter: ((typeof store.state.search.query.diameter !== 'undefined') ? store.state.search.query.diameter : '')
					};
				}
			}

			return {
				year: ((store.state.search.query && typeof store.state.search.query.year !== 'undefined')
					? store.state.search.query.year
					: ''
				),
				make: ((store.state.search.query && typeof store.state.search.query.make !== 'undefined')
					? store.state.search.query.make
					: ''
				),
				model: ((store.state.search.query && typeof store.state.search.query.model !== 'undefined')
					? store.state.search.query.model
					: ''
				),
				trim: ((store.state.search.query && typeof store.state.search.query.trim !== 'undefined')
					? store.state.search.query.trim
					: ''
				),
				option: ((store.state.search.query && typeof store.state.search.query.option !== 'undefined')
					? store.state.search.query.option
					: ''
				),
				width: ((store.state.search.query && typeof tiresizeObj.width !== 'undefined')
					? tiresizeObj.width
					: ''
				),
				ratio: ((store.state.search.query && typeof tiresizeObj.ratio !== 'undefined')
					? tiresizeObj.ratio
					: ''
				),
				diameter: ((store.state.search.query && typeof tiresizeObj.diameter !== 'undefined')
					? tiresizeObj.diameter
					: ''
				)
			};
		}

		function getGlobalProperties() {
			// storing in session saves booleans as strings. Need to cast to boolean.
			let isNational = JSON.parse(sessionStorage.getItem('isNational'));
			let dealerID = store.state.search.dealership_id;

			if (
				(typeof dealerID === 'undefined' || dealerID === null)
				&& store.state.search.query
				&& typeof store.state.search.query.uid !== 'undefined'
			) {
				dealerID = store.state.search.dealership_id;
			}

			if ( (isNational ? isNational : null) === null) {
				isNational = Boolean(!store.state.search.query.uid);
				sessionStorage.setItem('isNational', Boolean(!store.state.search.query.uid));
			}

			return {
				'search_type': store.state.search.userSearchType,
				'vehicle': getVehicle(),
				'national': isNational,
				'oem_program_code': store.state.initial.collaborativeProgramCode,
				'dealership_id': dealerID
			};
		}

		Vue.prototype.$rudderstack = {

			/**
             * The page call records page views with any additional relevant information about the viewed page.
             *
             * @arg {string} pageCategory - the category of the page IE: "Cart"
             * @arg {string} [pageName=''] - the name of the page IE: "Cart Viewed"
             * @arg {object} [properties={}] - Includes the properties associated with the event
             * @return {void}
             */
			page(pageCategory, pageName = '', properties = {}) {
				rudderAnalytics.page(pageCategory, pageName, {
					...getGlobalProperties(),
					...properties
				});
			},

			/**
             * The track call lets you record the user’s actions along with any properties associated with them.
             *
             * @arg {string} eventName - Name of the user action
             * @arg {object} [properties={}] - Includes the properties associated with the event
             * @return {void}
             */
			track(eventName, properties = {}) {
				rudderAnalytics.track(eventName, {
					...getGlobalProperties(),
					...properties
				});
			}
		};

		// Global mixin using the component navigation guard.
		Vue.mixin({
			beforeRouteEnter(to, from, next) {
				next(() => {
					store.state.search.query = to.query;
				});
			}
		});
	}
};

export default RudderstackPlugin;
