<template>
    <tr>
        <th class="order-totals__cell">{{ $t("message.taxes") }}*</th>
        <td class="order-totals__cell order-totals__cell--right">{{$n(tax, 'currency') }}</td>

    </tr>
</template>

<script>
// Accepts the products that are in the cart,
// Gets the taxes for them
// Sets it to a thing so the cart object can grab it.
import { mapActions, mapGetters, mapState } from 'vuex';
import { extend, isEmpty } from 'underscore';

export default {
	props: {
		product: {
			type: Object
		},
		service: {
			type: Array
		},
		quantity: {
			type: Array
		},
		isWheelPackage: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			tax: 0
		};
	},
	methods: {
		...mapActions('cart/taxes', ['getProductTaxInfo']),
		...mapGetters('cart/order', ['isMobileInstallation', 'mobileInstallationZip']),
		...mapState('cart', ['setTiresInCart'])
	},
	beforeMount: function() {
		let product = this.product;
		const qty = isEmpty(this.qtySelected) ? this.defaultQty : this.qtySelected;

		product.products.forEach( (p, i) => {
			p.qty = qty[i];
		});

		this.setTiresInCart(this.product);
		this.getProductTaxInfo({
			product,
			service: this.service,
			quantity: this.quantity,
			mobileInstallationZip: this.isMobileInstallation() ? this.mobileInstallationZip() : ''
		});
	},
	computed: extend({},
		mapState('products', ['qtySelected', 'defaultQty']),
		mapGetters('cart/order', ['orderPayment'])
	),
	watch: {
		orderPayment(paymentObj) {
			this.tax = paymentObj.supplementalChargesAmount;
			this.$emit('taxesDone', paymentObj);
		}
	}
};

</script>
