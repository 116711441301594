<template>

    <!-- 03/22/2024: NO LONGER BEING USED:
		Functionality was removed from the desktop version. Keeping this component
		for a short period of time in case the data says we should put it back.-->

    <div role="gridcell" class="spec-filter" tabIndex="-1" >
        <div class="spec-filter__buttons-wrapper">

            <!-- TOGGLES FILTER DROPDOWN MENU -->
            <button
                tabIndex="0"
                class="spec-filter__toggle"
                @click="toggleFilterDropdown"
                :name="$t('message.selectFeatures')"
                :aria-label="$t('message.selectFeatures')"
                :aria-expanded="showFilterDropdown ? 'true' : 'false'"
            >
                {{$t("message.selectFeatures")}} ({{ dropDownCount }})
                <i
                    class="icon icon--angle-down"
                    :class="{ 'spec-filter__flipped-arrow': showFilterDropdown }"
                >
                </i>
            </button>

            <!-- CLEARS FILTERS -->
            <button
                tabIndex="0"
                class="spec-filter__clear"
                @click="resetSpecs()"
                :name="$t('message.clear')"
                :aria-label="$t('message.clear')">
                {{$t("message.clear")}}
            </button>

        </div>

        <!-- DROPDOWN MENU -->
        <div v-show="showFilterDropdown" class="spec-filter__dropdown">

            <!-- DISPLAY SPECS AVAILABLE -->
            <label v-for="defaultSpec in defaultSpecs"
                   class="spec-filter__label"
                   :key="defaultSpec">

                <input
                    tabIndex="0"
                    type="checkbox"
                    :value="defaultSpec"
                    v-model="filteredSpecs"/>
                {{ $t(`message.${defaultSpec}`) }}
            </label>

            <!-- APPLIES FILTERS -->
            <button
                tabIndex="0"
                class="button button--primary spec-filter__apply"
                @click="setNewSpecs()"
                :name="$t('message.apply')"
                :aria-label="$t('message.apply')"
                :disabled="!filteredSpecs.length">
                {{$t("message.apply")}}
            </button>
        </div>

        <div v-show="showFilterDropdown" class="spec-filter__mask" @click="toggleFilterDropdown"/>
    </div>
</template>

<script>
import { noDataEvent } from '@/utils/setDataLayer';
import { mapMutations, mapState } from 'vuex';
import { contains } from 'underscore';
import { renameSpecTitlesForFeaturesToCompare } from '@/utils/specification-conversion.js';

export default {
	filters: {
		properTitle(spec) {
			return renameSpecTitlesForFeaturesToCompare(spec);
		}
	},
	data() {
		return {
			filteredSpecs: [],
			showFilterDropdown: false
		};
	},
	computed: {
		...mapState('products', [
			'defaultSpecs',
			'specsToCompare'
		]),
		// Removes selected features to compare from default specs to keep their initial order
		defaultSpecsFiltered() {
			let newSpecs = [];
			let specsToFilter = this.filteredSpecs;

			this.defaultSpecs.forEach(function(defaultSpec) {
				if (contains(specsToFilter, defaultSpec)) {
					newSpecs.push(defaultSpec);
				}
			});

			return newSpecs;
		},
		dropDownCount() {
			if (!this.filteredSpecs.length &&
				this.specsToCompare.length === this.defaultSpecs.length) {
				return 0;
			}

			return this.filteredSpecs.length ?
				this.filteredSpecs.length :
				this.specsToCompare.length;
		}
	},
	methods: {
		...mapMutations('products', [
			'setSpecsToCompare'
		]),
		resetSpecs() {
			this.setSpecsToCompare(this.defaultSpecs);
			this.filteredSpecs = [];
		},
		setNewSpecs() {
			this.setSpecsToCompare(this.defaultSpecsFiltered);
			this.showFilterDropdown = false;
			noDataEvent('comparePageSelectFeaturesToCompare');
		},
		toggleFilterDropdown() {
			this.showFilterDropdown = !this.showFilterDropdown;
		}
	}
};
</script>
