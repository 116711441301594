import axios from 'axios';
import { find, isEmpty, pluck } from 'underscore';
import packagePrices from '../data/wheel-package-prices.json';

// This functions prime directive is to go throught the provided
// List of products and find the ones that match the skus in our cart
export const findProdBySKU = (cartProds, tires) => {
	const cartSkus = cartProds.map(prod => prod.sku) || cartProds[0].sku;

	return find(tires, ({ products }) => { //eslint-disable-line
		const [front, rear] = products;


		if (cartSkus.length > 1) {
			return front.sku === cartSkus[0] && rear.sku === cartSkus[1];
		}
		if (front.sku === cartSkus[0]) {
			return front;
		}
		if (rear && rear.sku === cartSkus[1]) {
			return rear;
		}
	});
};

// Simply gets the cart from our session
export const getCart = () => axios.get('/service/cart');


// Goes into our session to grab the cart data
// Sets the data to the correct spots in the cart
// It should only be used to make a call to PQR (aka refresh)
export const fetchCart = async ({ commit, rootState, state }, payload) => { // eslint-disable-line complexity
	let { sku: payloadSkus, qty: payloadQty } = payload;
	// If our state isn't currently empty then we have nothing to fetch everything is set already

	if (!isEmpty(state.tiresInCart) && !isEmpty(state.servicesInCart)) {
		return false;
	}

	// Otherwise we will call out and grab the data ourselves
	// first check and see if there are tires and services in session.cart
	let { data: { tires, services, wheelPackages } } = await axios.get('/service/cart');


	if (wheelPackages.length) {
		const qtyToSet = wheelPackages.length > 1 ? wheelPackages.map(wheelPack => wheelPack.qty) : [wheelPackages[0].qty];

		commit('products/setQty', qtyToSet.length ? qtyToSet : [qtyToSet], { root: true });

		const { data: { filteredPQR: { wpk } } } = await axios.get('/service/itemdetailsrequest', { params: payload });
		const chosenPackages = wpk.filter(pack => wheelPackages.find(cartPack => pack.sku === cartPack.sku));

		chosenPackages.forEach(pack => {
			const packagePrice = packagePrices[pack.sku];

			pack.pricing = packagePrice;

			return pack;
		});

		// Set our services to cart and make sure our qty is up to date
		commit('schedule/setSelectedServices', services, { root: true });
		commit('setServicesInCart', services);

		return commit('setTiresInCart', {products: chosenPackages});
	}
	// check payload(query) for qty and format as array
	if (!payloadQty) {
		payloadQty = pluck(tires, 'qty').join(',');
	}
	// if no tires exist in session (they totally should? fix logical process of this?) then use sku/qty from query
	if (!tires.length || !tires.find( tire => payloadSkus.includes(tire.sku))) {
		const tiresToAdd = [];
		const skusForTires = payloadSkus.split(',');
		const qtyForTires = payloadQty.split(',');

		for (let i = 0; i < skusForTires.length; i++) {
			tiresToAdd.push({ sku: skusForTires[i],
				qty: qtyForTires[i]});
		}

		tires = tiresToAdd;
	}
	const qtyToSet = tires.length > 1 ? tires.map(tire => tire.qty) : [tires[0].qty];

	commit('products/setQty', qtyToSet.length ? qtyToSet : [qtyToSet], { root: true });
	// call PQR
	const { data: { filteredPQR: { staggered, loose } } } = await axios.get('/service/itemdetailsrequest', { params: payload });


	// Set our services to cart and make sure our qty is up to date
	commit('schedule/setSelectedServices', services, { root: true });
	commit('setServicesInCart', services);

	// mock up a PQR call with just one loose (or staggered) tire
	let tiresToSet = {};

	// add just our chosen tire
	if (staggered && staggered.length && rootState.products.isStaggered) {
		tiresToSet = await findProdBySKU(tires, staggered);
		// this is for when user only wants front or rear of a staggered, filtering the other product in the set out
		tiresToSet.products = tiresToSet.products.filter(prod => tires.find(tire => tire.sku === prod.sku));
		if (tiresToSet.products.length === 1) {
			tiresToSet.products[0].pricing = tiresToSet.pricing;
		}
	} else {
		tiresToSet = await findProdBySKU(tires, loose);
	}
	// commit our updated tire
	if (tires.length) {
		await axios.post('/service/cart/tires', tires);
	}

	return commit('setTiresInCart', tiresToSet);

	// append the qty selected to our PQR payload if it isn't in query
};

// Add a list of services to the cart
export const addServicesToCart = ({ commit }, payload) => {
	axios
		.post('/service/cart/services', payload)
		.then(() => {
			commit('setServicesInCart', payload);
		})
		.catch(err => {
			commit('setServicesInCart', []);

			throw new Error(err);
		});
};
export const removeServicesFromCart = ({ dispatch }) => {
	dispatch('getCart').then((cartRes) => {
		const serviceArray = cartRes.data.services;


		if (!isEmpty(serviceArray)) {
			serviceArray.forEach((service) => {
				const serviceId = service.id;

				axios.delete(`/service/cart/services/${serviceId}`);
			});
		}
	});
};

// Adds a tire product to the cart
// It will then automatically update our store with the latest info
export const addTireToCart = async ({ commit, rootState }, products) => {
	const { data, error } = await axios.post('/service/cart/tires', products);

	if (error) {
		commit('setTiresInCart', []);
		throw new Error(error);
	}
	const tiresToCommit = [];

	if (rootState.products.length) {

		const { allProducts } = rootState.products;
		const tiresBySKU = await findProdBySKU(data, allProducts);

		tiresToCommit.push(tiresBySKU);
	} else {
		tiresToCommit.push(products);
	}
	commit('setTiresInCart', tiresToCommit);
};

export const addPackageToCart = async ({ commit, rootState }, products) => {
	const { data, error } = await axios.post('/service/cart/packages', products);

	if (error) {
		commit('setTiresInCart', []);

		throw new Error(error);
	}
	const { wpks } = rootState.products;
	// const mappedWpks = wpks.map(pack => {
	// 	return { products: [pack] };
	// });
	const chosenPackage = wpks.find(pack => pack.sku === data[0].sku);

	commit('setTiresInCart', {products: [chosenPackage]});
};
// Remove a tire product from the cart
// It will then automatically update our store with the latest info
// In our current state we are only allowing 1 type of tire at a time
// So if a tire is removed, 99% chance we are just emptying it out
// As of 05/08/2018 this is not actually used
export const removeTireFromCart = ({ commit }) => axios
	.delete(`/service/cart/tires`)
	.then(() => commit('setTiresInCart', []))
	.catch(err => {
		commit('setTiresInCart', {});

		throw new Error(err);
	});
