import axios from 'axios';

export const getCarlineCatalogYears = async ({ commit }) => {
	let { data: years, error } = await axios.get('/service/carlineCatalogYearsRequest');

	if (error) {
		commit('carlineYears', []);
		throw new Error(error);
	}

	commit('carlineState', {
		makes: [],
		models: [],
		trims: [],
		options: [] });
	commit('carlineYears', years);
};

export const getCarlineCatalogMakes = async ({ commit }, { year }) => {
	let { data: makes, error } = await axios.get('/service/carlineCatalogMakesRequest', { params: { year } });


	if (error) {
		commit('carlineMakes', []);
		throw new Error(error);
	}

	if (!year) {
		return commit('tireSizeMakes', makes);
	}

	commit('carlineState', {
		models: [],
		trims: [],
		options: [] });

	return commit('carlineMakes', makes);

};

export const getCarlineCatalogModels = async ({ commit }, { year, make }) => {
	let { data: models, error } = await axios.get('/service/carlineCatalogModelsRequest', { params: { year,
		make } });

	if (error) {
		commit('carlineModels', []);
		throw new Error(error);
	}
	commit('carlineState', {
		trims: [],
		options: []
	});
	commit('carlineModels', models);
};

export const getCarlineCatalogTrims = async ({ commit }, { year, make, model }) => {
	let { data: trims, error } = await axios.get('/service/carlineCatalogTrimsRequest', { params: { year,
		make,
		model } });

	if (error) {
		commit('carlineTrims', []);
		throw new Error(error);
	}
	commit('carlineState', { options: [] });
	commit('carlineTrims', trims);
};

export const getCarlineCatalogOptions = async ({ commit }, { year, make, model, trim }) => {
	let { data: options, error } = await axios.get('/service/carlineCatalogOptionsRequest', { params: {
		year,
		make,
		model,
		trim
	}});

	if (error) {
		commit('carlineOptions', []);
		throw new Error(error);
	}
	commit('carlineOptions', options);


};

export const getTireSizeCatalog = async ({ commit }, { languageCode, languageLocale }) => {
	let { data: tireSizes, error } = await axios.get('/service/tireSizeCatalogRequest', { params: {
		languageCode,
		languageLocale
	}});

	if (error) {
		commit('tireSizes', []);
		throw new Error(error);
	}

	commit('tireSizes', tireSizes);
};
