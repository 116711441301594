<template>
    <div>
        <!-- Loading Products -->
        <div v-if="tireLoading" class="row">
            <div class="col-12 vehicle-info__content-placeholder">
                <content-placeholder :rows="placeholderRows" />
            </div>
        </div>
        <!-- Search By Vehicle -->
        <div class="row" v-else-if="!vehicleInfo.isBySize">
            <div :class="[{'col-6': showEvoxImg, 'col-12': !showEvoxImg}]">
                <h5 role="presentation" class="vehicle-info__we-found">
                    {{ $t("message.weFound") }} {{tireCount}} {{tireTense}} {{ $t("message.willFit") }}
                </h5>
                <h5 role="presentation" class="vehicle-info__model-name">{{vehicleInfo.year}} {{vehicleInfo.make}} {{vehicleInfo.model}}</h5>
                <p class="vehicle-info__model-size-name">
                    {{ $t("message.tireSize") }}: <strong>{{tireSize | cleanTireSize}}</strong>
                </p>
            </div>
            <div v-if="showEvoxImg" class="col-6 vehicle-info__img-wrapper">
                <img class="img-fluid vehicle-info__evox-img"
                     :src="evoxImg"
                     :alt="vehicleInfo.model"
                     @load="evoxLoaded">
            </div>
        </div>

        <!-- Search By Size -->
        <div class="row" v-else>
            <h3 class="col-12 vehicle-info__we-found">
                {{ $t("message.weFound") }}
                {{tireCount}} {{tireTense}}
                {{ $t("message.matchYour") }}
                {{vehicleInfo.make}}
                {{ $t("message.vehicle") }}
            </h3>
            <h5 class="col-12 vehicle-info__model-size-name">
                {{ $t("message.tireSize") }}: <strong>{{tireSize}}</strong>
            </h5>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { evoxImageSeenInViewport } from '@/utils/setDataLayer';
import ContentPlaceholder from 'vue-content-placeholder';

export default {
	props: {
		tireCount: {
			type: Number,
			required: true
		},
		tireSize: {
			type: [String, Array]
		},
		tireLoading: {
			type: Boolean,
			required: true
		},
		query: {
			type: Object,
			required: true
		},
		isMobile: {
			type: Boolean,
			required: true
		}
	},
	components: {
		ContentPlaceholder
	},
	computed: {
		showEvoxImg() {
			return this.hasEvoxImg && !this.isMobile;
		},
		tireTense() {
			return (this.tireCount === 1) ? this.$t('message.tireLowerCase') : this.$t('message.tiresLowerCase');
		}
	},
	filters: {
		cleanTireSize(val) {
			if (Array.isArray(val)) {
				return val.join(', ');
			}

			return val;
		}
	},
	data() {
		const qs = this.query;

		return {
			vehicleInfo: {
				year: qs.year,
				make: qs.make,
				model: qs.model,
				isBySize: qs.isbysize
			},
			evoxImg: '',
			hasEvoxImg: false,
			placeholderRows: [
				{
					height: '25px',
					boxes: [[0, '100%']]
				},
				{
					height: '10px',
					boxes: [[0, 0]]
				},
				{
					height: '25px',
					boxes: [[0, '100%']]
				}
			]
		};
	},
	methods: {
		evoxLoaded() {
			evoxImageSeenInViewport();
		}
	},
	created() {
		if (!this.query.isbysize) {
			const params = {
				year: this.query.year,
				make: this.query.make,
				model: this.query.model,
				trim: this.query.trim,
				option: this.query.option
			};

			axios.get('/service/carlineImagesRequest', { params }).then(({data}) => {
				const vif = data.evoxVIF;
				const colorCode = data.colorCode;

				if (vif && colorCode) {
					this.evoxImg = `/content/vi/color_0640_001/${vif}/${vif}_cc0640_001_${colorCode}.png`;
					this.hasEvoxImg = true;
				}
			});
		}
	}
};

</script>
