// We can store the i18n format options here
// This is used by the createI18n and allows us to have specific formats for date/time and numbers

// DateTime formats follow the EMCS402 standards
// https://tc39.es/ecma402/#sec-datetimeformat-abstracts
const dateTimeFormats = {
	'en-us': {
		time: {
			hour: 'numeric',
			minute: 'numeric'
		},
		short: {
			month: 'short',
			day: 'numeric'
		},
		long: {
			month: 'long',
			day: 'numeric',
			weekday: 'long'
		},
		full: {
			hour: 'numeric',
			minute: 'numeric',
			month: 'long',
			day: 'numeric',
			weekday: 'long'
		}
	},
	'fr-ca': {
		time: {
			hour: 'numeric',
			minute: 'numeric'
		},
		short: {
			month: 'short',
			day: 'numeric'
		},
		long: {
			month: 'long',
			day: 'numeric',
			weekday: 'long'
		},
		full: {
			hour: 'numeric',
			minute: 'numeric',
			month: 'long',
			day: 'numeric',
			weekday: 'long'
		}
	},
	'en-ca': {
		time: {
			hour: 'numeric',
			minute: 'numeric'
		},
		short: {
			month: 'short',
			day: 'numeric'
		},
		long: {
			month: 'long',
			day: 'numeric',
			weekday: 'long'
		},
		full: {
			hour: 'numeric',
			minute: 'numeric',
			month: 'long',
			day: 'numeric',
			weekday: 'long'
		}
	}
};

// Number formats follow the EMCS402 standards
// https://tc39.es/ecma402/#numberformat-objects
const numberFormats = {
	// We use narrowSymbol since some curriencies use a portion of the country in the display
	// For instance china uses 'CN¥' as the symbol and '¥' as the narrowSymbol
	// The list of countries and codes can be found at: https://st.unicode.org/cldr-apps/v#/en_US_POSIX/C_NAmerica/
	// Locale specific currencies
	'en-us': {
		currency: {
			style: 'currency',
			currency: 'USD',
			currencyDisplay: 'narrowSymbol'
		}
	},
	'fr-ca': {
		currency: {
			style: 'currency',
			currency: 'CAD',
			currencyDisplay: 'narrowSymbol'
		}
	},
	'en-ca': {
		currency: {
			style: 'currency',
			currency: 'CAD',
			currencyDisplay: 'narrowSymbol'
		}
	}
};

export { dateTimeFormats, numberFormats };
