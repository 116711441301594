<template>
    <aside v-if="typeCurrentState" class="appointment-type">
        <button
            type="button"
            v-for="(pickType, typeIndex) in filteredTransports"
            aria-labelledby="appointment"
            :key="typeIndex"
            data-cy="transportOption"
            @click="updateAppointmentType(pickType)"
            :aria-pressed="selectedAppointmentType.id === pickType.id ? 'true' : 'false'"
            class="appointment-type__button"
            :class="{ 'appointment-type__button--selected': selectedAppointmentType.id === pickType.id }"
        >
            {{ $t(`message.transportID${pickType.id}`) }}
        </button>

    <!-- TODO: uncomment when we reactivate Mobile Install -->
    <!-- <div v-if="isMobileInstallAvailable && !isAvailableFacingWarehouse" data-cy="transportOption">
                <label aria-labelledby="appoinment mobile" :for="pickType.id">
                    <input
                        name="appointmentTypeRadio"
                        :id="pickType.id"
                        type="radio"
                        role="radio"
                        aria-checked="true"
                        value="Mobile Install Disabled"
                        @click="showMobileInstallWarnMessage"
                        :disabled="showWarnMessage"
                    />
                    <span>{{ $t(`message.transportID14`) }}</span>
                </label>
            </div> -->
    <!-- <div v-if="selectedAppointmentType.id === 13" class="mi-info">
                <span>
                    <input type="text"
                           maxlength="5"
                           minlength="5"
                           @click="resetInvalidZip"
                           placeholder="Enter zip code to check availability"
                           v-validate="{
                               required: true,
                               digits:5,
                               is: eligibleZips.find(zip => zip === mobileInstallZip)
                           }"
                           name="zip code"
                           data-vv-name="zipCode"
                           v-model="mobileInstallZip"
                           class="safe-input"
                           :class="{ 'input__field--error': errors.has('zipCode') && !errors.firstByRule('zipCode', 'is') }"
                    />
                </span>
                <small
                    v-if="errors.has('zipCode') && !errors.firstByRule('zipCode', 'is')"
                    class="">
                    {{ (errors.first('zipCode')) }}
                </small>
                <small v-if="invalidZip" class="">
                    {{ $t('message.mobileInstallZipInvalid') }}
                </small>

                <button type="button"
                        :disabled="mobileInstallZip.length !== 5"
                        @click="verifyMobileInstallZip"> Check Zip</button>

                <div class="col-12" v-html="mobileInstallContent2"></div>

            </div> -->
    <!-- Apr 2024: removing mobile install content for now -->
    <!-- <div
            class="no-mobileinstallation-message"
            v-if="showWarnMessage && selectedAppointmentType.id === undefined"
            v-html="scheduleMobileUnAvailable"
        /> -->
    </aside>
</template>

<script>
import { mapState } from 'vuex';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import mobileInstallZips from '@/store/data/mobile-install-zip-codes.json';
import { zipCodeVerify, scheduleMobileInstallDisabledButton } from '@/utils/setDataLayer';

export default {
	name: 'AppointmentType',
	props: {
		query: {
			required: true,
			type: Object
		},
		isTypeOpen: {
			type: Boolean,
			required: true
		},
		isMobile: {
			type: Boolean,
			required: true
		},
		setShowChooseNewTireButton: {
			type: Function,
			requred: true
		}
	},
	data() {
		return {
			selectedAppointmentType: {},
			typeCurrentState: true,
			mobileInstallZip: '',
			invalidZip: false,
			showWarnMessage: false
		};
	},
	computed: {
		...mapState('dealer', ['selected']),
		// ...mapState('content/schedule', ['mobileInstallContent2']),
		...mapState('cart', ['tiresInCart']),

		eligibleZips() {
			return mobileInstallZips;
		},
		checkMark() {
			return faCircleCheck;
		},
		isMobileInstallAvailable() {
			// eslint-disable-next-line no-undefined
			return this.selected.transports.find(transport => transport.id === 14) !== undefined;
		},
		isAvailableFacingWarehouse() {
			return this.tiresInCart.products.every((product) => product.isAvailableFacingWarehouse === true);
		},
		filteredTransports() {
			if (this.isAvailableFacingWarehouse) return this.selected.transports;

			return this.selected.transports.filter(transport => transport.id !== 14);
		}

	},
	inject: {
		$validator: '$validator'
	},
	methods: {
		resetInvalidZip() {
			this.invalidZip = false;
		},
		updateAppointmentType(selectedAppt) {
			// this.selectedAppointmentType.id = $event.currentTarget.id;
			this.setShowChooseNewTireButton(false);
			this.selectedAppointmentType = selectedAppt;
			this.$emit('mobileInstallPicked', false);
			this.$emit('updateAppointmentType', this.selectedAppointmentType);

			// if (this.selectedAppointmentType.id !== 14) {
			// 	this.$emit('mobileInstallPicked', false);
			// 	this.$emit('updateAppointmentType', this.selectedAppointmentType);
			// } else {
			// 	this.$emit('mobileInstallPicked', true);
			// 	this.selectedAppointmentType = {};
			// 	// Data Layer event for mobile install
			// 	scheduleMobileInstallButton(window.location.href, this.tiresInCart);
			// }
		},
		verifyMobileInstallZip() {
			this.$validator.validateAll().then(zipValid => {
				if (zipValid) {
					this.invalidZip = false;
					this.$emit('updateAppointmentType', this.selectedAppointmentType, this.mobileInstallZip);
				} else {
					this.invalidZip = true;

					zipCodeVerify({
						dealerZipCode: this.selected.zip,
						zipNotAvailable: this.mobileInstallZip
					});
				}
			});
		},
		showMobileInstallWarnMessage() {
			this.selectedAppointmentType = {};
			this.showWarnMessage = true;
			this.setShowChooseNewTireButton(true);
			// Data Layer event for mobile install
			scheduleMobileInstallDisabledButton(window.location.href, this.tiresInCart);
		}
	},
	watch: {
		isTypeOpen(bool) {
			this.typeCurrentState = bool;
		}
	}
};
</script>
