import { get } from 'axios';

const store = {
	namespaced: true,
	state: {
		footerContent: '',
		primaryContent: ''
	},
	mutations: {
		content(state, { prop, html }) {
			state[prop] = html;
		}
	},
	actions: {
		cms({ commit, state }, { prop, slug, isUpdate }) {
			if (!state[prop] || isUpdate) {
				get(`/service/cms/${slug}`).then(({ data }) => {
					commit('content', {
						prop,
						html: data.html
					});
				});
			}
		},
		fetchFooterContent({ dispatch }, isUpdate) {
			dispatch('cms', {
				prop: 'footerContent',
				slug: 'consumer-info-footer',
				isUpdate
			});
		},
		fetchPrimaryContent({ dispatch }, isUpdate) {
			dispatch('cms', {
				prop: 'primaryContent',
				slug: 'consumer-info-primary',
				isUpdate
			});
		}
	}
};

export default store;
