<template>
    <div class="compare-drawer__tile">
        <div class="compare-drawer__close-wrapper">
            <button
                class="compare-drawer__close"
                :class="{ 'compare-drawer__close--black': errorLoading }"
                @click="removeProductSetFromCompare(productSet)"
                :name="removeFromCompare"
                :aria-label="removeFromCompare">
                <i class="icon icon--close"/>
            </button>
        </div>

        <div class="compare-drawer__img-wrapper">
            <img
                class="compare-drawer__img"
                :src="compareTileImg"
                @error="errorLoading=true;"
                alt="Compare Tires"
                aria-hidden="true"/>
        </div>

        <p class="compare-drawer__tire-brand">{{ tireBrand }}</p>
        <p class="compare-drawer__tire-name">{{ tireName }}</p>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
	props: {
		productSet: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			errorLoading: false
		};
	},
	computed: {
		compareTileImg() {
			if (this.errorLoading) {
				return '/content/drsg/b_iso/default.jpg';
			}
			const pictId = this.productSet.products[0].imageId;

			return `/content/drsg/t_tread/${pictId}_t.jpg`;
		},
		tireBrand() {
			return this.productSet.products[0].brand;
		},
		tireName() {
			return this.productSet.products[0].catalogEntryDescription;
		},
		removeFromCompare() {
			return `${ this.$t('message.remove') } ${ this.tireBrand } ${ this.tireName } ${ this.$t('message.fromCompare') }`;
		}
	},
	watch: {
		productSet() {
			this.errorLoading = false;
		}
	},
	methods: {
		...mapActions('products', [
			'removeProductSetFromCompare'
		])
	}
};
</script>
