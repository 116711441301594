<template>
    <div class="tabs">
        <ul class="tabs__list" role="tablist">
            <li
                class="tabs__item"
                v-for="(tab, index) in tabs"
                :key="index"
                role="tab"
                :aria-selected="ariaSelected(index)"
                :aria-controls="ariaControls(index)"
                :id="ariaLabel(index)">
                <button
                    tabindex="0"
                    :href="`#${tab.name}`"
                    :class="['tabs__link', {
                        'is-active': activated === index,
                        'is-not-active': activated !== index
                    }]"
                    :data-cy="`${tab.dataCy}`"
                    @click.prevent="activate(index)">
                    {{ $t(tab.title) }}
                    <span class="tabs__bottom"></span> <!--hides the bottom border of the active tab-->
                </button>
            </li>
        </ul>

        <div class="tab-content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
	props: {
		active: {
			type: Number,
			default: 0
		}
	},
	data() {
		return {
			tabs: [],
			activated: this.active
		};
	},
	methods: {
		activate(index) {
			this.activated = index;

			this.$emit('activated', index);
		},
		ariaSelected(index) {
			let selectedAria = 'false';

			if (this.activated === index) {
				selectedAria = 'true';
			}

			return selectedAria;
		},
		ariaControls(index) {
			return `tabpanel${index}`;
		},
		ariaLabel(index) {
			return `tab-${index}`;
		}
	}
};
</script>
