<template>
    <DialogModal
        :isOpen="showModal"
        @close="toggleOOSModal(false)"
        :showCloseButton="showCloseButton"
        :ariaLabel="header"
    >
        <h4 slot="header">{{ header }}</h4>
        <p v-html="body"></p>

        <!-- If you need to pass a button and button event handler -->
        <button
            v-if="showButton"
            slot="footer"
            @click="handleButtonClick"
            class="button button--primary"
        >
            {{ buttonText }}
        </button>
    </DialogModal>
</template>

<script>
import DialogModal from '@/components/DialogModal.vue';
import { mapState, mapMutations } from 'vuex';

export default {
	name: 'ModalOutOfStock',

	props: {
		isOpen: {
			type: Boolean,
			required: false,
			default: false
		},
		title: {
			type: String,
			required: false
		},
		message: {
			type: String,
			required: false
		},
		buttonText: {
			type: String,
			required: false
		},
		showCloseButton: {
			type: Boolean,
			required: false,
			default: true
		}
	},

	components: {
		DialogModal
	},

	computed: {
		...mapState('products', ['selectedProdName']),
		...mapState('initial', ['phoneNumber']),
		...mapState('modal', ['oos']),
		header() {
			return this.title
				? this.title
				: this.$t('message.oosHeader', { tire: this.selectedProdName });
		},
		body() {
			return this.message
				? this.message
				: this.$t('message.oosMsg', { phone: this.phoneNumber });
		},
		showModal() {
			// if `isOpen` prop is passed in, use it. Otherwise default to `oos` from the store.
			return this.isOpen ? this.isOpen : this.oos;
		},
		showButton() {
			return Boolean(this.buttonText);
		}
	},

	methods: {
		...mapMutations('modal', ['toggleOOSModal']),

		// `onButtonClick` emitted so parent can handle anything that needs to happen. IE: navigate to another page
		handleButtonClick() {
			this.$emit('onButtonClick');
			this.toggleOOSModal(false);
		}
	}
};
</script>
