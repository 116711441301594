<template>
    <Checkout
        :hasCreditCardCheckout="hasCCCheckout"
        :query="query"
        @taxesDone="trackCheckoutStarted"
    />
</template>

<script>
import Checkout from '@/components/Checkout.vue';
import { isEmpty } from 'underscore';
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex';
import { RudderstackCheckoutMixin } from '@/rudderstack/track/mixins/checkout';
import { RudderstackChatMixin } from '@/rudderstack/track/mixins/chat';

export default {
	components: {
		Checkout
	},

	mixins: [RudderstackCheckoutMixin, RudderstackChatMixin],

	props: {
		query: {
			required: true,
			type: Object
		}
	},

	watch: {
		'$store.state.consumerInfo.userLanguage': function() {
			// true fed to fetchContent to trigger re-fetching of content if userLanguage is changed
			this.fetchContent(true);
		}
	},

	computed: {
		...mapGetters('cart/order', ['isMobileInstallation']),
		...mapState('dealer', {	selectedDealer: 'selected' }),
		...mapState('cart', ['tiresInCart']),
		...mapState('consumerInfo', ['userLanguage']),
		...mapState('initial', ['title']),
		...mapState('search', ['dealership_id']),

		hasCCCheckout() {
			return this.$store.state.initial.hasCreditCardCheckout;
		},
		newPageTitle() {
			// eslint-disable-next-line prefer-template
			const page = this.$t('message.checkoutPage') + ' | ' + this.title;

			return page;
		}
	},

	methods: {
		...mapActions('dealer', { selectDealer: 'select' }),
		...mapActions('cart', ['fetchCart']),
		...mapActions('products', ['getInventory']),
		...mapActions('appointment', ['getMobileInstallZip']),
		...mapActions('serviceOfferings', ['addMobileInstallFee']),
		...mapActions('content/payment', ['fetchContent']),
		...mapMutations('products', ['checkStaggered']),
		...mapMutations('cart/order', ['setOrder'])
	},

	mounted() {
		document.title = this.newPageTitle;
	},

	async created() { // eslint-disable-line complexity
		const qs = this.query;

		if (sessionStorage.getItem('orderNo') !== '') {
			sessionStorage.removeItem('orderNo');
		}

		// Async - Get CMS Content
		this.fetchContent();

		// This block will execute on a page refresh
		if (isEmpty(this.selectedDealer)) {
			// Action - Sets store.dealer.selected
			await this.selectDealer({
				uid: qs.uid,
				make: qs.make
			});
			// Mutation - Sets store.products.isStaggered
			await this.checkStaggered(qs.sku);
			// Get cart from session
			await this.fetchCart(qs);
			await this.getInventory({ products: this.tiresInCart.products,
				qty: !Array.isArray(qs.qty) ? qs.qty.split(',') : qs.qty });

			// Action - Sets store.schedule.appointment
			if (this.isMobileInstallation) {
				// Action - Sets mobileInstallInfo.zip in cart/order/details
				this.getMobileInstallZip();
				this.addMobileInstallFee();
			}
		}
		// Mutation- Adds values to $store.cart.order.details

		this.setOrder({
			uid: qs.uid,
			vehicle: {
				year: qs.year,
				make: qs.make,
				model: qs.model,
				trim: qs.trim,
				option: qs.option,
				containsStaggeredSet: qs.sku.split(',').length > 1
			},
			leadSearchType: qs.isbysize ? 'searchByTireSize' : 'searchByVehicle'
		});

		this.$rudderstack.page(this.$route.meta.pageCategory, this.$route.meta.pageCategory, {
			...this.$route.query,
			'dealership_id': this.dealership_id
		});
	},
	// If the browser doesn't have the needed query strings boot back to index
	beforeRouteEnter(to, from, next) {
		next( vm => {
			if (!vm.$route.query.make && !vm.$route.query.sku && !vm.$route.query.qty) {
				return vm.$router.push('/');
			}

			return true;
		});
	}
};
</script>
