import Language from '../Language';

export default new Language(
	'Galician',
	['Xaneiro', 'Febreiro', 'Marzo', 'Abril', 'Maio', 'Xuño', 'Xullo', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Decembro'],
	['Xan', 'Feb', 'Mar', 'Abr', 'Mai', 'Xuñ', 'Xul', 'Ago', 'Set', 'Out', 'Nov', 'Dec'],
	['Dom', 'Lun', 'Mar', 'Mér', 'Xov', 'Ven', 'Sáb']
)
// eslint-disable-next-line
;
