import axios from 'axios';
import { first } from 'underscore';

// A function that simply checks if staggereds exist if so we assume
// It's a staggered set, the main action determines if we need to ask the question or not
const determineSet = ({ staggered }) => {
	if (staggered.length) {
		return 'staggered';
	}

	return 'loose';
};

// Determines if the returned sets require the questions on the verify page
// So if either set has more than 1 size, we need to take them to the verify page
const needsVerify = ({ loose, staggered }, askSize) => askSize || loose.length > 1 || staggered.length > 1;

// This bad boy is going off to fetch our tire sizes using the lightweight pqr built by Will
export const fetchTireSizes = ({ commit }, params) => new Promise((resolve, reject) => {
	commit('setIsLoading', true);

	// Set these to default state so the buttons can't be buttoned
	commit('selectSize', '');
	commit('setCurrentSizes', false);
	// First let's make sure we need to make the actual request
	// Check to see if allSizes is empty OR both of the properties are empty first
	axios.get('/service/verifySizesRequest', {
		params
	}).then(({ data }) => { // eslint-disable-line complexity
		const { loose, staggered } = data;

		// Check to see if we need to ask the size question
		// "Are your front and rear tires different sizes"
		const askSize = loose.length > 0 && staggered.length > 0;

		// Check to see if we need to verify the size (go to the verify page)
		const goToVerify = needsVerify(data, askSize);

		// This figures out if we are using a staggered or loose set
		const set = determineSet(data);

		commit('products/noTires', !loose.length && !staggered.length, { root: true });

		// Set our store values
		commit('setNeedsVerified', goToVerify);
		commit('setAllSizes', data);
		commit('setAskSize', askSize);

		// If we are NOT asking the question
		// (meaning we only have 1 type that returned which is staggered or loose)
		// Then set the current size to our determined type
		if (!askSize) {
			commit('setCurrentSizes', set);
		}

		// If we do NOT need to verify the tire size and we do NOT need to ask the question
		// Then we are going to set our store state selectedSize to the first value in our array
		// As well as set the current sizes to the right set
		if (!goToVerify && !askSize) {
			commit('selectSize', first(data[set]));
			commit('setCurrentSizes', set);
		}

		return resolve();
	}).catch(err => {
		// If something goes wrong, then set everything back to it's default state
		commit('setAllSizes', {
			loose: [],
			staggered: []
		});
		commit('setCurrentSizes', false);
		commit('setAskSize', false);
		commit('setNeedsVerified', false);
		commit('selectSize', '');

		return reject(err);
	}).finally(() => commit('setIsLoading', false));
});
