import axios from 'axios';

export const clearFormData = ({ commit }) => {
	commit('setEmailUserInput', '');
	commit('setLastNameUserInput', '');
	commit('setOrderNumber', '');
	commit('setOrderNumberToDisplay', '');
	commit('setResultsHaveBeenFound', false);
	commit('setShowResults', false);
};

export const getConsumerInfo = ({ commit }, query ) => {
	const { emailUserInput, lastNameUserInput, orderNumber } = query;

	commit('setConsumerInfo', {});
	commit('setResultsHaveBeenFound', false);
	commit('setShowResults', false);

	axios.get('/service/consumerlead', {
		params: {
			orderNumber: orderNumber
		}
	}).then(({ data }) => {
		if (data.consumer.email.toLowerCase() === emailUserInput.toLowerCase() &&
			data.consumer.lastName.toLowerCase() === lastNameUserInput.toLowerCase()) {
			commit('setConsumerInfo', data);
			commit('setResultsHaveBeenFound', true);
			commit('setOrderNumberToDisplay', orderNumber);
		}
	}).catch(err => {
		throw err;
	}).finally(() => {
		commit('setShowResults', true);
	});
};
