<template>
    <div>
        <h4>{{ $t("message.features") }}</h4>

        <div class="row details__feature-list">
            <p v-if="isLocalBestSeller" class="col">
                <span class="details__local-best-seller">{{ $t("message.localBestSeller") }}</span>
                <span v-html="lbs"></span>
            </p>

            <p v-if="isOEM" class="col">
                <span class="details__original-equipment">{{ $t("message.originalEquipment") }}</span>
                <span v-html="oem"></span>
            </p>

            <p v-if="isSpecial" class="col">
                <span class="details__original-equipment">{{isSpecial}}</span>
            </p>
        </div>

        <div class="details__feature-box">
            <dl class="details__feature">
                <dt class="details__feature-title">{{ currProdSet.season }} {{$t("message.tire")}}</dt>
                <dd class="details__feature-content">{{ seasonDescription }}</dd>

                <template v-if="hasWarranty">
                    <dt class="details__feature-title">{{ hasWarranty }}</dt>
                    <dd class="details__feature-content">{{ $t("message.warrantyDescription") }}</dd>
                </template>

                <dt class="details__feature-title">{{ currProdSet.size }} | {{ currProdSet.serviceDescription }}</dt>
                <dd class="details__feature-content">{{ $t("message.sizeDescription") }}</dd>
            </dl>
        </div>

    </div>
</template>

<script>
import prettyLgNum from '@/filters/pretty-lg-numbers';
import { mapState } from 'vuex';
import { extend, find } from 'underscore';

export default {
	props: {
		query: {
			type: Object,
			required: true
		},
		productSet: {
			type: Object
		},
		warrantyDoc: {
			type: [String, Boolean],
			required: false,
			default: false
		}
	},
	filters: {
		prettyLgNum
	},
	computed: extend({}, mapState('content/details', [
		'oem',
		'lbs'
	]), {
		hasWarranty() {
			return (Number(this.currProdSet.warrantyMilesNumeric)) ?
				`${prettyLgNum(this.currProdSet.warrantyMilesNumeric)} ${this.$t('message.warrantyMiles')}` :
				'';
		},
		isOEM() {
			return this.currProdSet.factoryInstalled;
		},
		isSpecial() {
			return this.currProdSet.oemTireMarkLongDescription;
		},
		isLocalBestSeller() {
			return this.query.localBest;
		},
		currProdSet() {

			if (this.productSet.products.length > 1) {
				return this.productSet.products[1];
			}

			return this.productSet.products[0];
		},
		seasonDescription() {
			const [message] = find([
				['message.allSeasonDescription', this.currProdSet.season.toLowerCase() === 'all season'],
				['message.summerDescription', this.currProdSet.season.toLowerCase() === 'summer'],
				['message.winterDescription', this.currProdSet.winter]
			], ([, rule]) => rule);

			return (message) ? this.$t(message) : '';
		}
	})
};
</script>
