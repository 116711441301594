import Language from '../Language';

const language = new Language(
	'Mongolia',
	['1 дүгээр сар', '2 дугаар сар', '3 дугаар сар', '4 дүгээр сар', '5 дугаар сар', '6 дугаар сар', '7 дугаар сар', '8 дугаар сар', '9 дүгээр сар', '10 дугаар сар', '11 дүгээр сар', '12 дугаар сар'],
	['1-р сар', '2-р сар', '3-р сар', '4-р сар', '5-р сар', '6-р сар', '7-р сар', '8-р сар', '9-р сар', '10-р сар', '11-р сар', '12-р сар'],
	['Ня', 'Да', 'Мя', 'Лх', 'Пү', 'Ба', 'Бя']
);

language.ymd = true;

export default language
// eslint-disable-next-line
;
