import { mapState } from 'vuex';

export const RudderstackDealersMixin = {
	methods: {

		/**
         * When a visitor views a list of dealerships after completing search
         *
         * @param {Object} properties { dealerships, zip_code, search_query }
         * @arg {array} properties.dealerships - An array of dealerships being viewed
         * @arg {number} properties.zip_code - The zipcode the user provided
         * @returns {void}
         */
		trackDealershipsViewed() {
			const mappedDealerships = this.locationList.map((dealership) => {
				return {
					website_uid: dealership.websiteUID,
					name: dealership.dealershipname,
					dealership_id: dealership.dealershipid,
					distance: dealership.distance,
					zip: dealership.zip
				};
			});

			this.$rudderstack.track('DEALERSHIPS_VIEWED', {
				dealerships: mappedDealerships,
				zip_code: this.zipCode
			});
		},

		/**
         * When a visitor clicks on a Dealership from a list of dealerships
         *
         * @param {Object} properties { dealership_id, dealership_position, zip_code = null, latitude = null, longitude = null, total_dealerships }
         * @arg {number} properties.dealer_position - the position in the list of dealers
         * @arg {number} properties.total_dealerships - the total number of dealerships in the list
         * @arg {number} properties.dealer_distance - the dealers distance from the zip/lat,long
         * @arg {number} [properties.zip_code=null] - the zip code of the dealer
         * @arg {number} [properties.dealer_latitude=null] - the latitude of the dealer
         * @arg {number} [properties.dealer_longitude=null] - the longitude of the dealer
         * @returns {void}
         */
		trackDealershipClicked(properties) {
			const {
				dealership_id,
				dealer_position,
				dealer_distance,
				dealer_latitude = null,
				dealer_longitude = null
			} = properties;

			this.$rudderstack.track('DEALERSHIP_CLICKED', {
				dealership_id,
				dealer_position,
				zip_code: this.zipCode,
				dealer_latitude,
				dealer_longitude,
				total_dealerships: this.locationList.length,
				dealer_distance
			});
		}
	},

	computed: {
		...mapState('dealer', ['zipCode', 'locationList'])
	}
};
