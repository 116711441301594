<template>
    <div>
        <h2>{{$t('message.emailFailure')}}</h2>
    </div>
</template>

<script>
export default {
	props: {
		query: {
			type: Object,
			required: true
		}
	}
};
</script>
