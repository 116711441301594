const detailsPageURL = (query, productSet) => {
	const baseURL = window.location.origin;
	const detailsPath = '/app/details?';
	const productId = `id=${productSet.products[0].productId}`;
	let detailsQuery = '';

	for (const key in query) {
		if (key.toLowerCase() !== 'id') {
			detailsQuery += `${key}=${query[key]}&`;
		}
	}

	detailsQuery = detailsQuery.concat(productId);

	return `${baseURL}${detailsPath}${detailsQuery}`;
};

export {
	detailsPageURL
};
