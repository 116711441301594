import '@babel/polyfill';

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueMask from 'v-mask';
import VeeValidate, { Validator } from 'vee-validate';
import Vue2Filters from 'vue2-filters';
import PortalVue from 'portal-vue';
import Axios from 'axios';
import { dateTimeFormats, numberFormats } from '@/i18nConfig';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import RudderstackPlugin from './rudderstack/plugin';

library.add(faUserSecret);

// Setup files
import router from './router';
import store from './store';
import locales from './locales';

// Components
import CookieConsent from '@/components/CookieConsent.vue';
import LocaleSwitcher from '@/components/LocaleSwitcher.vue';
import DealerHeader from '@/components/DealerHeader.vue';
import DealerFooter from '@/components/DealerFooter.vue';
import DealerContact from '@/components/DealerContact.vue';

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('cookie-consent', CookieConsent);
Vue.component('locale-switcher', LocaleSwitcher);
Vue.component('dealer-header', DealerHeader);
Vue.component('dealer-footer', DealerFooter);
Vue.component('dealer-contact', DealerContact);

// Globals
Vue.use(VueI18n);
Vue.use(VueMask);
Vue.use(Vue2Filters);
Vue.use(PortalVue);
Vue.use(RudderstackPlugin, { store });

// We will use this to build a list of custom error messages from the locales/translate data.
function buildCustomLocaleDictionary(data) {
	let dictionary = {};

	Object.keys(data).forEach(localeCode => {
		let specificAttributeMessages = {};

		for (const [messageKey, messageValue] of Object.entries(data[localeCode]['message'])) {
			// The validation.messages is mainly used to make sure that we have our specific messages instead of generic ones
			// We remove it after we split so techinically it can be anything unique
			if (messageKey.startsWith('validation.messages')) {
				let messageParts = messageKey.split('.');
				// The last part will be our key for determining the value of the message
				let last = messageParts.pop();

				// remove the 'validation' and 'messages' part of the key since we don't need them
				messageParts.splice(0, 2);

				// We will drill down into the message parts and build nested objects based on the messageParts
				messageParts.reduce((o, k) => {
					o[k] = o[k] || {};

					return o[k];
				}, specificAttributeMessages)[last] = messageValue;
			}
		}

		dictionary[localeCode] = {};
		dictionary[localeCode].custom = specificAttributeMessages;
	});

	return dictionary;

}

const vmObject = {
	value: {
		$t: function(str) {
			return str;
		}
	}
};

// Create VueI18n instance with options
Axios.get(`/service/translate`).then(async ({data}) => {
	const defaultLocale = Object.keys(data).find(locale => {
		return locale === window.navigator.language.toLocaleLowerCase();
	}) || Object.keys(data)[0];

	await Axios.post('/service/language', { languageCode: defaultLocale });
	const i18n = new VueI18n({
		// set initial locale
		locale: defaultLocale,
		messages: data,
		dateTimeFormats,
		numberFormats
	});

	Validator.localize(data);

	Vue.use(VeeValidate, {
		i18n,
		dictionary: buildCustomLocaleDictionary(data)
	});

	const app = new Vue({
		i18n,
		router,
		store,
		mixins: [Vue2Filters.mixin]
	});

	vmObject.value = app;

	app.$mount('#app');

}).catch(() => {
	const i18n = new VueI18n({
	// if translations fail, still render the app using US translations
		locale: 'en-us',
		messages: {
			'en-us': {
				message: locales
			}
		},
		dateTimeFormats,
		numberFormats
	});

	Validator.localize('en', locales);

	Vue.use(VeeValidate, {
		i18n,
		dictionary: {
			'en-us': {
				messages: buildCustomLocaleDictionary(locales)
			}
		}
	});

	const app = new Vue({
		i18n,
		router,
		store,
		mixins: [Vue2Filters.mixin]
	});

	vmObject.value = app;

	app.$mount('#app');
});

export const vm = vmObject;
