import * as actions from './actions.js';

const store = {
	namespaced: true,
	state: {
		originalEquipmentDetails: '',
		localBestSellerDetails: '',
		productOverview: '',
		warrantyDoc: '',
		warrantyDocTitle: ''
	},
	getters: {
		productOverview: state => state.productOverview,
		warrantyDoc: state => state.warrantyDoc,
		warrantyDocTitle: state => state.warrantyDocTitle
	},
	// Mutations are commited and must be synchronous
	mutations: {
		originalEquipmentDetails(state, payload) {
			state.originalEquipmentDetails = payload;
		},
		localBestSellerDetails(state, payload) {
			state.localBestSellerDetails = payload;
		},
		productOverview(state, payload) {
			state.productOverview = payload;
		},
		warrantyDoc(state, payload) {
			state.warrantyDoc = payload;
		},
		warrantyDocTitle(state, payload) {
			state.warrantyDocTitle = payload;
		}
	},
	// Actions are dispatched, which then commit mutations. Can be asynchronous methods.
	actions
};

export default store;
