<template>
    <form @submit.prevent="handleFormSubmit" class="error500-form">
        <p class="error500-form__title">{{ $t('message.youreAlmostThere') }}</p>

        <p class="error500-form__msg" v-html="msg"></p>

        <div class="error500-form__inputs-wrapper">
            <BaseInput
                id="name"
                name="fullName"
                :label="$t('message.fullName')"
                autocomplete="given-name"
                v-model="form.fullName"
                data-vv-name="fullName"
                v-validate="'required'"
                :error="errors.first('fullName')"
                :required="true" />

            <BaseInput
                type="email"
                name="email"
                id="email"
                :label="$t('message.email')"
                autocomplete="email"
                v-model="form.email"
                v-validate="'required|email'"
                data-vv-name="emailAddress"
                :error="errors.first('emailAddress')"
                :required="true" />

            <BaseInput
                type="tel"
                id="phone"
                name="phone"
                :label="$t('message.phone')"
                autocomplete="tel"
                v-model="form.phone"
                :mask="'(###)###-####'"
                data-vv-name="phoneNumber"
                v-validate="{
                    regex: /^\(\d{3}\)\d{3}-\d{4}$/,
                }"
                :error="errors.first('phoneNumber')" />

            <BaseInput
                type="text"
                id="tire"
                :label="$t('message.whichTiresSearchingFor')"
                v-model="form.tire"
                :required="true" />

            <BaseInput
                type="text"
                name="comments"
                id="comments"
                :label="$t('message.whatCanWeDoToHelp')"
                v-model="form.comments" />

            <StatefulButton :isLoading="isSubmitting">{{ $t('message.submit') }}</StatefulButton>
        </div>
    </form>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import axios from 'axios';
import BaseInput from '@/components/BaseInput.vue';
import StatefulButton from '@/components/StatefulButton.vue';

export default {
	name: 'Error500Form',

	props: {
		query: {
			required: true,
			type: Object
		}
	},

	components: {
		BaseInput,
		StatefulButton
	},

	data() {
		return {
			isSubmitting: false,
			form: {
				fullName: '',
				phone: '',
				email: '',
				tire: '',
				comments: ''
			}
		};
	},

	computed: {
		...mapGetters('initial', ['getSiteLogo']),
		...mapState('initial', ['title', 'phoneNumber']),
		...mapState('dealer', { selectedDealer: 'selected' }),
		msg() {
			return this.$t('message.oopsMessage', { phone: this.selectedDealer.phoneNumber || this.phoneNumber });
		}
	},

	methods: {
		...mapMutations('dealer', ['selected']),
		async handleFormSubmit() {
			const validInfo = await this.$validator.validateAll();

			if (!validInfo) return;

			try {
				this.isSubmitting = true;

				await axios.post('/service/email/500-error-form', {
					consumer: this.form,
					domain: window.location.hostname,
					logo: this.getSiteLogo,
					title: this.title,
					dealer: this.selected,
					query: this.$route.query
				});

				this.$emit('submitted', true);
			} catch (error) {
				throw new Error(error);
			} finally {
				this.isSubmitting = false;
			}
		},
		async fetchDealerIfNotPresent() {
			const websiteUID = this.selectedDealer.websiteUID || this.query.uid;

			if (!websiteUID) return;

			try {
				const { data } = await axios.get('/service/dealerDetails', { params: { websiteUID } });

				this.selected(data);
			} catch (error) {
				throw new Error(error);
			}
		}
	},

	mounted() {
		this.fetchDealerIfNotPresent();
	}
};
</script>
