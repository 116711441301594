import Language from '../Language';

export default new Language(
	'French',
	['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
	['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
	['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam']
)
// eslint-disable-next-line
;
