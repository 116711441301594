<template>
    <div class="compare__coach">
        <!-- HIGHLIGHTS DIFFERENCES -->
        <div class="compare__coach-wrapper">
            <label
                for="showDiff"
                class="compare__coach-label"
                :class="{ 'compare__coach-label--checked': showDiffComp }"
            >
                <input
                    tabindex="0"
                    id="showDiff"
                    class="compare__coach-checkbox"
                    type="checkbox"
                    v-model="showDiffComp"
                />
                {{ $t("message.highlightDiff") }}
            </label>
        </div>

        <!-- HIDES SIMILARITIES -->
        <div class="compare__coach-wrapper">
            <label
                for="hideSame"
                class="compare__coach-label"
                :class="{ 'compare__coach-label--checked': hideSameComp }"
            >
                <input
                    tabindex="0"
                    id="hideSame"
                    class="compare__coach-checkbox"
                    type="checkbox"
                    v-model="hideSameComp"
                />
                {{ $t("message.hideSimilar") }}
            </label>
        </div>
    </div>
</template>

<script>
import SpecFilter from '@/components/CompareCoachSpecFilter.vue';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { noDataEvent } from '@/utils/setDataLayer';
import { RudderstackProductsMixin } from '@/rudderstack/track/mixins/products';

export default {
	mixins: [RudderstackProductsMixin],

	components: {
		SpecFilter
	},

	computed: {
		...mapState('products', ['showDiff', 'hideSame']),
		...mapGetters('products', ['productSetsToCompare']),
		showDiffComp: {
			get() {
				return this.showDiff;
			},
			set(boolean) {
				this.setShowDiff(boolean);
				this.trackProductCompared();
			}
		},
		hideSameComp: {
			get() {
				return this.hideSame;
			},
			set(boolean) {
				this.setHideSame(boolean);
				this.trackProductCompared();
			}
		}
	},
	watch: {
		hideSame() {
			if (this.hideSame) {
				noDataEvent('comparePageHideSimilarItems');
			}
		},
		showDiff() {
			if (this.showDiff) {
				noDataEvent('comparePageHighlightDifferences');
			}
		}
	},
	methods: {
		...mapMutations('products', ['setShowDiff', 'setHideSame'])
	}
};
</script>
