<template>
    <aside class="appointmentTimePicker" v-if="timeCurrentState" role="tabpanel" aria-labelledby="accordion-header">
        <div class="calendar__appointment-list" v-if="!isLoading">
            <fieldset>
                <legend><h2>{{$t("message.appointmentTime")}}</h2></legend>
                <div v-if="isMobile && morningSlotsAvailable && afternoonSlotsAvailable" class="calendar__timeslot-button-container">
                    <button
                        type="button"
                        class="calendar__timeslot"
                        :class=" {'calendar__timeslot--selected': timeToShow === 'am'}"
                        @click="showSlotsForSelectedTime('am')"
                    >{{$t("message.morning")}}</button>
                    <button
                        type="button"
                        class="calendar__timeslot"
                        :class=" {'calendar__timeslot--selected': timeToShow === 'pm'}"
                        @click="showSlotsForSelectedTime('pm')"
                    >{{$t("message.afternoon")}}</button>
                </div>
            </fieldset>
            <div v-if="(timeToShow === 'am' || !isMobile) && morningSlotsAvailable" class="calendar__appointment-time">

                <h3 v-if="!isMobile && morningSlotsAvailable && afternoonSlotsAvailable">{{$t("message.morning")}}</h3>

                <div class="calendar__appointment-slot"
                     v-for="(slot, timeIndex) in timeSlotsToShow.am" :key="timeIndex"
                >
                    <button
                        type="button"
                        :disabled="!slot.isAvail"
                        class="calendar__timeslot"
                        :class=" {'calendar__timeslot--selected': slot.time === selectedAppointmentTime}"
                        @click="timeslotClicked(date, slot)"
                        data-cy="calendarTimeslot"
                        :aria-pressed="checkClick(slot.time, selectedAppointmentTime)"
                    >
                        <time>
                            {{ $d(new Date(slot.displayTime),'time') }}
                        </time>
                    </button>
                </div>
            </div>
            <div v-if="(timeToShow === 'pm' || !isMobile) && afternoonSlotsAvailable" class="calendar__appointment-time">

                <h3 v-if="!isMobile && morningSlotsAvailable && afternoonSlotsAvailable">{{$t("message.afternoon")}}</h3>

                <div class="calendar__appointment-slot"
                     v-for="(slot, timeIndex) in timeSlotsToShow.pm" :key="timeIndex"
                >
                    <button
                        type="button"
                        :disabled="!slot.isAvail"
                        @click="timeslotClicked(date, slot)"
                        class="calendar__timeslot"
                        :class=" {'calendar__timeslot--selected': slot.time === selectedAppointmentTime}"
                        :aria-pressed="checkClick(slot.time, selectedAppointmentTime)"
                    >
                        <time>
                            {{ $d(new Date(slot.displayTime),'time') }}
                        </time>
                    </button>
                </div>
            </div>
        </div>
        <div v-if="isLoading" class="col-12">
            <div class="calendar__loader">
                <img
                    src="/dist/assets/loaders/tire-spinner.gif"
                    alt="Spinning loader animation"
                />
            </div>
        </div>
        <!-- Provider Logos -->
        <figure v-if="providerContent" class="col calendar__api-logo">
            <figcaption class="calendar__api-text">
                {{ providerContent.text }}
            </figcaption>
            <img :src="providerContent.path" alt="Scheduling API Logo" />
        </figure>
    </aside>
</template>

<script>
import { format, addHours, add, isEqual } from 'date-fns'; //eslint-disable-line

export default {
	name: 'AppointmentTime',
	props: {
		query: {
			required: true,
			type: Object
		},
		isTimeSlotsOpen: {
			type: Boolean,
			required: true
		},
		date: {
			type: Date,
			required: true
		},
		timeSlots: {
			type: Array,
			required: true
		},
		provider: {
			type: String,
			required: true
		},
		isMobile: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			timeSlotsToShow: [],
			selectedAppointmentTime: '',
			timeCurrentState: false,
			dateOfSlots: new Date(),
			timeToShow: 'am',
			windowSizeMixinWidth: 0,
			isLoading: false
		};
	},
	methods: {
		showSlotsForSelectedTime(time) {
			this.timeToShow = time;
		},
		timeslotClicked(date, { time, offset }) {
			// convert date to local time, so it shows right on checkout and emails.
			const apptDateTime = new Date();
			const [year, month, day] = format(date, 'yyyy-MM-dd').split('-');
			const [hours, minutes] = time.split(':');
    		const formattedHours = minutes.toLowerCase().includes('pm') && parseInt(hours) < 12 ? parseInt(hours) + 12 : parseInt(hours); //eslint-disable-line
			// FYI: Months are 0 based in JS, days are base 1
			apptDateTime.setFullYear(year, month - 1, day);
			apptDateTime.setHours(formattedHours);
			apptDateTime.setMinutes(minutes.slice(0, 2));
			apptDateTime.setSeconds(0);
			apptDateTime.setMilliseconds(0);

			const appointmentInfo = {
				offset,
				apptDateTime,
				blockStartTime: format(apptDateTime, 'hh:mm:ss aa'),
				appointmentDate: format(apptDateTime, 'yyyy-MM-dd'),
				appointmentTime: format(apptDateTime, 'HH:mm')
			};

			this.selectedAppointmentTime = time.toLowerCase();

			// Enable the CTA
			this.$emit('updateAppointmentTime', appointmentInfo);
		},
		initializeTimeslots(date, timeslots) { // eslint-disable-line complexity
			this.isLoading = true;
			this.selectedAppointmentTime = '';
			// this.dateOfSlots = format(date, 'yyy-MM-dd');
			if (timeslots && timeslots.length > 0) {
				const timeSlotsToShow = timeslots.find(slot => (format(slot.date, 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd')));

				if (timeSlotsToShow) {
					this.timeSlotsToShow = timeSlotsToShow.timeslots;
					this.timeToShow = this.timeSlotsToShow.am && this.timeSlotsToShow.am.find(slot => slot.isAvail) ? 'am' : 'pm';
				}
			}
			this.isLoading = false;
		},
		$windowSizeMixinHandleResize() {
			this.windowSizeMixinWidth = window.innerWidth;
		},
		checkClick(slotTime, selectedAppointmentTime) {
			if (slotTime === selectedAppointmentTime) {
				return 'true';
			}

			return 'false';
		}
	},
	computed: {
		morningSlotsAvailable() {
			return this.timeSlotsToShow.am && this.timeSlotsToShow.am.find(slot => slot.isAvail);
		},
		afternoonSlotsAvailable() {
			return this.timeSlotsToShow.pm && this.timeSlotsToShow.pm.find(slot => slot.isAvail);
		},
		providerContent() {
			const providers = {
				MK: {
					path: '/dist/assets/myKaarma-logo-dark.png',
					text: this.$t('message.poweredBy')
				},
				TH: {
					path: '/dist/assets/timehighway-logo.png',
					text: ''
				},
				XT: {
					path: '/dist/assets/xtime-logo.png',
					text: this.$t('message.poweredBy')
				},
				AL: {
					path: '/dist/assets/autoloop.png',
					text: ''
				}
			};

			return providers[this.provider] ? providers[this.provider] : false;
		}
	},
	created() {
		window.addEventListener('resize', this.$windowSizeMixinHandleResize);
		this.$windowSizeMixinHandleResize();
	},
	watch: {
		isTimeSlotsOpen(val) {
			this.timeCurrentState = val;
		},
		provider(name) {
			if (name !== 'SL') {
				this.selectedAppointmentTime = '';
			}
		},
		date(updatedDate) {
			return this.initializeTimeslots(updatedDate, this.timeSlots);
		}
	}
};
</script>
