<template>
    <!-- TEST -->
    <div role="gridcell" class="compare-table__spec">
        <template v-for="(promoTitleAndDescription, promoIndex) in promoTitlesAndDescriptions">

            <div v-if="!promoTitleAndDescription.title"
                 :key="promoIndex">
                {{ $t('message.noPromos') }}
            </div>

            <div v-else class="compare-row__promo"
                 :key="promoTitleAndDescription.title">
                <button tabindex="-1" class="compare-row__promo-button"
                        @click="togglePromoToShow(promoTitleAndDescription)"
                        aria-label="Show Promotion Description">

                    <span class="compare-row__promo-link">
                        <span class="compare-row__promo-linktext">
                            {{ promoTitleAndDescription.title }}
                        </span>
                        <i class="icon icon--angle-down compare__arrow"
                           :class="{ 'compare__arrow--active': showPromo(promoTitleAndDescription) }"/>
                    </span>
                </button>
                <!-- PROMO DESCRIPTION WHEN "DROPDOWN"  TOGGLED -->
                <div v-show="showPromo(promoTitleAndDescription)"
                     class="compare-row__promo-text"
                     :key="promoTitleAndDescription.description">
                    {{ promoTitleAndDescription.description }}
                </div>
            </div>

        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { isEmpty } from 'underscore';

export default {
	props: {
		promoTitlesAndDescriptions: {
			type: Array,
			require: true
		}
	},
	data() {
		return {
			promoToShow: {},
			noPromos: false
		};
	},

	computed: {
		...mapState('products', [
			'productSetsToCompare',
			'singleProductSetToCompare'
		])
	},
	created() {
		this.checkNoPromos();
	},
	watch: {
		productSetsToCompare() {
			this.promoToShow = {};
		},
		singleProductSetToCompare() {
			this.promoToShow = {};
		}
	},
	methods: {
		checkNoPromos() {
			if (!this.promoTitlesAndDescriptions.length) {
				this.promoTitlesAndDescriptions.push([]);
				this.noPromos = true;
			} else {
				this.noPromos = false;
			}
		},
		showPromo(promo) {

			let returnBool = false;

			// This component should now only get applied promos, but just in case...
			if (promo.applied) {
				returnBool = JSON.stringify(this.promoToShow) === JSON.stringify(promo);
			}

			return returnBool;
		},
		// Sets promoToShow if empty or if different promotion is clicked. Otherwise clears.
		togglePromoToShow(promo) {
			if (isEmpty(this.promoToShow) || !this.showPromo(promo)) {
				this.promoToShow = promo;
			} else {
				this.promoToShow = {};
			}
		}
	}
};
</script>
