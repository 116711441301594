import { get } from 'axios';

const store = {
	namespaced: true,
	state: {
		primary: '',
		secondary: '',
		ad: '',
		headline: '',
		noDealership: ''
	},
	mutations: {
		content(state, { prop, html }) {
			state[prop] = html;
		}
	},
	actions: {
		cms({ commit, state }, { prop, slug, isUpdate }) {
			if (!state[prop] || isUpdate) {
				get(`/service/cms/${slug}`).then(({ data }) => {
					commit('content', {
						prop,
						html: data.html
					});
				});
			}
		},
		fetchContent({ dispatch }, isUpdate) {
			dispatch('cms', {
				prop: 'primary',
				slug: 'dealer-primary',
				isUpdate
			});

			dispatch('cms', {
				prop: 'secondary',
				slug: 'dealer-secondary',
				isUpdate
			});

			dispatch('cms', {
				prop: 'ad',
				slug: 'dealer-ad',
				isUpdate
			});

			dispatch('cms', {
				prop: 'headline',
				slug: 'dealer-headline',
				isUpdate
			});

			dispatch('cms', {
				prop: 'noDealership',
				slug: 'dealer-no-dealership',
				isUpdate
			});
		}
	}
};

export default store;
