<template>
    <dialog class="dialog" aria-modal="true" :aria-label="ariaLabel">
        <!-- Header -->
        <header class="dialog__header">
            <button
                v-if="showCloseButton"
                @click="close"
                class="dialog__close"
                data-cy="modalCloseButton"
            >
                <IconClose />
                <span class="sr-only">Close Modal</span>
            </button>

            <slot name="header"></slot>
        </header>

        <!-- Body -->
        <div class="dialog__body">
            <slot></slot>
        </div>

        <!-- Footer -->
        <footer class="dialog__footer">
            <slot name="footer"></slot>
        </footer>
    </dialog>
</template>

<script>
import IconClose from '@/components/IconClose';

export default {
	name: 'DialogModal',

	props: {
		showCloseButton: {
			type: Boolean,
			required: false,
			default: true
		},
		isOpen: {
			type: Boolean,
			required: false
		},
		// Disable the modal being closed by the `Esc` button
		disableEscape: {
			type: Boolean,
			required: false,
			default: false
		},
		ariaLabel: {
			type: String,
			required: true
		}
	},

	components: {
		IconClose
	},

	watch: {

		/**
         * The value to determine if the modal should be opened.
         * `showModal()` is the native way to display the dialog as a modal.
         * @param {Boolean} newVal - the new value of the prop after it changes
         * @return {void} - does not return anything, just show the modal
         */
		isOpen(newVal) {
			if (newVal) {
				this.$el.showModal();
			} else {
				this.close();
			}
		}
	},

	methods: {
		close() {
			this.$el.close();
		}
	},

	mounted() {
		// Check to see if the modal should be open by default
		if (this.isOpen) {
			this.$el.showModal();
		}

		/**
		 * If we want to disable escaping from the modal and force action.
		 * For example, the appointment time is no longer available.
		 */
		this.$el.addEventListener('cancel', (e) => {
			if (this.disableEscape) {
				e.preventDefault();
			}
		});

		/**
         * When this.close() is called the browser fires the `close` DOM Event.
         * We listen for the event on the <dialog> and $emit('close').
         * Doing it this way allows us to respond to both `Esc` and Close button being clicked.
         */
		this.$el.addEventListener('close', () => {
			this.$emit('close');
		});
	}
};
</script>
