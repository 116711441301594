<template>
    <div v-if="showCookieConsent" class="cookie-consent">

        <p class="cookie-copy">
            {{ $t("message.cookiePolicyText") }}
            <a href="/privacy-policy" target="_blank">{{ $t("message.cookiePolicyLink") }}</a>
        </p>

        <button class="cookie-consent__button"
                @click="consentToCookies(true)"
                aria-label="Consent to use of cookies">
            <i class="icon icon--close"/>
        </button>
    </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
	computed: {
		...mapState('content/cookie', [
			'cookieContent'
		]),
		...mapState('modal', [
			'hasConsentedToCookies'
		]),
		showCookieConsent() {
			return !this.hasConsentedToCookies;
		},
		query() {
			return this.$route.query;
		}
	},
	watch: {
		query() {
			this.consentToCookies(true);
		}
	},
	methods: {
		...mapMutations('modal', [
			'consentToCookies'
		])
	},
	created() {
		if (localStorage.getItem('cookies') === 'true') {
			this.consentToCookies(true);
		}
	}
};
</script>
