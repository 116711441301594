import { get } from 'axios';

const store = {
	namespaced: true,
	state: {
		policy: '',
		ad: '',
		headline: '',
		winter: '',
		transport: '',
		mobileInstall: ''
	},
	mutations: {
		content(state, { prop, html }) {
			state[prop] = html;
		}
	},
	actions: {
		cms({ commit, state }, { prop, slug, isUpdate }) {
			if (!state[prop] || isUpdate) {
				get(`/service/cms/${slug}`).then(({ data }) => {
					commit('content', {
						prop,
						html: data.html
					});
				});
			}
		},
		fetchContent({ dispatch }, isUpdate) {
			dispatch('cms', {
				prop: 'policy',
				slug: 'checkout-policy',
				isUpdate
			});

			dispatch('cms', {
				prop: 'ad',
				slug: 'checkout-ad',
				isUpdate
			});

			dispatch('cms', {
				prop: 'headline',
				slug: 'checkout-headline',
				isUpdate
			});

			dispatch('cms', {
				prop: 'winter',
				slug: 'checkout-winter',
				isUpdate
			});

			dispatch('cms', {
				prop: 'transport',
				slug: 'checkout-transport',
				isUpdate
			});
			// Apr 2024: removing mobile install content for now
			// dispatch('cms', {
			// 	prop: 'mobileInstall',
			// 	slug: 'checkout-mobile-install',
			// 	isUpdate
			// }));
		}
	}
};

export default store;
