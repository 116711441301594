export const windowSizeMixin = {
	data() {
		return {
			windowSizeMixinWidth: 0
		};
	},
	computed: {
		$windowSizeMixinWindowWidthIsMobile() {
			return this.windowSizeMixinWidth < 768;
		},
		$windowSizeMixinWindowWidthIsMobileInclusive() {
			return this.windowSizeMixinWidth <= 768;
		},
		$windowSizeMixinWindowWidthIsTablet() {
			return this.windowSizeMixinWidth >= 768 && this.windowSizeMixinWidth < 1024;
		}
	},
	created() {
		window.addEventListener('resize', this.$windowSizeMixinHandleResize);
		this.$windowSizeMixinHandleResize();
	},
	destroyed() {
		window.removeEventListener('resize', this.$windowSizeMixinHandleResize);
	},
	methods: {
		$windowSizeMixinHandleResize() {
			this.windowSizeMixinWidth = window.innerWidth;
		}
	}
};
