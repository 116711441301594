<template>
    <svg :width="width" :height="height" viewBox="0 0 24 24" fill="none">
        <path
            stroke-linejoin="round"
            stroke-linecap="round"
            stroke-width="1"
            :stroke="stroke"
            d="M15.9996 15.2877L15.2925 15.9948L21.2958 21.9981L22.0029 21.291L15.9996 15.2877Z">
        </path>
        <path
            stroke-linejoin="round"
            stroke-linecap="round"
            stroke-width="1"
            :stroke="stroke"
            fill="none"
            d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18Z">
        </path>
    </svg>
</template>

<script>
export default {
	name: 'IconSearch',

	props: {
		width: {
			type: String,
			default: '24'
		},
		height: {
			type: String,
			default: '24'
		},
		stroke: {
			type: String,
			default: '#221b38'
		}
	}
};
</script>

