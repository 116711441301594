const curry = require('../curry');

/* eslint-disable */
const path = ([key, ...keys], obj) => {
	if (!keys.length) {
		return obj[key];
	}

	if (obj[key] == null) {
		return undefined;
	}

	return path(keys, obj[key]);
};
/* eslint-enable */

module.exports = curry(path);
