<template>
    <!-- Form -->
    <form class="form" @submit.prevent="submit">
        <ul class="input__list">
            <li class="row">
                <!-- First Name -->
                <div class="col-md-4 col-lg-3 input">
                    <label class="input__label" for="firstName">{{
                        $t("message.firstName")
                    }}</label>
                    <input
                        id="firstName"
                        name="firstName"
                        type="text"
                        maxlength="100"
                        v-model="form.firstName"
                        data-vv-name="firstName"
                        v-validate="{ required: true, regex: /^[a-zA-Z]+[a-zA-Z .,'-]*$/ }"
                        :class="[
                            'input__field',
                            { 'input__field--error': errors.has('firstName') },
                        ]"
                    />
                    <small
                        v-if="errors.has('firstName')"
                        class="input__error-reason form__error"
                    >
                        {{ errors.first("firstName") }}
                    </small>
                </div>

                <!-- Last Name -->
                <div class="col-md-4 col-lg-3 input">
                    <label class="input__label" for="lastName">{{
                        $t("message.lName")
                    }}</label>
                    <input
                        id="lastName"
                        name="lastName"
                        type="text"
                        maxlength="100"
                        v-model="form.lastName"
                        data-vv-name="lastName"
                        v-validate="{ required: true, regex: /^[a-zA-Z]+[a-zA-Z .,'-]*$/ }"
                        :class="[
                            'input__field',
                            { 'input__field--error': errors.has('lastName') },
                        ]"
                    />
                    <small
                        v-if="errors.has('lastName')"
                        class="input__error-reason form__error"
                    >
                        {{ errors.first("lastName") }}
                    </small>
                </div>
            </li>

            <li class="row">
                <!-- Email Address -->
                <div class="col-md-4 col-lg-3 input">
                    <label class="input__label" for="email">{{
                        $t("message.email")
                    }}</label>
                    <input
                        id="email"
                        name="email"
                        type="email"
                        maxlength="100"
                        v-model="form.email"
                        data-vv-name="emailAddress"
                        v-validate="'required|email'"
                        :class="[
                            'input__field',
                            { 'input__field--error': errors.has('emailAddress') },
                        ]"
                    />
                    <small
                        v-if="errors.has('emailAddress')"
                        class="input__error-reason form__error"
                    >
                        {{ errors.first("emailAddress") }}
                    </small>
                </div>

                <!-- Telephone -->
                <div class="col-md-4 col-lg-3 input">
                    <label class="input__label" for="tel">
                        {{ $t("message.phone") }} ({{ $t("message.optional") }})
                    </label>
                    <input
                        id="tel"
                        name="tel"
                        type="tel"
                        placeholder="(___)___-____"
                        v-mask="'(###)###-####'"
                        v-model="form.phone"
                        data-vv-name="phoneNumber"
                        v-validate="{ regex: /^\(\d{3}\)\d{3}-\d{4}$/ }"
                        :class="[
                            'input__field',
                            { 'input__field--error': errors.has('phoneNumber') },
                        ]"
                    />
                    <small
                        v-if="errors.has('phoneNumber')"
                        class="input__error-reason form__error"
                    >
                        {{ errors.first("phoneNumber") }}
                    </small>
                </div>
            </li>

            <li class="row">
                <!-- Comments -->
                <div class="col-md-12 col-lg-9 input">
                    <label class="input__label" for="comments">
                        {{ $t("message.comments") }} ({{ $t("message.optional") }})
                    </label>
                    <textarea
                        id="comments"
                        name="comments"
                        type="text"
                        maxlength="400"
                        v-model="form.comments"
                        data-vv-name="comments"
                        v-validate="{ regex: /^[a-zA-Z0-9 ,=/'@&()?$#.!\n\-]*$/ }"
                        :class="[
                            'input__field',
                            { 'input__field--error': errors.has('comments') },
                        ]"
                    >
                    </textarea>
                    <small
                        v-if="errors.has('comments')"
                        class="input__error-reason form__error"
                    >
                        {{ $t("message.commentsError") }}
                    </small>
                </div>
            </li>
        </ul>

        <!-- Submit -->
        <stateful-btn
            :optClass="'form__btn'"
            :isLoading="isLoading"
            :status="status"
            type="submit"
        >
            {{ $t("message.contact") }}
        </stateful-btn>
    </form>
</template>

<script>
import statefulBtn from '@/components/StatefulButton.vue';
import axios from 'axios';
import { mapState } from 'vuex';

export default {
	components: {
		statefulBtn
	},
	props: {
		query: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			form: {
				comments: '',
				email: '',
				firstName: '',
				lastName: '',
				phone: ''
			},
			isLoading: false,
			status: false
		};
	},
	computed: {
		...mapState('dealer', ['locationInfo'])
	},
	methods: {
		submit() {
			this.isLoading = true;
			this.$validator.validateAll().then((isConsumerInfoValid) => {
				if (isConsumerInfoValid) {
					const config = {
						method: 'POST',
						url: '/service/email/no-dealer',
						data: {
							consumer: this.form,
							location: JSON.stringify(this.locationInfo, null, '\t'),
							domain: window.location.hostname
						}
					};

					axios(config)
						.then(({ data }) => {
							if (data.accepted.length) {
								return this.$router.push({
									name: 'noDealerSuccess',
									query: this.query
								});
							}

							return this.$router.push({
								name: 'noDealerFail',
								query: this.query
							});
						})
						.catch(() => {
							return this.$router.push({
								name: 'noDealerFail',
								query: this.query
							});
						});
				}

				this.isLoading = false;
			});
		}
	}
};
</script>
