<template>
    <svg  width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path stroke-linejoin="round" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1" stroke="#221b38" d="M2 2L22 22"/>
        <path stroke-linejoin="round" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1" stroke="#221b38" d="M22 2L2 22"/>
    </svg>
</template>

<script>
export default {
	name: 'IconClose'
};
</script>

