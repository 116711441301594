import { get } from 'axios';

const store = {
	namespaced: true,
	state: {
		primary: '',
		secondary: '',
		mobileInstallPrimary: '',
		mobileInstallSecondary: '',
		policy: '',
		ad: '',
		headline: ''
	},
	mutations: {
		content(state, { prop, html }) {
			state[prop] = html;
		}
	},
	actions: {
		cms({ commit, state }, { prop, slug }) {
			if (!state[prop]) {
				get(`/service/cms/${slug}`).then(({ data }) => {
					commit('content', {
						prop,
						html: data.html
					});
				});
			}
		},
		fetchContent({ dispatch }) {
			dispatch('cms', {
				prop: 'primary',
				slug: 'confirmation-primary'
			});

			dispatch('cms', {
				prop: 'secondary',
				slug: 'confirmation-1'
			});

			dispatch('cms', {
				prop: 'mobileInstallPrimary',
				slug: 'confirmation-mobile-install-primary'
			});

			dispatch('cms', {
				prop: 'mobileInstallSecondary',
				slug: 'confirmation-mobile-install-secondary'
			});

			dispatch('cms', {
				prop: 'policy',
				slug: 'confirmation-policy'
			});

			dispatch('cms', {
				prop: 'ad',
				slug: 'confirmation-ad'
			});

			dispatch('cms', {
				prop: 'headline',
				slug: 'confirmation-headline'
			});
		}
	}
};

export default store;
