import {
	calculateServicesCost,
	generateGlobalProductProperties,
	generateGlobalProductPricing
} from '../helpers';

export const RudderstackReceiptMixin = {
	methods: {

		/**
		 * Fires when the Checkout page is done loading
		 *
		 * @param {object} properties - Properties
		 * @arg {number} properties.subTotal - Subotal after applying discount(s) & coupon(s)
		 * @arg {number} properties.total - Total of transaction, excluding the shipping and tax details
		 * @arg {number} properties.tax - Total tax associated with the order or transaction
		 * @arg {array} properties.promotions - Total discount associated with the transaction
		 * @arg {array} properties.rebates - Rebates associated with the transaction
		 * @arg {string} properties.currency - Currency code associated with an order or transaction
		 * @arg {array} properties.sku - Contains the list of products in the order or transaction
		 * @arg {array} properties.product_details - Tire objects containing: install price, tire price, quantity of each tire bought
		 * @arg {array} properties.product_id - Array of product id's
		 * @arg {array} properties.quantity - Total number of all tires purchased
		 * @arg {string} properties.service_added - 'Y' or 'N' if services have been selected
		 * @arg {array} properties.services - Array of Services Requested
		 * @arg {number} properties.services_cost - Total Cost of Services Selected
		 * @arg {string} properties.appointment_type - Eg. Drop Off
		 * @arg {Date} properties.date_selected - Date of Appointment Selected
		 * @arg {Date} properties.time_selected - Time selected for the appointment
		 * @arg {string} properties.financing_avalaible - 'Y' or 'N'
		 * @arg {string} properties.financing_selected - 'Y' or 'N'
		 * @arg {object} properties.appointment - all the appointment info (date, time, type)
		 * @returns {void}
		 */
		trackOrderCompleted() {
			this.$rudderstack.track('ORDER_COMPLETED', {
				'total': Number(this.receipt.payment.grandTotalAmount),
				'subTotal': Number(this.receipt.payment.subTotalAmount),
				'tax': Number(this.receipt.payment.supplementalChargesAmount),
				'promotions': this.receipt.promotions,
				'currency': 'USD',
				'products': generateGlobalProductProperties(this.receipt.products),
				'product_pricing': generateGlobalProductPricing(this.receipt.products),
				'quantity': this.receipt.qtySelected,
				'service_added': this.receipt.additionalServices.length > 0 ? 'Y' : 'N',
				'services': this.receipt.additionalServices,
				'services_cost': calculateServicesCost(this.receipt.additionalServices),
				'appointment_type': this.receipt.appointmentType.name,
				'appointment_date': this.receipt.appointment.time.appointmentDate,
				'appointment_time': this.receipt.appointment.time.apptDateTime,
				'financing_avalaible': this.isFinancingAvailable,
				'financing_selected': this.receipt.consumer.selectedFinancing || false,
				'dealership_id': this.receipt.dealer.dealershipid
			});
		}
	},

	computed: {
		isFinancingAvailable() {
			const financingObjectExists = Object.prototype.hasOwnProperty.call(this.receipt, 'financing');
			const financingIsPopulated = Object.keys(this.receipt.financing).length > 0;

			return (financingObjectExists && financingIsPopulated);
		}
	}
};
