<template>
    <div class="grid-row" role="presentation">

        <!-- STICKY HEADER -->
        <header-cell-sticky
            v-for="(productSet, productSetIndex) in productSetsToCompare"
            :key="productSetIndex"
            :productSet="productSet"
        />
    </div>
</template>

<script>
import HeaderCellSticky from '@/components/CompareHeaderCellSticky.vue';
import { mapState } from 'vuex';


export default {
	components: {
		HeaderCellSticky
	},
	computed: {
		...mapState('products', [
			'productSetsToCompare'
		])
	}
};
</script>
