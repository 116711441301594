import consumerInfo from './modules/consumerInfo';
import cookie from './modules/cookie';
import dealer from './modules/dealer';
import details from './modules/details';
import noDealer from './modules/noDealer';
import payment from './modules/payment';
import products from './modules/product';
import receipt from './modules/receipt';
import schedule from './modules/schedule';
import shopBySize from './modules/shopBySize';
import verify from './modules/verify';

import axios from 'axios';
import ensureArray from '@/utils/ensure-array';
import path from '@/utils/path';

const articlePath = path([
	'contentDetailsResponse',
	'articles',
	'article'
]);

const contentBodyPath = path([
	'0',
	'articleContents',
	'articleContent',
	'contentBody'
]);

const store = {
	namespaced: true,
	modules: {
		consumerInfo,
		cookie,
		products,
		verify,
		dealer,
		details,
		noDealer,
		payment,
		receipt,
		schedule,
		shopBySize
	},
	state: {
		spotlight: '',
		trivia: '',
		advertisement: ''
	},
	mutations: {
		content(state, { prop, html }) {
			state[prop] = html;
		}
	},
	actions: {
		doc({ commit, state }, { prop }) {
			if (!state[prop]) {
				axios.get('/service/docs/', {
					params: {
						articleType: prop,
						path: '/',
						section: 'Right Turn White Label'
					}
				}).then(({ data }) => {
					const article = ensureArray(articlePath(data));
					const contentBody = contentBodyPath(article);

					if (contentBody) {
						commit('content', {
							prop,
							html: contentBody
						});
					}
				});
			}
		},
		fetchDocs({ dispatch }) {
			dispatch('doc', {
				prop: 'spotlight'
			});

			dispatch('doc', {
				prop: 'trivia'
			});

			dispatch('doc', {
				prop: 'advertisement'
			});
		}
	}
};

export default store;
