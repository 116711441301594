<template>
    <div
        :id="name"
        v-if="active"
        :class="['tab-pane', { active: active }]">
        <slot></slot>
    </div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			required: true
		},
		name: {
			type: String,
			required: true
		},
		dataCy: {
			type: String,
			required: false
		}
	},
	computed: {
		active() {
			const { $children, activated } = this.$parent;

			if ($children[activated]) {
				return $children[activated].name === this.name;
			}

			return false;
		}
	},
	mounted() {
		this.$parent.tabs.push(this);
	}
};
</script>
