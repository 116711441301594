const store = {
	namespaced: true,
	state: {
		orgURL: {
			dev: 'https://dealertire--DTstaging.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8',
			prod: 'https://dealertire.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8'
		},
		orgID: {
			dev: '00D1F000000Otth',
			prod: '00D41000000VRVx'
		},
		recordType: {
			dev: '012410000012ZHX',
			prod: '012410000012ZHX'
		},
		type: {
			dev: 'SET Mobile Install',
			prod: 'SET Mobile Install'
		}
	},
	getters: {
		getSFID: (state, getters, rootState) => (param) => {
			let env = rootState.initial.env.isProd ? 'prod' : 'dev';

			return state[param][env];
		}
	}
};

export default store;
