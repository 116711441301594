<template>
    <div :class="['row no-gutters', { 'tire-coach--fade': this.disabled }]">
        <div class="col-lg-12">
            <section id="tireCoachComp" ref="tireCoachWrapper" class="tire-coach">
                <!-- Tire Coach Header -->
                <div class="hidden-lg-down tire-coach__header-wrapper">
                    <span class="tire-coach__rec-icon-wrapper">
                        <i class="tire-coach__rec-icon icon icon--thumbs-up"></i>
                    </span>

                    <!-- CMS Content -->
                    <div class="tire-coach__header" v-html="recommendations"></div>
                </div>

                <!-- Small Viewport Button -->
                <button
                    class="hidden-lg-up tire-coach__sm-button"
                    :disabled="this.disabled"
                    @click="toggleTireCoachForm"
                >
                    <span class="accessibility__label">{{
                        $t("message.accessibilityToggleTireCoach")
                    }}</span>
                    <span class="tire-coach__rec-icon-wrapper--md">
                        <i class="tire-coach__rec-icon icon icon--thumbs-up"></i>
                    </span>

                    <!-- CMS Content -->
                    <div class="tire-coach__header" v-html="recommendations"></div>

                    <div class="tire-coach__arrows">
                        <i class="icon icon--double-angle-right"></i>
                    </div>
                </button>

                <!-- Tire Coach Form -->
                <div ref="tireCoachForm" class="tire-coach__form-wrapper">
                    <div class="tire-coach__form">
                        <button
                            @click.stop="toggleTireCoachForm"
                            :disabled="this.disabled"
                            class="hidden-lg-up tire-coach__back-btn"
                        >
                            <div class="tire-coach__back-btn-left"></div>
                            <div class="tire-coach__back-btn-right">
                                <i class="icon icon--double-angle-right"></i>
                            </div>
                            <span class="accessibility__label">{{
                                $t("message.accessibilityToggleTireCoach")
                            }}</span>
                        </button>

                        <!-- Weather -->
                        <section class="row check-options">
                            <h5 class="col-7 check-options__header">
                                {{ $t("message.weatherDriven") }}?
                            </h5>
                            <div class="col-5 tire-coach__hint">
                                {{ $t("message.selectAllApply") }}
                            </div>

                            <div class="col-6 col-md-4 col-lg-6">
                                <div class="check-options__input-wrapper">
                                    <label class="check-options__label">
                                        <input
                                            class="check-options__input"
                                            :disabled="this.disabled"
                                            type="checkbox"
                                            :value="1"
                                            v-model.number="filters.weatherType"
                                            @change="runRecommend($event, 'Hot sunny days')"
                                        />
                                        {{ $t("message.sunnyDays") }}
                                    </label>
                                </div>
                            </div>

                            <div class="col-6 col-md-4 col-lg-6">
                                <div class="check-options__input-wrapper">
                                    <label class="check-options__label">
                                        <input
                                            class="check-options__input"
                                            :disabled="this.disabled"
                                            type="checkbox"
                                            :value="2"
                                            v-model.number="filters.weatherType"
                                            @change="runRecommend($event, 'Occasional rain')"
                                        />
                                        {{ $t("message.rain") }}
                                    </label>
                                </div>
                            </div>

                            <div class="col-6 col-md-4 col-lg-6">
                                <div class="check-options__input-wrapper">
                                    <label class="check-options__label">
                                        <input
                                            class="check-options__input"
                                            :disabled="this.disabled"
                                            type="checkbox"
                                            :value="3"
                                            v-model.number="filters.weatherType"
                                            @change="runRecommend($event, 'Frequent rain')"
                                        />
                                        {{ $t("message.moreRain") }}
                                    </label>
                                </div>
                            </div>

                            <div class="col-6 col-md-4 col-lg-6">
                                <div class="check-options__input-wrapper">
                                    <label class="check-options__label">
                                        <input
                                            class="check-options__input"
                                            :disabled="this.disabled"
                                            type="checkbox"
                                            :value="4"
                                            v-model.number="filters.weatherType"
                                            @change="runRecommend($event, 'Snow, ice, rain')"
                                        />
                                        {{ $t("message.snow") }}
                                    </label>
                                </div>
                            </div>

                            <div class="col-6 col-md-4 col-lg-6">
                                <div class="check-options__input-wrapper">
                                    <label class="check-options__label">
                                        <input
                                            class="check-options__input"
                                            :disabled="this.disabled"
                                            type="checkbox"
                                            :value="5"
                                            v-model.number="filters.weatherType"
                                            @change="runRecommend($event, 'Extreme winter')"
                                        />
                                        {{ $t("message.winter") }}
                                    </label>
                                </div>
                            </div>
                        </section>

                        <!-- Miles Driven Yearly -->
                        <section class="row range">
                            <h5 class="col-12 range__header">
                                {{ $t("message.milesDriven") }}?
                            </h5>
                            <div class="col-12">
                                <div class="row no-gutters range__label-wrapper">
                                    <p class="col range__label">{{ $t("message.under") }} 5k</p>
                                    <p class="col range__label">10k</p>
                                    <p class="col range__label">{{ $t("message.over") }} 15k</p>
                                </div>
                            </div>
                            <div class="col-12">
                                <input
                                    class="range__input"
                                    :disabled="this.disabled"
                                    type="range"
                                    min="1"
                                    max="5"
                                    step="1"
                                    :title="$t('message.milesDrivenTitle')"
                                    @change="
                                        runRecommend($event, { milesDriven: filters.milesDriven })
                                    "
                                    v-model.number="filters.milesDriven"
                                />
                            </div>
                        </section>

                        <!-- Driving Style -->
                        <section class="row range">
                            <h5 class="col-12 range__header">
                                {{ $t("message.drivingStyle") }}?
                            </h5>
                            <div class="col-12">
                                <div class="row no-gutters range__label-wrapper">
                                    <p class="col range__label">{{ $t("message.turtle") }}</p>
                                    <p class="col range__label">{{ $t("message.typical") }}</p>
                                    <p class="col range__label">{{ $t("message.hare") }}</p>
                                </div>
                            </div>
                            <div class="col-12">
                                <input
                                    class="range__input"
                                    :disabled="this.disabled"
                                    type="range"
                                    min="1"
                                    max="5"
                                    step="1"
                                    :title="$t('message.drivingStyleTitle')"
                                    @change="
                                        runRecommend($event, { drivingStyle: filters.drivingStyle })
                                    "
                                    v-model.number="filters.drivingStyle"
                                />
                            </div>
                        </section>

                        <!-- Activites -->
                        <section v-if="showOtherActivities" class="row check-options">
                            <h5 class="col-7 check-options__header">
                                {{ $t("message.otherActivities") }}?
                            </h5>
                            <div class="col-5 tire-coach__hint">
                                {{ $t("message.selectAllApply") }}
                            </div>

                            <div class="col-6 col-md-4 col-lg-6">
                                <div class="check-options__input-wrapper">
                                    <label class="check-options__label">
                                        <input
                                            class="check-options__input"
                                            :disabled="this.disabled"
                                            type="checkbox"
                                            value="offRoad"
                                            v-model="filters.truckOpts.offRoad"
                                            @change="runRecommend($event, 'Offroad')"
                                        />
                                        {{ $t("message.offRoad") }}
                                    </label>
                                </div>
                            </div>

                            <div class="col-6 col-md-4 col-lg-6">
                                <div class="check-options__input-wrapper">
                                    <label class="check-options__label">
                                        <input
                                            class="check-options__input"
                                            :disabled="this.disabled"
                                            type="checkbox"
                                            value="tow"
                                            v-model="filters.truckOpts.towing"
                                            @change="runRecommend($event, 'towing')"
                                        />
                                        {{ $t("message.tow") }}
                                    </label>
                                </div>
                            </div>

                            <div class="col-12 col-md-4 col-lg-12">
                                <div class="check-options__input-wrapper">
                                    <label class="check-options__label">
                                        <input
                                            class="check-options__input"
                                            :disabled="this.disabled"
                                            type="checkbox"
                                            value="heavyLoads"
                                            v-model="filters.truckOpts.heavyLoads"
                                            @change="runRecommend($event, 'Heavy loads')"
                                        />
                                        {{ $t("message.heavyLoads") }}
                                    </label>
                                </div>
                            </div>
                        </section>

                        <template v-if="winterTires">
                            <hr class="tire-coach__hr" />
                            <!-- Recommended Switch -->
                            <section class="row no-gutters recommended-option">
                                <div class="col-2">
                                    <div
                                        class="
                      tire-coach__mountain-snowflake
                      icon icon--mountain-snowflake
                    "
                                    ></div>
                                </div>
                                <h6 class="col-7 tire-coach__winter-msg">
                                    Recommend
                                    <strong>only</strong> Winter Tires
                                </h6>
                                <div class="col-3 switch">
                                    <label class="switch__label">
                                        <input
                                            class="switch__input"
                                            type="checkbox"
                                            :disabled="this.disabled"
                                            v-model="filters.recommendWinter"
                                            @change="
                                                runRecommend($event, 'Recommend only winter tires')
                                            "
                                        />
                                        <div class="switch__slider switch__slider--round"></div>
                                        <span class="accessibility__label">{{
                                            $t("message.accessibilityToggleWinterOnly")
                                        }}</span>
                                    </label>
                                </div>
                            </section>
                        </template>
                    </div>
                <!-- tire-coach__form -->
                </div>
            <!-- tire-coach__wrapper -->
            </section>
        </div>
    </div>
</template>

<script>
import { extend, isEmpty, max } from 'underscore';
import { mapState } from 'vuex';
import { filterChoice, setTireCoachFilters } from '@/utils/setDataLayer';

export default {
	props: {
		disabled: {
			required: true,
			type: Boolean
		},
		year: {
			required: true,
			type: Number
		}
	},
	data() {
		return {
			filters: {
				vehicleYear: this.year,
				milesDriven: 3,
				weatherType: [],
				truckOpts: {
					offRoad: false,
					towing: false,
					heavyLoads: false
				},
				drivingStyle: 3,
				recommendWinter: false
			},
			showOtherActivities: this.showTruck,
			winterTires: false
		};
	},
	computed: extend(mapState('content/products', ['recommendations']), mapState('products', ['showTruck']), {}),
	methods: {
		toggleTireCoachForm() {
			// Only transition if the viewport is below the break-point that hides the element
			if (window.innerWidth < 992) {
				document.getElementsByTagName('html')[0].classList.toggle('no-overflow');
				this.$refs.tireCoachForm.classList.toggle('tire-coach--is-fixed');
			}
		},
		runRecommend(event, filter) {
			// Google analytics for the chosen filter

			let filterType = event.target.type;
			let isChecked = event.target.checked;

			filterChoice(filter, filterType, isChecked);

			// Since everything else will always have a value, we need to wait for them to select a weather value
			// Before we run any kind of recommendation

			// Sets the state of the filters for the dataLayer
			setTireCoachFilters(this.filters);

			this.winterTires = !isEmpty(this.filters.weatherType) && max(this.filters.weatherType) >= 4;
			if (!this.filters.weatherType.length) {
				this.filters.recommendWinter = false;

				// Even though the filters didn't change the results they still need to be pushed
				this.$emit('pushDataLayer');

				return false;
			}

			if (!this.winterTires) {
				this.filters.recommendWinter = false;
			}

			return this.$emit(
				'recommend',
				extend({}, this.filters, {
					weatherType: max(this.filters.weatherType)
				})
			);
		}
	},
	beforeMount() {
		setTireCoachFilters(this.filters);
	}
};
</script>
