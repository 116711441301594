export const RudderstackLocationMixin = {
	methods: {

		/**
		 * When the user either enters their zipcode and clicks on search or clicks on use my current location.
		 *
		 * @param {object} properties { location_shared = 'N', zip_code = null, latitude = null, longitude = null }
		 * @arg {string} [properties.location_shared='N'] - 'Y' or 'N' on clicking "Use Current Location"
		 * @arg {string} [properties.zip_code=null] - the user's zipcode they entered
		 * @arg {string} [properties.latitude=null] - the latitude of the user if they shared location
		 * @arg {string} [properties.longitude=null] - the longitude of the user if they shared location
		 * @returns {void}
		 */
		trackLocationUpdated(properties) {
			const {
				location_shared = 'N',
				zip_code = null,
				latitude = null,
				longitude = null
			} = properties;

			this.$rudderstack.track('LOCATION_UPDATED', {
				location_shared,
				zip_code,
				latitude,
				longitude
			});
		}

	}
};
