<template>
    <div role="tabpanel">
        <form :action="nextLocation" @submit="setDataLayer" :target="target">
            <ul class="search">
                <li class="search__year">
                    <label class="search__label" for="vehicleYear">
                        {{ $t("message.year") }}
                    </label>
                    <select
                        id="vehicleYear"
                        class="search__select safe-select"
                        name="year"
                        :disabled="!years.length"
                        v-model="year"
                        @change="updateYear"
                        data-cy="vehicleYear"
                    >
                        <option class="search__disabled-option" selected disabled hidden>
                            {{ $t('message.selectYear') }}
                        </option>
                        <option :value="year" v-for="year in years" :key="year">
                            {{ year }}
                        </option>
                    </select>
                </li>

                <li class="search__make-model">
                    <label class="search__label" for="vehicleMake">
                        {{ $t("message.make") }}
                    </label>
                    <select
                        id="vehicleMake"
                        class="search__select safe-select"
                        name="make"
                        :disabled="!makes.length || loading.make"
                        v-model="make"
                        @change="updateMake"
                        data-cy="vehicleMake"
                    >
                        <option v-if="loading.make" :selected="loading.make" value="" disabled>
                            {{ $t('message.loading') }}
                        </option>
                        <option disabled hidden class="search__disabled-option">
                            {{ $t('message.selectMake') }}
                        </option>
                        <option :value="make" v-for="make in makes" :key="make">
                            {{ make }}
                        </option>
                    </select>
                </li>

                <li class="search__make-model">
                    <label class="search__label" for="vehicleModel">
                        {{ $t("message.model") }}
                    </label>
                    <select
                        id="vehicleModel"
                        class="search__select safe-select"
                        name="model"
                        :disabled="!models.length || loading.model"
                        v-model="model"
                        @change="updateModel"
                        data-cy="vehicleModel"
                    >
                        <option v-if="loading.model" :selected="loading.model" value="" disabled>
                            {{ $t('message.loading') }}
                        </option>
                        <option class="search__disabled-option" disabled hidden>
                            {{ $t('message.selectModel') }}
                        </option>
                        <option :value="model" v-for="model in models" :key="model">
                            {{ model }}
                        </option>
                    </select>
                </li>

                <li class="search__trim-opt">
                    <label class="search__label" for="vehicleTrim">
                        {{ $t("message.trim") }}
                    </label>
                    <select
                        id="vehicleTrim"
                        class="search__select safe-select"
                        name="trim"
                        :disabled="!trims.length || loading.trim"
                        v-model="trim"
                        @change="updateTrim"
                        data-cy="vehicleTrim"
                    >
                        <option v-if="loading.trim" :selected="loading.trim" value="" disabled>
                            {{ $t('message.loading') }}
                        </option>
                        <option class="search__disabled-option" disabled hidden>
                            {{ $t('message.selectTrim') }}
                        </option>
                        <option :value="trim" v-for="trim in trims" :key="trim">
                            {{ trim }}
                        </option>
                    </select>
                </li>

                <li class="search__trim-opt">
                    <label class="search__label" for="vehicleOption">
                        {{ $t("message.option") }}
                    </label>
                    <select
                        id="vehicleOption"
                        class="search__select safe-select"
                        name="option"
                        :disabled="!options.length || loading.option"
                        v-model="option"
                        @change="updateOption"
                        data-cy="vehicleOption"
                    >
                        <option v-if="loading.option" :selected="loading.option" value="" disabled>
                            {{ $t('message.loading') }}
                        </option>
                        <option class="search__disabled-option" disabled hidden>
                            {{ $t('message.selectOption') }}
                        </option>
                        <option :value="option" v-for="option in options" :key="option">
                            {{ option }}
                        </option>
                    </select>
                </li>

                <input name="uid" type="hidden" :value="dealerUID" />
                <input name="defaultTransport" type="hidden" :value="defaultTransport" />
                <input v-if="referrer" name="referrer" type="hidden" :value="referrer" />
                <input v-if="analyticsId" name="analyticsId" type="hidden" :value="analyticsId" />
                <input v-if="selectedSize" name="tiresize" type="hidden" :value="selectedSize" />

                <li class="search__button">
                    <stateful-button
                        :disabled="!canSubmit"
                        :isLoading="isLoading"
                        optClass="search__button button button--primary button--full"
                        data-cy="searchButton">
                        {{ $t("message.shopNow") }}
                    </stateful-button>
                </li>
            </ul>
        </form>
    </div>
</template>

<script>
import { extend } from 'underscore';
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex';
import { byVehicleSearchLoaded } from '@/utils/setDataLayer/index';
import StatefulButton from '@/components/StatefulButton.vue';
import { RudderstackSearchMixin } from '@/rudderstack/track/mixins/search';

export default {
	props: {
		query: {
			type: Object,
			required: true
		},
		target: {
			type: String,
			required: true
		}
	},

	mixins: [RudderstackSearchMixin],

	components: {
		StatefulButton
	},

	data() {
		return {
			year: 0,
			make: '',
			model: '',
			trim: '',
			option: '',
			nextLocation: '/app/dealer',
			isLoading: false,
			loading: {
				make: false,
				model: false,
				trim: false,
				option: false
			}
		};
	},

	computed: extend(
		mapState('products/verify', ['needsVerified', 'selectedSize']),
		mapState('products', ['noTires']),
		mapGetters('carline', {
			years: 'sortYears',
			makes: 'sortMakes',
			models: 'sortModels',
			trims: 'sortTrims',
			options: 'optionsMap'
		}), {
			dealerUID() {
				return this.query.uid;
			},
			isNational() {
				return Boolean(!this.query.uid);
			},
			defaultTransport() {
				return this.query.defaultTransport;
			},
			referrer() {
				return this.query.referrer;
			},
			analyticsId() {
				return this.query.analyticsId;
			},
			canSubmit() { // eslint-disable-line complexity
				if (this.query.uid) {
					return Boolean(this.make && this.model && this.year && this.trim && this.finalCheck);
				}

				return Boolean(this.make && this.model && this.year && this.trim);
			},
			finalCheck() {
				return this.selectedSize || this.needsVerified || this.noTires;
			}
		}
	),

	methods: {
		...mapActions('products/verify', ['fetchTireSizes']),
		...mapActions('carline', ['getCarlineCatalogYears', 'getCarlineCatalogMakes', 'getCarlineCatalogModels',
			'getCarlineCatalogTrims', 'getCarlineCatalogOptions']),
		...mapMutations('modal', ['consentToCookies']),
		setState(state) {
			extend(this, state);
		},
		async updateYear() {
			if (!this.year) return;

			this.loading.make = true;
			this.make = '';
			this.model = '';
			this.trim = '';
			this.option = '';
			await this.getCarlineCatalogMakes({ year: this.year });
			this.loading.make = false;

			if (this.makes.length === 1) {
				this.make = this.makes[0];
			}

			this.trackSearchStarted({ 'search_type': 'Vehicle' });
			this.trackSearchOptionClicked({
				'search_term': 'year',
				'search_option_selected': this.year
			});
		},
		async updateMake() {
			if (!this.make) return;

			this.loading.model = true;
			this.model = '';
			this.trim = '';
			this.option = '';
			await this.getCarlineCatalogModels({
				year: this.year,
				make: this.make
			});
			this.loading.model = false;
			this.trackSearchOptionClicked({
				'search_term': 'make',
				'search_option_selected': this.make
			});

		},
		async updateModel() {
			if (!this.model) return;

			this.loading.trim = true;
			this.trim = '';
			this.option = '';
			await this.getCarlineCatalogTrims({
				year: this.year,
				make: this.make,
				model: this.model
			});
			this.loading.trim = false;
			this.trackSearchOptionClicked({
				'search_term': 'model',
				'search_option_selected': this.model
			});
		},
		async updateTrim() {
			if (!this.trim) return;

			this.loading.option = true;
			this.option = '';
			await this.getCarlineCatalogOptions({
				year: this.year,
				make: this.make,
				model: this.model,
				trim: this.trim
			});
			this.loading.option = false;

			if (this.options.length === 1) {
				this.option = this.options[0];
			}

			this.trackSearchOptionClicked({
				'search_term': 'trim',
				'search_option_selected': this.trim
			});
		},
		updateOption() {
			if (!this.option) return;

			this.trackSearchOptionClicked({
				'search_term': 'option',
				'search_option_selected': this.option
			});

			// If we have a uid in the query string this is a 99.9% chance that the
			// User is coming from a dealer, so we will be skipping the dealer page
			if (this.query.uid) {
				// Call our store action in order to get some tire sizes
				// This will confirm if we need to go to our verify page or not
				this.fetchTireSizes({
					productVehicleYear: this.year,
					productVehicleModel: this.model,
					productVehicleTrim: this.trim,
					productVehicleOption: this.option,
					productVehicleMake: this.make,
					uid: this.query.uid
				}).then(() => {
					// If we have multiple sizes come back then we need to use the
					// Verify page in order to confirm which size we are using
					if (this.needsVerified) {
						this.nextLocation = '/app/verify';
					} else if (this.noTires) {
						this.nextLocation = '/app/no-products';
					} else {
						// Otherwise go ahead and go to the product page
						// Luckily if there is only 1 tire size it will be set
						// By our above action when it's called
						this.nextLocation = '/app/product';
					}
				});
			}
		},
		setDataLayer() {
			const tireDetails = {
				year: this.year,
				make: this.make,
				model: this.model,
				trim: this.trim,
				option: this.option
			};

			this.isLoading = true;

			this.consentToCookies(true);

			this.trackSearchCompleted({
				'search_type': 'Vehicle',
				...tireDetails
			});

			byVehicleSearchLoaded(tireDetails);
		}
	},

	created() {
		this.getCarlineCatalogYears();
	}
};
</script>
