<template>
    <div class="col-12">
        <div class="row">
            <!-- Sorting -->
            <div :class="['col-6 col-md-5 sort sort__dropdown', {'sort--fade': fadeSidebar } ]">
                <label class="sort__label" for="sortDropdown">{{ $t("message.sortBy") }}:</label>
                <select
                    class="sort__select safe-select"
                    :disabled="fadeSidebar"
                    @change="runSortBy"
                    id="sortDropdown"
                    v-model="sortByComputed"
                >
                    <option v-if="showRecOption" value="recommended">{{ $t("message.recommended") }}</option>
                    <option value="default">{{ $t("message.recommended") }}</option>
                    <option value="lowToHigh">{{ $t("message.lowToHigh") }}</option>
                    <option value="highToLow">{{ $t("message.highToLow") }}</option>
                    <option value="aToZ">{{ $t("message.brand") }} {{ $t("message.aToZ") }}</option>
                    <option value="zToA">{{ $t("message.brand") }} {{ $t("message.zToA") }}</option>
                    <option value="treadwearWarranty">{{ $t("message.treadwearWarranty") }}</option>
                </select>
            </div>
            <!-- Filtering -->
            <div :class="['col-6 col-md-5 sort sort__dropdown', {'sort--fade': fadeSidebar } ]">
                <label class="sort__label" for="filterDropdown">{{ $t("message.show") }}:</label>
                <select
                    class="sort__select safe-select"
                    :disabled="fadeSidebar"
                    id="filterDropdown"
                    v-model="filterToShowComputed"
                >
                    <option v-if="showRecOption" value="recommended">{{ $t("message.recommended") }}</option>
                    <option
                        v-for="(filterDescription, key) in showFilterOptions"
                        :key="key"
                        :value="key"
                    >{{ $t(filterDescription) }}</option>
                </select>
            </div>
        </div>
        <div class="row mobile-install-wrapper" v-if="isMobileInstallAvailable">
            <div class="mobile-install-filter">
                <input type="checkbox" id="toggle" v-model="isMobileInstallFilterComputed" />
                <label for="toggle"></label>
                <span>{{ $t("message.mobileInstallEligible") }}</span>
                <img
                    class="icon"
                    src="/dist/assets/svgs/mobile-install-icon-blue.svg"
                    alt="Mobile Install Icon"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import { extend } from 'underscore';
import * as filtering from '@/utils/filtering';
import { structuredProductQuery } from '@/utils/product-query-builder';
import { showChoice, sortByChoice, filterMobileInstallToggle } from '@/utils/setDataLayer';

export default {
	props: {
		fadeSidebar: {
			type: Boolean,
			required: true
		},
		query: {
			type: Object,
			required: true
		}
	},
	computed: {
		...mapState('products', ['allProducts', 'noTires', 'qtySelected']),
		...mapState('productResults', ['filteredProducts', 'filterToShow', 'recigneProducts', 'showRecOption', 'sortBy', 'isMobileInstallFilter']),
		...mapState('dealer', ['selected']),
		...mapGetters('initial', ['getPowerReviews']),

		filterToShowComputed: {
			get() {
				return this.filterToShow;
			},
			set(filter) {
				this.setFilterToShow(filter);
			}
		},
		sortByComputed: {
			get() {
				return this.sortBy;
			},
			set(sortType) {
				this.setSortBy(sortType);
			}
		},
		isMobileInstallAvailable() {
			// eslint-disable-next-line no-undefined
			return this.selected.transports && this.selected.transports.find(transport => transport.id === 14) !== undefined;
		},
		isMobileInstallFilterComputed: {
			get() {
				return this.isMobileInstallFilter;
			},
			set(filterValue) {
				this.setMobileInstallFilter(filterValue);
			}
		},
		showFilterOptions() {
			// Default filter is to show all tire results
			const fieldsToShow = {
				all: 'message.all'
			};
			// All other filters that could be applicable to the tire results
			const fieldsToCheck = {
				promos: 'message.specialOffers',
				oem: 'message.originalEquipment',
				allSeason: 'message.allSeasonTires',
				winter: 'message.winterTires',
				summer: 'message.summerTires',
				fuelEfficient: 'message.fuelEfficientTires'
			};

			// Only render filter options that have results for that filter
			for (const key in fieldsToCheck) {
				if (filtering[key](this.recigneProducts).length) {
					extend(fieldsToShow, {
						[key]: fieldsToCheck[key]
					});
				}
			}

			return fieldsToShow;
		}
	},
	watch: {
		filterToShow(newVal) {
			this.runShowFilter();
			showChoice(newVal);
		},
		sortBy(newVal) {
			sortByChoice(newVal);
		},
		qtySelected() {
			this.refreshAllData();
		},
		'$store.state.consumerInfo.userLanguage': function() {
			this.refreshAllData();
		},
		isMobileInstallFilter(newValue) {
			this.runShowFilter();
			if (newValue === true) {
				// Data layer event for Mobile Install
				filterMobileInstallToggle(window.location.href);
			}
		}
	},
	methods: {
		...mapActions('products', ['getAllProducts']),
		...mapActions('productResults', ['pushDataLayer', 'runRecigne', 'runShowFilter', 'runSortBy', 'setAllProductReviews']),
		...mapMutations('productResults', ['setFilteredProducts', 'setFilterToShow', 'setRecigneProducts', 'setSortBy', 'setMobileInstallFilter']),
		async refreshAllData() {
			this.setFilteredProducts([]);
			await this.getAllProducts(structuredProductQuery(this.query))
				.then(() => {
					if (this.noTires) {
						this.$router.push({
							path: '/app/no-products',
							query: this.query
						});
					}
					this.setFilteredProducts(this.allProducts);
					this.setRecigneProducts(this.allProducts);
					this.runShowFilter();
				}).then(() => {
					const allProductIds = this.filteredProducts.map(prod => {
						return prod.products[0].modelID;
					}).join(',');

					if (this.getPowerReviews.enabled) {
						this.setAllProductReviews({productIDs: allProductIds});
					}
				})
				.catch(err => {
					throw new Error(err);
				});
		}
	},

	async created() {
		await this.refreshAllData();
	}
};
</script>
