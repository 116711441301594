const store = {
	namespaced: true,
	state: {
		hasConsentedToCookies: false,
		ivf: false,
		noProducts: false,
		oos: false,
		inventoryUnavailable: false,
		showFslModal: false
	},
	mutations: {
		toggleIVFModal(state) {
			state.ivf = !state.ivf;
		},
		toggleNoProdsModal(state) {
			state.noProducts = !state.noProducts;
		},
		toggleInventoryUnavailableModal(state, payload) {
			if (typeof payload === 'boolean') {
				state.inventoryUnavailable = payload;
			} else {
				state.inventoryUnavailable = !state.inventoryUnavailable;
			}
		},
		toggleOOSModal(state, payload) {
			if (typeof payload === 'boolean') {
				// this allows for sending in a payload to give more control
				state.oos = payload;
			} else {
				// if no payload is provided - toggle the existing payload
				state.oos = !state.oos;
			}
		},
		toggleFslModal(state, payload) {
			if (typeof payload === 'boolean') {
				// this allows for sending in a payload to give more control
				state.showFslModal = payload;
			} else {
				// if no payload is provided - toggle the existing payload
				state.showFslModal = !state.showFslModal;
			}
		},
		consentToCookies(state, payload) {
			state.hasConsentedToCookies = payload;
			localStorage.setItem('cookies', JSON.stringify(payload));
		}
	}
};

export default store;
