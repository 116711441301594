<template>
    <svg :width="width" :height="height" viewBox="0 0 24 24" fill="none">
        <path
            stroke-linecap="round"
            stroke-miterlimit="10"
            stroke-width="1"
            stroke="#221b38"
            fill="none"
            d="M20 13C19.17 12.37 18.13 12 17 12C14.24 12 12 14.24 12 17C12 18.13 12.37 19.17 13 20H4C2.9 20 2 19.1 2 18V9H20V13Z"></path>
        <path stroke-linecap="round" stroke-miterlimit="10" stroke-width="1" stroke="#221b38" fill="none" d="M18 4H4C2.9 4 2 4.9 2 6V9H20V6C20 4.9 19.1 4 18 4Z"></path>
        <path stroke-linejoin="round" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1" stroke="#221b38" d="M15 2V6"></path>
        <path stroke-linejoin="round" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1" stroke="#221b38" d="M7 2V6"></path>
        <path stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1" stroke="#221b38" d="M4 13H6"></path>
        <path stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1" stroke="#221b38" d="M8 13H10"></path>
        <path stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1" stroke="#221b38" d="M12 13H14"></path>
        <path stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1" stroke="#221b38" d="M4 17H6"></path>
        <path stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1" stroke="#221b38" d="M8 17H10"></path>
        <path
            stroke-linejoin="round"
            stroke-linecap="round"
            stroke-miterlimit="10"
            stroke-width="1"
            stroke="#221b38"
            fill="none"
            d="M17 22C19.7614 22 22 19.7614 22 17C22 14.2386 19.7614 12 17 12C14.2386 12 12 14.2386 12 17C12 19.7614 14.2386 22 17 22Z"></path>
        <path stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1" stroke="#221b38" d="M17 14V17"></path>
        <path stroke-linejoin="round" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1" stroke="#221b38" d="M17 19H17.01"></path>
    </svg>
</template>

<script>
export default {
	name: 'IconCalendarWarning',

	props: {
		width: {
			type: String,
			default: '24'
		},
		height: {
			type: String,
			default: '24'
		},
		stroke: {
			type: String,
			default: '#221b38'
		}
	}
};
</script>

