const { groupBy, keys, last } = require('underscore');

/**
 * Sort the keys using standard sorting rules
 * @param  {string} compareA the first value to compare
 * @param  {string} compareB the second value to compare
 * @return {Number} follows standard sort rules
 */
const sortKeys = (compareA, compareB) => {
	if (compareA.toLowerCase() < compareB.toLowerCase()) {
		return -1;
	}
	if (compareA.toLowerCase() > compareB.toLowerCase()) {
		return 1;
	}

	return 0;
};

/**
 * Given the keys from the underscore group that was done, return the keys sorted
 * @param  {Array} prods an array of the keys gotten from the group
 * @param  {boolean} ascending whether or not to reverse sort
 * @return {array} the keys sorted
 */
const getSortedKeys = (prods, ascending) => {
	const productKeys = keys(prods).sort( (a, b) => {
		// Because all values coming through are a string (object keys), further investigation needs done
		// Each value is "converted" to a number and determines if a number (isNumber says 'NaN' is a number so no)
		// Otherwise, just go through as normal. This level was done because JS doesn't know how to compare string numbers...
		if ( !isNaN(a) ) {

			return Number(a) - Number(b);
		}

		return sortKeys(a, b);
	});

	return ascending ? productKeys : productKeys.reverse();
};

/**
 * Given products and rules, break out the group to look for and work it
 * @param  {array} prods the products that will be grouped
 * @param  {object} rules the rules needed for the grouping
 * @return {Object} the products and the sorted key to use in the next sorting
 */
const getProductGroup = (prods, rules) => {
	let products = [];

	if (rules.attrMap.toLowerCase() === 'products') {
		// All attributes from Products should come from the rear tire following the Sir Mix-A-Lot Rule
		products = groupBy(prods, (product) => last(product.products)[rules.key]);
	} else if (rules.attrMap.toLowerCase() === 'pricing') {
		products = groupBy(prods, (product) => product.pricing[rules.key]);
	} else {
		// This attribute is at the base of the 'product' object so would die if trying to step through the object with ''
		products = groupBy(prods, (product) => product[rules.key]);
	}

	return {
		products,
		sortedKeys: getSortedKeys(products, rules.ascending)
	};
};

/**
 * Determine if at the end of the rules chain
 * @param  {array} group the group of products
 * @param  {array} tieBreakers what's left in the list of criteria
 * @return {boolean} all done or not
 */
const isFinalValue = (group, tieBreakers) => group.length === 1 || tieBreakers.length === 0;

/**
 * Taking the products and rules for the sort, return the sorted products
 * @param  {array} prods the products
 * @param  {array} rules the current rule and all subsequent rules
 * @return {array} the products sorted based on the criteria passed in
 */
const sortHelper = (prods, [rules, ...tieBreakers]) => {
	let sortedProducts = [];

	// Check to see if we are at the final tiebreak to get us to returning the products we need
	const {products, sortedKeys} = getProductGroup(prods, rules);

	// Here's some recursion, boys and girls. Each sort has a hierarchy to follow and must be stepped
	// Through to get there. The keys will be ran through and determine if at the end of the line
	// Or if sortHelper needs called again to keep stepping further
	sortedKeys.forEach(group => {
		if (rules.noFurtherFilter && group !== 'undefined') {
			sortedProducts = sortedProducts.concat(products[group]);
		} else if (isFinalValue(products[group], tieBreakers)) {
			sortedProducts = sortedProducts.concat(products[group]);
		} else {
			sortedProducts = sortedProducts.concat(sortHelper(products[group], tieBreakers));
		}
	});

	return sortedProducts;
};

// The Rules for each type of sort that can be had are below

const warranty = prods => {
	return sortHelper(prods, [{
		key: 'warrantyMilesNumeric',
		ascending: false,
		attrMap: 'products'
	},
	{
		key: 'installPriceAfterSavings',
		ascending: true,
		attrMap: 'pricing'
	},
	{
		key: 'brand',
		ascending: true,
		attrMap: 'products'
	}]);
};

const recommended = prods => {
	return sortHelper(prods, [{
		key: 'recommended',
		ascending: true,
		attrMap: '',
		noFurtherFilter: true
	},
	{
		key: 'factoryInstalled',
		ascending: false,
		attrMap: 'products'
	},
	{
		key: 'winter',
		ascending: true,
		attrMap: 'products'
	},
	{
		key: 'installPriceAfterSavings',
		ascending: true,
		attrMap: 'pricing'
	},
	{
		key: 'brand',
		ascending: true,
		attrMap: 'products'
	}]);
};

const oemSeasonPriceBrand = prods => {

	return sortHelper(prods, [{
		key: 'factoryInstalled',
		ascending: false,
		attrMap: 'products'
	},
	{
		key: 'winter',
		ascending: true,
		attrMap: 'products'
	},
	{
		key: 'installPriceAfterSavings',
		ascending: true,
		attrMap: 'pricing'
	},
	{
		key: 'brand',
		ascending: true,
		attrMap: 'products'
	}]);
};

const price = (prods, reverse) => {
	return sortHelper(prods, [{
		key: 'installPriceAfterSavings',
		ascending: !reverse,
		attrMap: 'pricing'
	},
	{
		key: 'brand',
		ascending: true,
		attrMap: 'products'
	}]);
};

const brand = (prods, reverse) => {
	return sortHelper(prods, [{
		key: 'brand',
		ascending: !reverse,
		attrMap: 'products'
	},
	{
		key: 'priceAfterDiscounts',
		ascending: true,
		attrMap: 'pricing'
	}]);
};

module.exports = {
	warranty,
	price,
	oemSeasonPriceBrand,
	brand,
	recommended
};
