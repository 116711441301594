import { difference, filter } from 'underscore';
import * as sorting from '@/utils/sorting';

// Finds all tires of a specified preferred brand and sorts them based on default sort order
const groupAndSortByBrand = (brandToGroupBy, allPreferredBrandTires) => {
	const tireArrayByBrand = filter(allPreferredBrandTires, (productSet) => {
		return productSet.products[0].brand.toLowerCase() === brandToGroupBy.toLowerCase();
	});

	return sorting['oemSeasonPriceBrand'](tireArrayByBrand, false);
};

// Sets up an array of brand arrays that we'll eventually loop through to get the "best" preferred tire from each
const createArrayOfBrandArrays = (allPreferredBrandTires) => {
	const michelinTiresSorted = groupAndSortByBrand('michelin', allPreferredBrandTires);
	const dunlopTiresSorted = groupAndSortByBrand('dunlop', allPreferredBrandTires);
	const generalTiresSorted = groupAndSortByBrand('general', allPreferredBrandTires);

	return [generalTiresSorted, dunlopTiresSorted, michelinTiresSorted];
};

// Returns an array with the top ranked tire of each preferred brand
const findBestPreferredTires = (allPreferredBrandTires) => {
	const arrayOfBrandArrays = createArrayOfBrandArrays(allPreferredBrandTires);
	let bestPreferredTires = [];

	arrayOfBrandArrays.forEach((brandArray) => {
		if (brandArray.length) {
			bestPreferredTires.push(brandArray[0]);
		}
	});

	return bestPreferredTires;
};

// Returns an array with "best" preferred tires first, then the rest of the results afterwards
const allResultsSorted = (allResults, allPreferredBrandTires) => {
	const bestPreferredTires = findBestPreferredTires(allPreferredBrandTires);
	const resultsWithoutBestTires = difference(allResults, bestPreferredTires);

	return bestPreferredTires.concat(resultsWithoutBestTires);
};

// Main function of this module
const resultsSortedByPreferredBrand = (allResults) => {
	const allPreferredBrandTires = allResults.filter(({ products: singleProduct }) => {
		// this is for the SET sorting (if true, it means that this tire is one of the following brands)
		// AND that the program is SET (this is set in the pqr web service)
		// preferredBrands: ['michelin', 'dunlop', 'general'];

		return singleProduct[0].isPreferredBrand;
	});

	if (allPreferredBrandTires.length) {

		return allResultsSorted(allResults, allPreferredBrandTires);
	}

	return allResults;
};

export { resultsSortedByPreferredBrand };
