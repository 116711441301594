import axios from 'axios';
import path from '@/utils/path';

const articleContents = [
	'contentDetailsResponse',
	'articles',
	'article',
	'articleContents',
	'articleContent'
];

const contentBodyPath = path(
	articleContents.concat([
		'contentBody'
	])
);

const linkSourcePath = path(
	articleContents.concat([
		'articleLinks',
		'articleLink',
		'linkSource'
	])
);

const articleTitlePath = path(
	articleContents.concat([
		'title'
	])
);

export const setProductOverview = ({ commit }, product) => axios.get('/service/docs/', {
	params: {
		articleType: 'Product Overview',
		path: '/app/product/detail/',
		productSKU: product.sku,
		productID: product.productId,
		productModelID: product.modelID,
		productBrandCode: product.brandCode
	}
}).then(({ data }) => {
	commit('productOverview', contentBodyPath(data));
});

export const roadHazard = ({ commit }, opts) => axios.get('/service/docs/', {
	params: {
		articleType: 'Road Hazard',
		path: '/app/product/detail/',
		productBrandCode: opts.brandCode,
		productSKU: opts.sku,
		vehicleModel: opts.vehicleModel,
		vehicleMake: opts.vehicleMake,
		productID: opts.productID,
		productModelID: opts.productModelID
	}
}).then(({ data }) => {
	commit('roadHazard', contentBodyPath(data));
});

// Problem here: some calls return multiple articles
// articleContents above assumes a single article
export const setWarrantyDoc = ({ commit }, product) => axios.get('/service/docs/', {
	params: {
		brandCode: product.brandCode,
		productID: product.productId,
		productModelID: product.modelID
	}
}).then(({ data }) => {
	commit('warrantyDoc', linkSourcePath(data));
	commit('warrantyDocTitle', articleTitlePath(data));
}).catch(() => {
	commit('warrantyDoc', '');
	commit('warrantyDocTitle', '');
});
