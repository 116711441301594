import VueRouter from 'vue-router';
import Vue from 'vue';

// Pages
import Appointment from '@/page/Appointment.vue';
import Compare from '@/page/Compare.vue';
import ConsumerInfo from '@/page/ConsumerInfo.vue';
import Dealer from '@/page/Dealer.vue';
import ProductDetail from '@/page/ProductDetail.vue';
import Home from '@/page/Home.vue';
import NoDealer from '@/page/NoDealer.vue';
import NoDealerFormAndContact from '@/page/NoDealerFormAndContact.vue';
import NoDealerFailure from '@/page/NoDealerFailure.vue';
import NoDealerSuccess from '@/page/NoDealerSuccess.vue';
import NoProducts from '@/page/NoProducts.vue';
import NoProductsFormAndContact from '@/page/NoProductsFormAndContact.vue';
import NoProductsFailure from '@/page/NoProductsFailure.vue';
import NoProductsSuccess from '@/page/NoProductsSuccess.vue';
import Payment from '@/page/Payment.vue';
import Product from '@/page/Product.vue';
import Receipt from '@/page/Receipt.vue';
import Verify from '@/page/Verify.vue';
import LeavingSite from '@/page/LeavingSite';
import FSLConfirmation from '@/page/FSLConfirmation';
import Error500 from '@/page/Error500.vue';

Vue.use(VueRouter);

const routes = [
	{
		path: '/app',
		component: Home,
		props: (route) => ({ query: route.query }),
		meta: {
			category: 'Home',
			name: 'Home Page'
		}
	},
	{
		path: '/app/dealer',
		component: Dealer,
		props: (route) => ({ query: route.query }),
		meta: {
			category: 'Dealer',
			name: 'Dealer Page'
		}
	},
	{
		path: '/app/product',
		component: Product,
		props: (route) => ({ query: route.query }),
		meta: {
			category: 'Product',
			name: 'Product Page'
		}
	},
	{
		path: '/app/details',
		component: ProductDetail,
		props: (route) => ({ query: route.query }),
		meta: {
			category: 'Product Details',
			name: 'Product Details Page'
		}
	},
	{
		path: '/app/schedule',
		component: Appointment,
		props: (route) => ({ query: route.query }),
		meta: {
			category: 'Appointment',
			name: 'Appointment Page'
		}
	},
	{
		path: '/app/payment',
		component: Payment,
		props: (route) => ({query: route.query}),
		meta: {
			category: 'Payment',
			name: 'Payment Page'
		}
	},
	{
		path: '/app/leaving-site',
		component: LeavingSite,
		props: (route) => ({ query: route.query }),
		meta: {
			category: 'Leaving Site',
			name: 'Leaving Site Page'
		}
	},
	{
		path: '/app/receipt',
		component: Receipt,
		props: (route) => ({ query: route.query }),
		meta: {
			category: 'Receipt',
			name: 'Receipt Page'
		}
	},
	{
		path: '/app/verify',
		component: Verify,
		props: (route) => ({ query: route.query }),
		meta: {
			category: 'Verify',
			name: 'Verify Page'
		}
	},
	{
		path: '/app/compare',
		component: Compare,
		props: (route) => ({ query: route.query }),
		meta: {
			category: 'Compare',
			name: 'Compare Page'
		}
	},
	{
		path: '/app/consumer-info',
		component: ConsumerInfo,
		props: (route) => ({ query: route.query }),
		meta: {
			category: 'Consumer Info',
			name: 'Consumer Info Page'
		}
	},
	{
		path: '/app/no-dealer',
		component: NoDealer,
		props: (route) => ({ query: route.query }),
		meta: {
			category: 'No Dealer',
			name: 'No Dealer Page'
		},
		children: [
			{
				path: '',
				component: NoDealerFormAndContact,
				name: 'noDealerForm',
				meta: {
					category: 'No Dealer',
					name: 'No Dealer Form Page'
				},
				props: (route) => ({ query: route.query })
			},
			{
				path: 'success',
				name: 'noDealerSuccess',
				component: NoDealerSuccess,
				meta: {
					category: 'No Dealer',
					name: 'No Dealer Success Page'
				},
				props: (route) => ({ query: route.query })
			},
			{
				path: 'fail',
				name: 'NoDealerFailure',
				component: NoDealerFailure,
				meta: {
					category: 'No Dealer',
					name: 'No Dealer Failure Page'
				},
				props: (route) => ({ query: route.query })
			}
		]
	},
	{
		path: '/app/no-products',
		component: NoProducts,
		props: (route) => ({ query: route.query }),
		meta: {
			category: 'No Products',
			name: 'No Products Page'
		},
		children: [
			{
				path: '',
				component: NoProductsFormAndContact,
				name: 'noProdsForm',
				meta: {
					category: 'No Products',
					name: 'No Products Form Page'
				},
				props: (route) => ({ query: route.query })
			},
			{
				path: 'success',
				name: 'noProdsSuccess',
				component: NoProductsSuccess,
				meta: {
					category: 'No Products',
					name: 'No Products Success Page'
				},
				props: (route) => ({ query: route.query })
			},
			{
				path: 'fail',
				name: 'noProdsFail',
				component: NoProductsFailure,
				meta: {
					category: 'No Products',
					name: 'No Products Failure Page'
				},
				props: (route) => ({ query: route.query })
			}
		]
	},
	{
		path: '/app/fsl-confirm',
		component: FSLConfirmation,
		props: (route) => ({ query: route.query })
	},
	{
		path: '/error/500',
		component: Error500,
		props: (route) => ({ query: route.query }),
		meta: {
			category: 'Error',
			name: 'Error 500 Page'
		}
	},
	{
		path: '*',
		component: Home,
		props: (route) => ({ query: route.query }),
		meta: {
			category: 'Home Wildcard Route',
			name: 'Home Page'
		}
	}
];

const router = new VueRouter({
	mode: 'history',
	routes,
	scrollBehavior () {
		return {
			x: 0,
			y: 0
		};
	}
});

export default router;
