/* eslint-disable complexity */

import {
	calculateServicesCost,
	generateGlobalProductProperties,
	generateGlobalProductPricing
} from '../helpers';

export const rudderstackScheduleMixin = {
	methods: {

		/**
		 * when a user selects an additional service
		 *
		 * @param {object} services { services_selected }
		 * @returns {void}
		 */
		trackServicesSelected(services) {
			let dealerObj = this.selected;
			let dealerCurrency = ((dealerObj.country === 'us') ? 'USD' : 'CAD' );

			this.$rudderstack.track('SERVICES_SELECTED', {
				'products': generateGlobalProductProperties(this.tiresInCart.products),
				'product_pricing': generateGlobalProductPricing(this.tiresInCart.products),
				'currency': dealerCurrency,
				'service_added': ( (services.length > 0) ? 'Y' : 'N' ),
				'services': services,
				'services_cost': calculateServicesCost(services)
			});
		},

		/**
		* When a appointment type is selected
		*
		* @param {object} apptType { name: string, id: number }
		* @returns {void}
		*/
		trackAppointmentTypeRequested(apptType) {
			let appointment = this.selectedAppointment;
			let services = appointment.services;

			this.$rudderstack.track('APPOINTMENT_TYPE_REQUESTED', {
				'products': generateGlobalProductProperties(this.tiresInCart.products),
				'product_pricing': generateGlobalProductPricing(this.tiresInCart.products),
				'appointment_type': (( typeof apptType.name !== 'undefined') ? apptType.name : ''),
				'services_cost': calculateServicesCost(services)
			});
		},

		/**
		 * When the appointment date is selected.
		 *
		 * @param {object} appointmentDate - An object containing the appointment date
		 * @return {void}
		 */
		trackAppointmentDateRequested(appointmentDate) {
			let appointment = this.selectedAppointment;
			let services = appointment.services;

			this.$rudderstack.track('APPOINTMENT_DATE_REQUESTED', {
				'products': generateGlobalProductProperties(this.tiresInCart.products),
				'product_pricing': generateGlobalProductPricing(this.tiresInCart.products),
				'appointment_type': (appointment.type.name || ''),
				'appointment_date': appointmentDate,
				'services_cost': calculateServicesCost(services)
			});
		},

		/**
		 * when the appointment time is selected.
		 *
		 * @param {object} appointmentTime - An object containing the appointment time
		 * @return {void}
		 */
		trackAppointmentTimeRequested(appointmentTime) {
			let appointment = this.selectedAppointment;
			let services = appointment.services;

			this.$rudderstack.track('APPOINTMENT_TIME_REQUESTED', {
				'products': generateGlobalProductProperties(this.tiresInCart.products),
				'product_pricing': generateGlobalProductPricing(this.tiresInCart.products),
				'appointment_type': (appointment.type.name || ''),
				'appointment_date': (appointment.time.appointmentDate || ''),
				'appointment_time': appointmentTime,
				'services_cost': calculateServicesCost(services)
			});
		}
	}
};
