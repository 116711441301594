<template>
    <div class="row">
        <div class="col-md-12">
            <article class="product-item">
                <div class="wpk-tile">
                    <div class="row">

                        <modal-tire-image
                            @onClose="toggleWpkImageModal"
                            :isOpen="showWpkImageModal"
                            :productSet="wheelPackage"
                            :isWpkModal="true"
                        />

                        <div class="col-md-7">
                            <div class="wpk-tile__wrapper">
                                <header>
                                    <h5 class="wpk-tile__tire-brand">{{productName}}</h5>
                                </header>
                                <div class="wpk-tile__img-info-wrapper">
                                    <!-- Images -->
                                    <button class="wpk-tile__img-button" v-if="showImageButton" @click.prevent="toggleWpkImageModal">
                                        <figure class="wpk-tile__tire-img-wrapper wpk-tile__tire-img-wrapper--front">
                                            <img class="wpk-tile__tire-img wpk-tile__tire-img--front"
                                                 :src="images.front" alt="front view of tire" @error="hideImageButton">
                                        </figure>
                                        <figure class="wpk-tile__tire-img-wrapper wpk-tile__tire-img-wrapper--side">
                                            <img class="wpk-tile__tire-img wpk-tile__tire-img--side"
                                                 :src="images.side" alt="side view of tire" @error="hideImageButton">
                                        </figure>
                                    </button>

                                    <!-- Tire Info -->
                                    <div class="wpk-tile__info-wrapper">
                                        <!-- Localbestseller & OEM -->
                                        <ul v-if="hasFlags" class="wpk-tile__specs">
                                            <li v-if="wheelPackage.localBestSeller"
                                                class="tile__local-best-seller">
                                                {{$t("message.localBestSeller")}}
                                            </li>
                                            <li v-if="wheelPackage.factoryInstalled"
                                                class="tile__original-equipment">
                                                {{$t("message.originalEquipment")}}
                                            </li>
                                            <li v-if="wheelPackage.oemTireMarkDescription"
                                                class="tile__oem-tiremark">
                                                {{wheelPackage.oemTireMarkDescription}}
                                            </li>
                                        </ul>

                                        <!-- Specs -->
                                        <div class="wpk-tile__specs">
                                            <ul class="wpk-tile__specs-list">
                                                <li v-for="spec in specs"
                                                    v-if="spec"
                                                    :key="spec"
                                                    class="wpk-tile__specs-item"
                                                >
                                                    {{spec}}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- CMS Content -->
                        <div v-html="content" class="col-md-5"></div>
                    </div>
                </div>
            </article>
        </div>
    </div>
</template>

<script>
import prettyLgNum from '@/filters/pretty-lg-numbers/index.js';
import ModalTireImage from '@/components/ModalTireImage.vue';

export default {
	props: {
		content: {
			type: String,
			required: true
		},
		wheelPackage: {
			type: Object,
			required: true
		}
	},

	components: {
		ModalTireImage
	},

	data() {
		return {
			showWpkImageModal: false,
			showImageButton: true,
			specs: []
		};
	},

	methods: {
		toggleWpkImageModal() {
			this.showWpkImageModal = !this.showWpkImageModal;
		},
		hideImageButton() {
			this.showImageButton = false;
		}
	},

	computed: {
		images() {
			if (this.wheelPackage.imageId) {
				const imageLastChar = this.wheelPackage.imageId.charAt(this.wheelPackage.imageId.length - 1);
				const imageID = imageLastChar.search(/l|r/i) === -1 ?
					this.wheelPackage.imageId : this.wheelPackage.imageId.slice(0, -1);

				return {
					front: `/content/drsg/c_front/${imageID}_c.jpg`,
					side: `/content/drsg/a_side/${imageID}_a.jpg`
				};
			}

			return {
				front: '/content/drsg/c_front/default.jpg',
				side: '/content/drsg/a_side/default.jpg'
			};
		},
		productName() {
			return this.wheelPackage.overview.split('utilizing')[1];
		},
		hasFlags() {
			const { localBestSeller, originalEquipment, oemTireMarkDescription } = this.wheelPackage;

			return localBestSeller || originalEquipment || oemTireMarkDescription;
		},
		hasWarrantyMsg() {
			return (Number(this.wheelPackage.warrantyMilesNumeric)) ?
				`${prettyLgNum(this.wheelPackage.warrantyMilesNumeric)} Mile Warranty` :
				'';
		},
		isRunFlat() {
			return this.wheelPackage.isRunflat ? this.$t('message.runFlat') : '';
		},
		showRoadHazard() {
			return this.wheelPackage.showRoadHazard ? this.$t('message.roadHazard') : '';
		}
	},

	created() {
		this.specs = [
			this.wheelPackage.season,
			this.isRunFlat,
			this.hasWarrantyMsg,
			this.showRoadHazard,
			`${this.wheelPackage.size} | ${this.wheelPackage.serviceDescription}`
		];
	}
};

</script>
