export const RudderstackSearchMixin = {
	methods: {

		/**
		 * Track very first engagement with the search widget.
		 * "By Vehcile" - triggers on year drop down selection
		 * "By Size" - When the "by size" tab is clicked
		 *
		 * @param {object} properties { search_type }
		 * @arg {string} properties.search_type - 'Size' or 'Vehicle'
		 * @returns {void}
		 */
		trackSearchStarted(properties) {
			const { search_type } = properties;

			this.$rudderstack.track('SEARCH_STARTED', { search_type });
		},

		/**
		* When a user interacts with the search widget dropdown options.
		* Triggers when the user makes a selection
		*
		* @param {object} properties { search_term: string, option: string|number }
		* @arg {string} properties.search_term - Identifies which dropdown the user is interacting with
		* @arg {string|number} properties.search_term_selected - What option was selected in the dropdown
		* @returns {void}
		*/
		trackSearchOptionClicked(properties) {
			this.$rudderstack.track('SEARCH_OPTION_CLICKED', properties);
		},

		/**
		 * When the "Shop Now" search button is submitted.
		 *
		 * @param {object} properties - An object containing the search properties
		 * @arg {string} properties.search_type - 'Size' or 'Vehicle'
		 * @arg {string} properties.make - car,
		 * @arg {number} [properties.year=null] - car
		 * @arg {string} [properties.model=null] - car
		 * @arg {string} [properties.trim=null] - car
		 * @arg {string} [properties.option=null] - car
		 * @arg {number} [properties.width=null] - tire
		 * @arg {number} [properties.ratio=null] - tire
		 * @arg {number} [properties.diameter=null] - tire
		 * @return {void}
		 */
		trackSearchCompleted(properties) {
			const {
				search_type,
				make,
				year = null,
				model = null,
				trim = null,
				option = null,
				width = null,
				ratio = null,
				diameter = null
			} = properties;

			this.$rudderstack.track('SEARCH_COMPLETED', {
				search_type,
				make,
				year: Number(year),
				model,
				trim,
				option,
				width: Number(width),
				ratio: Number(ratio),
				diameter: Number(diameter)
			});
		}
	}
};
