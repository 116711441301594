<template>
    <div class="compare-table__swap">
        <label v-show="uncomparedTires.length" class="compare-table__swap-label">
            {{$t("message.addToCompare")}}

            <select class="compare-table__swap-select" v-model="swappedTire"
                    @change="addSwappedTire(swappedTire)">
                <option class="compare-table__swap-option" selected disabled hidden>
                    {{$t("message.selectTire")}}
                </option>
                <option v-for="(uncomparedTire, uncomparedTireIndex) in uncomparedTires"
                        class="compare-table__swap-option"
                        :key="'tireToSwap' + uncomparedTireIndex"
                        :value="uncomparedTire">
                    {{ uncomparedTire.products[0].brand }}
                    {{ uncomparedTire.products[0].catalogEntryDescription }}
                </option>
            </select>
        </label>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { isEmpty, filter, contains, map } from 'underscore';

export default {
	data() {
		return {
			swappedTire: {}
		};
	},
	computed: {
		...mapState('products', [
			'allProducts',
			'productSetsToCompare'
		]),
		excludedIds() {
			return map(this.productSetsToCompare, function(productSet) {
				return productSet.products[0].productId;
			});
		},
		uncomparedTires() {
			let excludedIds = this.excludedIds;

			return filter(this.allProducts, function(oneProduct) {
				return !contains(excludedIds, oneProduct.products[0].productId);
			});
		}
	},
	methods: {
		...mapActions('products', [
			'addProductSetToCompare'
		]),
		addSwappedTire(tireToSwap) {
			if (isEmpty(this.swappedTire)) {
				return;
			}
			this.addProductSetToCompare(tireToSwap);
		}
	}
};
</script>
