import Language from '../Language';

export default new Language(
	'Russian',
	['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
	['Янв', 'Февр', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек'],
	['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
)
// eslint-disable-next-line
;
