import Language from '../Language';

export default new Language(
	'Sloveian',
	['Januar', 'Februar', 'Marec', 'April', 'Maj', 'Junij', 'Julij', 'Avgust', 'September', 'Oktober', 'November', 'December'],
	['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Avg', 'Sep', 'Okt', 'Nov', 'Dec'],
	['Ned', 'Pon', 'Tor', 'Sre', 'Čet', 'Pet', 'Sob']
)
// eslint-disable-next-line
;
