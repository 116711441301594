<template>
    <article>
        <span v-html="contentHTML"></span>
    </article>
</template>

<script>
import VueTypes from 'vue-types';
import { mapActions, mapState } from 'vuex';

export default {
	name: 'ContentContainer',
	props: {
		contentType: VueTypes.oneOf(['headline', 'ad', 'applicableTrivia', '']).def('')
	},
	data() {
		return {
			contentHTML: ''
		};
	},
	methods: {
		...mapActions('content/schedule', ['fetchContent', 'fetchDocs'])
	},
	computed: {
		...mapState('content/schedule', ['trivia', 'ad', 'headline', 'promo', 'dealerSpecialPromo']),

		// eslint-disable-next-line complexity
		applyContent() {
			let cssClass = null;

			switch (this.contentType) {
				case 'headline':
					this.contentHTML = this.headline;
					cssClass = 'col-12 header__page-heading';
					break;

				case 'ad':
					this.contentHTML = this.ad;
					cssClass = 'col-12';
					break;

				case 'applicableTrivia':
					this.contentHTML = this.trivia;
					cssClass = 'col-12';
					break;

				default:
					cssClass = 'col-12';
			}

			return cssClass;
		},
		applicableTrivia() {
			// ranking order: Dealer special promotion > product promotion > standard trivia
			const promoContentTypes = [this.dealerSpecialPromo, this.promo];
			const promoTriviaAvailable = promoContentTypes.find(trivia => trivia.includes('promotionTrivia'));

			return promoTriviaAvailable || this.trivia;
		}
	},
	created() {
		this.fetchContent();
	}
};
</script>
