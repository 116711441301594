import Language from '../Language';

export default new Language(
	'Romanian',
	['Ianuarie', 'Februarie', 'Martie', 'Aprilie', 'Mai', 'Iunie', 'Iulie', 'August', 'Septembrie', 'Octombrie', 'Noiembrie', 'Decembrie'],
	['Ian', 'Feb', 'Mar', 'Apr', 'Mai', 'Iun', 'Iul', 'Aug', 'Sep', 'Oct', 'Noi', 'Dec'],
	['D', 'L', 'Ma', 'Mi', 'J', 'V', 'S']
)
// eslint-disable-next-line
;
