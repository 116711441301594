import Language from '../Language';

const language = new Language(
	'Lithuanian',
	['Sausis', 'Vasaris', 'Kovas', 'Balandis', 'Gegužė', 'Birželis', 'Liepa', 'Rugpjūtis', 'Rugsėjis', 'Spalis', 'Lapkritis', 'Gruodis'],
	['Sau', 'Vas', 'Kov', 'Bal', 'Geg', 'Bir', 'Lie', 'Rugp', 'Rugs', 'Spa', 'Lap', 'Gru'],
	['Sek', 'Pir', 'Ant', 'Tre', 'Ket', 'Pen', 'Šeš']
);

language.ymd = true;

export default language
// eslint-disable-next-line
;
