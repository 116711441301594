const store = {
	namespaced: true,

	state: {
		userSearchType: 'Vehicle',
		query: {},
		dealership_id: null
	},

	mutations: {
		setSearchType(state, val) {
			state.userSearchType = val;
		},
		setQuery(state, val) {
			state.query = val;
		},
		setDealershipId(state, val) {
			state.dealership_id = val;
		}
	}
};


export default store;
