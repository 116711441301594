import Language from '../Language';

const language = new Language(
	'Hebrew',
	['ינואר', 'פברואר', 'מרץ', 'אפריל', 'מאי', 'יוני', 'יולי', 'אוגוסט', 'ספטמבר', 'אוקטובר', 'נובמבר', 'דצמבר'],
	['ינו', 'פבר', 'מרץ', 'אפר', 'מאי', 'יונ', 'יול', 'אוג', 'ספט', 'אוק', 'נוב', 'דצמ'],
	['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ש']
);

language.rtl = true;

export default language
// eslint-disable-next-line
;
