<template>
    <!-- SPEC ROW -->
    <div class="grid-row compare-row compare-row__specs" :class="{ compare__hide: hideRow }" role="row">

        <!-- TITLE OF SPEC -->
        <div class="compare-row__title grid-row__header" role="rowheader"
             :class="[{ 'compare-row__title--highlight': highlightRow }]">
            {{ $t(`message.${specs[spec]}`) }}
        </div>

        <!-- UNIQUE SPECS -->
        <template v-if="isUniqueSpec">
            <template v-if="spec === 'reviews'">
                <div role="gridcell" class="compare-table__spec" v-for="(reviewValue, reviewIndex) in specValues" :key="spec + reviewIndex">
                    <rating slot="reviews" v-if="getPowerReviews.enabled && reviewValue" :modelID="reviewValue" />
                </div>
            </template>

            <!-- PROMOS ONLY -->
            <template v-if="spec === 'promos'">
                <spec-row-promo v-for="(promoValue, promoValueIndex) in specValues" :promoTitlesAndDescriptions="promoValue"
                                :key="spec + promoValueIndex" />
            </template>

            <!-- Financing Data ONLY -->
            <template v-if="spec === 'financing'">
                <div role="gridcell" class="compare-table__spec" v-for="(finValue, finValueIndex) in specValues"
                     :key="spec + finValueIndex">
                    <div v-if="!Object.entries(finValue.financingDisclaimer).length">
                        {{ $t('message.noFinancing') }}
                    </div>
                    <financing-data v-else :product-set="finValue.productSet" :tire-quantity="finValue.tireQuantity"
                                    :financing-disclaimer="finValue.financingDisclaimer">
                    </financing-data>
                </div>
            </template>

            <!-- SIZE ONLY -->
            <template v-if="spec === 'size'">
                <div role="gridcell" class="compare-table__spec" :key="spec + sizeValueIndex"
                     v-for="(sizeValue, sizeValueIndex) in specValues">

                    <template v-if="isStaggered">
                        {{ $t("message.front") }}: {{ sizeValue[0] }}<br>
                        {{ $t("message.rear") }}: {{ sizeValue[1] }}
                    </template>

                    <template v-else>
                        {{ sizeValue }}
                    </template>
                </div>
            </template>

            <template v-if="spec === 'mobileinstall'">
                <spec-row-mobile-install v-for="(_, valueIndex) in specValues"
                                         :isMobileInstallAvailable="isMobileInstallAvailable && isAvailableFacingWarehouse[valueIndex]"
                                         :key="spec + valueIndex" />
            </template>
        </template>

        <!-- ALL OTHER SPECS -->
        <template v-else>
            <div role="gridcell" class="compare-table__spec" :key="spec + specValueIndex"
                 v-for="(specValue, specValueIndex) in specValues">
                {{ properSpecValue(specValue, spec) }}
            </div>
        </template>
        <!-- BLANK COLUMNS IF LESS THAN 3 TIRES ARE BEING COMPARED ON DESKTOP -->
        <template>
            <div v-for="(n, index) in numberOfBlankCols" :key="'blankCell' + index" class="compare-table__spec" />
        </template>
    </div>
</template>

<script>
import SpecRowPromo from '@/components/CompareSpecRowPromo.vue';
import SpecRowMobileInstall from '@/components/CompareSpecRowMobileInstall.vue';
import FinancingData from '@/components/FinancingData.vue';
import Rating from '@/components/TileRating.vue';

import { mapState, mapGetters } from 'vuex';
import { convertBooleans, convertSpecValues, renameSpecTitlesForCompare } from '@/utils/specification-conversion.js';

export default {
	data() {
		return {
			specs: {
				ecoTire: 'ecoTire',
				size: 'size',
				weight: 'weight',
				performanceType: 'performanceType',
				serviceDescription: 'serviceDescription',
				speedRating: 'speedRating',
				sidewall: 'sidewall',
				temp: 'temp',
				tractionGrade: 'traction',
				gradeTreadWear: 'treadWear',
				maxRimWidth: 'maxRim',
				diameter: 'diameter',
				reviews: 'reviews',
				treadDepth: 'treadDepth',
				financing: 'financing',
				promos: 'promos',
				mobileinstall: 'mobileInstall',
				season: 'season',
				oemTireMarkDescription: 'oemTireMarkDescription',
				warrantyYears: 'warrantyYears',
				warrantyMilesNumeric: 'warrantyMilesNumeric',
				roadHazardFlag: 'roadHazard',
				isRunflat: 'runFlatDescription',
				serviceIndex: 'serviceIndex',
				factoryInstalled: 'factoryInstalled'
			}
		};
	},
	methods: {
		properSpecValue(specValue, spec) {
			if (typeof specValue === 'boolean' || typeof specValue === 'undefined') {
				return convertBooleans(specValue, this);
			}

			return convertSpecValues(specValue, spec);
		}
	},
	filters: {
		properTitle(spec) {
			return renameSpecTitlesForCompare(spec);
		}
	},
	props: {
		hideRow: {
			type: Boolean,
			required: true,
			default: false
		},
		highlightRow: {
			type: Boolean,
			required: true,
			default: false
		},
		numberOfBlankCols: {
			type: Number,
			required: true
		},
		spec: {
			type: String,
			required: true
		},
		specValues: {
			type: Array,
			required: true
		},
		productSetsToCompare: {
			type: Array,
			required: true
		}
	},
	components: {
		SpecRowPromo,
		FinancingData,
		SpecRowMobileInstall,
		Rating
	},
	computed: {
		...mapState('products', [
			'isStaggered'
		]),
		...mapGetters('initial', ['getPowerReviews']),
		...mapState('dealer', ['selected']),
		// Logic to display compare-row different for promos, sizes, and financing
		// eslint-disable-next-line complexity
		isUniqueSpec() {
			return (this.spec === 'reviews' || this.spec === 'promos' || this.spec === 'size' || this.spec === 'financing' || this.spec === 'mobileinstall');
		},
		isMobileInstallAvailable() {
			// eslint-disable-next-line no-undefined
			return this.selected.transports.find(transport => transport.id === 14) !== undefined;
		},
		isAvailableFacingWarehouse() {
			return this.productSetsToCompare.map((productSet) => {
				return productSet.products.every((product) => product.isAvailableFacingWarehouse === true);
			});
		}
	}
};
</script>
