/* eslint-disable no-underscore-dangle */
/**
 * NOTE: This needs to route to the "legacy" bundled version.
 * This is because our webpack version is not able to process "dynamic imports"
 * See docs: https://www.rudderstack.com/docs/sources/event-streams/sdks/rudderstack-javascript-sdk/installation/#sdk-imports-for-bundling-tools-that-process-dynamic-imports
 */
import { RudderAnalytics } from '@rudderstack/analytics-js/dist/npm/legacy/bundled/umd/index.js';
const rudderAnalytics = new RudderAnalytics();

const env = window.__INITIAL_STATE__.env;

// We are currently only loading Rudderstack for US. NOT Canada!
if (!env.isCA) {
	rudderAnalytics.load(env.rudderStack.key, env.rudderStack.url, {});
}

export { rudderAnalytics };
