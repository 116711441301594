<!-- eslint-disable complexity -->
<template>
    <div
        :class="[`col-${colSize}`, 'tile__flex']"
        :id="`tile-${productSet.products[0].modelID}`"
    >
        <div
            class="tile__promotions-pricing-wrapper"
            ref="promotionsPricingWrapper"
        >
            <!-- Promotions -->
            <div
                v-if="promos.length && !isMobileInstallOpen"
                :class="['row', { 'tile--promo-bg tile--promo-active': isPromoOpen }]"
                ref="promoParent"
            >
                <div class="col-12">
                    <span
                        class="tile__promotions-icon"
                        :class="{
                            'tile__promotions-icon--mobile-details': isOnDetailPage,
                            'tile__promotions-icon--disabled': !hasAppliedPromo,
                        }"
                    >
                        <div class="icon icon--money"></div>
                    </span>

                    <div
                        v-for="(promo, promoIndex) in promos"
                        :key="promoIndex"
                        ref="promotionWrapper"
                        class="tile__promotion"
                        :class="{ 'tile__promotion-notApplied': !promo.applied }"
                    >
                        <button
                            class="tile__promotion-header"
                            @click="displayPromotion($event, promoIndex)"
                            :aria-label="`Expand information for ${promo.title}`"
                        >
                            <span>{{ promo.title }}</span>
                            <i
                                ref="promoArrow"
                                class="tile__promotion-arrow-down icon icon--angle-down"
                            ></i>
                        </button>

                        <div class="tile__promo-details is-hidden" ref="promoDetails">
                            <div class="tile__promotion-body">
                                <p>{{ promo.description }}</p>
                            </div>

                            <div
                                v-for="(doc, docIndex) in promo.docs"
                                class="tile__promotion-footer"
                                :key="docIndex"
                            >
                                <a
                                    :href="doc.location"
                                    target="_blank"
                                    class="tile__promotion-link"
                                >
                                    <i class="icon icon--file-pdf tile__promotion-download"></i>
                                    {{ doc.title }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Mobile Install -->
            <div
                v-if="
                    isMobileInstallAvailable && isAvailableFacingWarehouse && !isPromoOpen
                "
                :class="[
                    'row mobile-install-eligible',
                    { 'tile--mi-bg tile--mi-active': isMobileInstallOpen },
                ]"
            >
                <div class="col-12">
                    <span
                        class="tile__mi-icon"
                        :class="{
                            'is-hidden': isOnDetailPage,
                        }"
                    >
                        <img
                            class="icon"
                            src="/dist/assets/svgs/mobile-install-icon-white.svg"
                            alt="Mobile Install Icon"
                        />
                    </span>

                    <div class="tile__mi">
                        <button class="tile__mi-header" @click="openMobileInstall()">
                            <span>{{ $t("message.mobileInstallEligible") }}</span>
                            <i
                                class="tile__mi-arrow-down icon icon--angle-down"
                                :class="{ 'is-open': isMobileInstallOpen }"
                            ></i>
                        </button>
                    <!-- Apr 2024: removing mobile install call for now -->
                    <!-- <div
                            :class="[
                                'tile__mi-details',
                                { 'is-hidden': !isMobileInstallOpen },
                            ]"
                            v-html="productTileMobileInstall"
                        ></div> -->
                    </div>
                </div>
            </div>

            <!-- Loose Pricing/QTA/CTA -->
            <div
                v-if="!isStaggered"
                ref="pricingWrapper"
                :class="{ 'is-hidden': isPromoOpen || isMobileInstallOpen }"
            >
                <!-- Pricing -->
                <div class="row tile__pricing-wrapper">
                    <div class="col-md-6">
                        <p
                            :class="[
                                'tile__tire-price-desc',
                                { 'tile__tire-price-desc--active': showPriceBreakdown },
                            ]"
                        >
                            {{ $t("message.tirePrice") }}
                        </p>
                        <p class="tile__price">
                            {{
                                $n(productSet.products[0].pricing.unitRetailPrice, "currency")
                            }}
                        </p>
                    </div>

                    <div class="col-md-6">
                        <button
                            type="button"
                            :aria-label="`${installedPrice}: ${tire}`"
                            @click="showPriceBreakdown = !showPriceBreakdown"
                            :aria-expanded="showPriceBreakdown ? 'true' : 'false'"
                            :class="[
                                'tile__install-price-button',
                                { 'tile__install-price-button--active': showPriceBreakdown },
                            ]"
                        >
                            <span class="accessibility__label">
                                {{ $t("message.accessibilityToggleInstallDetail") }}
                            </span>
                            <i
                                class="icon icon--angle-down"
                                :class="[
                                    'tile__install-arrow',
                                    { 'tile__install-arrow--active': showPriceBreakdown },
                                ]"
                            ></i>
                            <span :class="[
                                'tile__install-price-desc',
                                { 'tile__install-price-desc--active': showPriceBreakdown },
                            ]">
                                {{ installedPrice }}
                            </span>
                        </button>

                        <p
                            :class="[
                                'tile__price',
                                'tile__price--install',
                                { 'tile__price--active': showPriceBreakdown },
                            ]"
                        >
                            {{
                                $n(productSet.products[0].pricing.unitInstallPrice, "currency")
                            }}
                        </p>
                    </div>

                    <div
                        v-if="showPriceBreakdown"
                        class="col-12 tile__install-cms"
                        v-html="priceBreakdown"
                    ></div>
                </div>

                <!-- Quantity -->
                <div class="row tile__pricing-wrapper">
                    <form
                        class="col-12 tile__qty-form"
                        @submit.prevent="checkInventory"
                    >
                        <div class="row">
                            <div class="col-sm-12 col-md-6 tile__quantity-input-wrapper">
                                <label>{{ $t("message.qty") }}:</label>
                                <input
                                    :aria-label="quantityInputLabel"
                                    class="tile__quantity-input safe-input"
                                    type="number"
                                    min="1"
                                    max="4"
                                    @change="qtyChange()"
                                    v-model.number="locQty.front"
                                />
                            </div>
                        </div>
                        <!-- Squared CTA Button -->
                        <stateful-button
                            :aria-label="productLabel"
                            :isLoading="isLoading"
                            :optClass="statefulBtnClass"
                            data-cy="productTileChooseButton"
                        >
                            {{ $t("message.choose") }}
                        </stateful-button>
                    </form>

                    <!-- Set of 4 Price -->
                    <div class="col-12 tile__price-set-of-4">
                        <!-- Only show crossout price if it does not match the final price
                        it should never be MORE, but if so, let's show it so we can fix it -->
                        <span v-if="hasAppliedPromo && productSet.pricing.installPrice !== productSet.pricing.installPriceAfterSavings"
                              class="tile__no-wrap tile__no-wrap--strike">
                            {{ $n(productSet.pricing.installPrice, "currency") }}
                        </span>

                        <p
                            class="tile__set-of-4-msg"
                            :class="{ 'tile__strike-through': hasAppliedPromo }"
                        >
                            {{ $t(tireMessage) }}
                            <span class="tile__no-wrap">
                                =
                                <strong>{{
                                    $n(productSet.pricing.installPriceAfterSavings, "currency")
                                }}</strong>
                            </span>
                        </p>
                        <p>{{ $t("message.taxNotIncluded") }}</p>
                    </div>
                </div>
                <!-- Financing Data -->
                <div
                    v-if="showFinancing"
                    class="row tile__pricing-wrapper"
                >
                    <financing-data
                        :product-set="productSet"
                        :tire-quantity="tireQuantity"
                        :financing-disclaimer="financingDisclaimer"
                    >
                    </financing-data>
                </div>
            </div>

            <!-- Staggered Pricing/QTA/CTA -->
            <div
                v-if="isStaggered"
                ref="pricingWrapper"
                :class="{ 'is-hidden': isPromoOpen || isMobileInstallOpen }"
            >
                <!-- Pricing -->
                <div class="row tile__pricing-wrapper">
                    <div class="col-md-6">
                        <p
                            :class="[
                                'tile__tire-price-desc',
                                { 'tile__tire-price-desc--active': showPriceBreakdown },
                            ]"
                        >
                            {{ $t("message.tirePrice") }}
                        </p>

                        <div class="tile__price">
                            <!--retail price--->
                            <span class="tile__stag-position-text">{{
                                $t("message.front")
                            }}</span>
                            {{ $n(productSet.products[0].pricing.retailPrice, "currency") }}
                        </div>

                        <div class="tile__price">
                            <span class="tile__stag-position-text">{{
                                $t("message.rear")
                            }}</span>
                            {{ $n(productSet.products[1].pricing.retailPrice, "currency") }}
                        </div>
                    </div>

                    <div class="col-md-6">
                        <button
                            type="button"
                            :aria-label="`${installedPrice}: ${tire}`"
                            @click="showPriceBreakdown = !showPriceBreakdown"
                            :aria-expanded="showPriceBreakdown ? 'true' : 'false'"
                            :class="[
                                'tile__install-price-button',
                                { 'tile__install-price-button--active': showPriceBreakdown },
                            ]"
                        >
                            <span class="accessibility__label">
                                {{ $t("message.accessibilityToggleInstallDetail") }}
                            </span>
                            <i
                                class="icon icon--angle-down"
                                :class="[
                                    'tile__install-arrow',
                                    { 'tile__install-arrow--active': showPriceBreakdown },
                                ]"
                            ></i>
                            <span :class="[
                                'tile__install-price-desc',
                                { 'tile__install-price-desc--active': showPriceBreakdown },
                            ]">
                                {{ installedPrice }}
                            </span>
                        </button>
                        <!--install price--->
                        <div
                            :class="[
                                'tile__price',
                                'tile__price--install',
                                {
                                    'tile__price--active tile__install-price--active':
                                    showPriceBreakdown,
                                },
                            ]"
                        >
                            <span
                                class="tile__stag-position-text tile__stag-position-text--install"
                                :class="{
                                    'tile__stag-position-text--install-details': isOnDetailPage,
                                }"
                            >{{ $t("message.front") }}</span
                            >
                            {{ $n(productSet.products[0].pricing.installPrice, "currency") }}
                        </div>

                        <div
                            :class="[
                                'tile__price',
                                'tile__price--install',
                                {
                                    'tile__price--active tile__install-price--active tile__rear-install-price--active':
                                    showPriceBreakdown,
                                },
                            ]"
                        >
                            <span
                                class="tile__stag-position-text tile__stag-position-text--install"
                                :class="{
                                    'tile__stag-position-text--install-details': isOnDetailPage,
                                }"
                            >{{ $t("message.rear") }}</span
                            >
                            {{ $n(productSet.products[1].pricing.installPrice, "currency") }}
                        </div>
                    </div>

                    <div
                        v-if="showPriceBreakdown"
                        class="col-12 tile__install-cms"
                        v-html="priceBreakdown"
                    ></div>
                </div>

                <div class="row tile__pricing-wrapper">
                    <!-- Quantity -->
                    <form
                        class="col-12 tile__qty-form"
                        @submit.prevent="checkInventory"
                    >
                        <div class="row">
                            <div class="col-6">
                                <label class="tile__qty-label">{{ $t("message.front") }}</label>
                                <input
                                    class="tile__quantity-input tile__quantity-input--staggered safe-input"
                                    type="number"
                                    :min="this.locQty.rear != 0 ? 0 : 1"
                                    max="2"
                                    :title="quantityInputLabelFront"
                                    :aria-label="quantityInputLabelFront"
                                    @change="qtyChange()"
                                    v-model.number="locQty.front"
                                />
                            </div>

                            <div class="col-6">
                                <label class="tile__qty-label">{{ $t("message.rear") }}</label>
                                <input
                                    class="tile__quantity-input tile__quantity-input--staggered safe-input"
                                    type="number"
                                    :min="this.locQty.front != 0 ? 0 : 1"
                                    max="2"
                                    :title="quantityInputLabelRear"
                                    :aria-label="quantityInputLabelRear"
                                    @change="qtyChange()"
                                    v-model.number="locQty.rear"
                                />
                            </div>
                        </div>

                        <!-- Staggered CTA Button -->
                        <stateful-button
                            :aria-label="productLabel"
                            :disabled="isDisabled"
                            :isLoading="isLoading"
                            :optClass="statefulBtnClass"
                        >
                            {{ $t("message.choose") }}
                        </stateful-button>
                    </form>

                    <!-- Set of 4 Price -->
                    <div class="col-12 tile__price-set-of-4">
                        <!-- Only show crossout price if it does not match the final price
                        it should never be MORE, but if so, let's show it so we can fix it -->
                        <span
                            v-if=" hasAppliedPromo && productSet.pricing.installPrice !== productSet.pricing.installPriceAfterSavings"
                            class="tile__no-wrap tile__no-wrap--strike">
                            {{ $n(productSet.pricing.installPrice, "currency") }}
                        </span>

                        <p
                            class="tile__set-of-4-msg"
                            :class="{ 'tile__strike-through': hasAppliedPromo }"
                        >
                            {{ $t(tireMessage) }}
                            <span class="tile__no-wrap">
                                =
                                <strong>{{
                                    $n(productSet.pricing.installPriceAfterSavings, "currency")
                                }}</strong>
                            </span>
                        </p>
                        <p>{{ $t("message.taxNotIncluded") }}</p>
                    </div>
                </div>

                <!-- Financing Data -->
                <div
                    v-if="showFinancing"
                    class="row tile__pricing-wrapper"
                >
                    <financing-data
                        :product-set="productSet"
                        :tire-quantity="tireQuantity"
                        :financing-disclaimer="selected.financePartner || null"
                    >
                    </financing-data>
                </div>
            </div>
        </div>

        <div v-html="details"></div>
    </div>
</template>

<script>
import { extend, isEmpty, sortBy } from 'underscore';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import {
	noDataEvent,
	productSkuEvent,
	productTileChoose,
	productMobileInstallDropdown,
	detailsMobileInstallDropdown
} from '@/utils/setDataLayer';
import StatefulButton from '@/components/StatefulButton.vue';
import FinancingData from '@/components/FinancingData.vue';
import { RudderstackProductsMixin } from '@/rudderstack/track/mixins/products';

export default {
	name: 'TileShopBlock',

	props: {
		query: {
			type: Object,
			required: true
		},
		tilePosition: {
			required: false,
			type: Number,
			default: 0
		},
		productSet: {
			required: true
		},
		colSize: {
			default: 5
		},
		isOnDetailPage: {
			type: Boolean,
			required: false,
			default: false
		},
		isWheelPackage: {
			type: Boolean,
			required: false,
			default: false
		},
		buttonColSize: {
			type: String,
			default: 'col-md-4'
		},
		statefulBtnClass: {
			type: String,
			default: 'button button--primary button--full tile--button'
		},
		financingDisclaimer: {
			type: Object
		},
		isMobileInstallAvailable: {
			type: Boolean,
			default: false
		},
		tire: {
			type: String
		}
	},

	mixins: [RudderstackProductsMixin],

	components: {
		StatefulButton,
		FinancingData
	},

	data() {
		return {
			frontTire: {},
			isLoading: false,
			isPromoOpen: false,
			isMobileInstallOpen: false,
			openPromoIndex: -1,
			locQty: {},
			showPriceBreakdown: false
		};
	},

	watch: {
		qtySelected([front, rear]) {
			this.locQty = {
				front,
				rear
			};
		}
	},

	methods: {
		...mapMutations('products', ['setSelectedModelId', 'updateQty']),
		...mapActions('cart', ['addTireToCart']),
		...mapActions('products', ['getInventory']),
		isEmpty,
		qtyChange() { // eslint-disable-line complexity
			// update the quantity so all tire prices update together
			let newQty = [this.locQty.front];

			const { products } = this.productSet;

			this.setSelectedModelId(products[0].modelID);

			if (this.locQty.rear) newQty.push(this.locQty.rear);

			if (this.isStaggered) {
				if (!this.locQty.front) {
					newQty =
            !this.locQty.rear || this.locQty.rear < 1 ? [1, 0] : [0, this.locQty.rear];
					this.locQty.front = newQty[0];
				}
				if (!this.locQty.rear) {
					newQty =
            !this.locQty.front || this.locQty.front < 1 ? [0, 1] : [this.locQty.front, 0];
					this.locQty.rear = newQty[1];
				}
			} else if (typeof this.locQty.front === 'string') {
				newQty = [1];
				this.locQty.front = newQty[0];
			}
			this.updateQty(newQty);
		},
		// eslint-disable-next-line complexity
		displayPromotion($event, promoIndex) {
			// Set the minHeight property to prevent the tile from collapsing
			let minHeight =
        this.$refs.pricingWrapper.clientHeight +
        this.$refs.promotionWrapper[promoIndex].clientHeight;

			// If the promotion was being displayed hide the promo details and show the pricing
			if (this.openPromoIndex === promoIndex) {
        //eslint-disable-line
				this.isPromoOpen = false;
				this.$refs.promotionWrapper[promoIndex].style.minHeight = 0;
				this.$refs.promoDetails[promoIndex].classList.add('is-hidden');
				this.$refs.promoArrow[promoIndex].classList.remove('is-open');
				this.openPromoIndex = -1;

				return;
			}

			// Hide pricing, apply styles to clicked promotion and show the details
			// If there are multiple promotions but the promo that was clicked was not the open one, hide the open promo
			if (this.openPromoIndex >= 0) {
				minHeight =
          this.$refs.promotionWrapper[this.openPromoIndex].style['min-height'];
				this.$refs.promotionWrapper[this.openPromoIndex].style.minHeight = 0;
				this.$refs.promoDetails[this.openPromoIndex].classList.add('is-hidden');
				this.$refs.promoArrow[this.openPromoIndex].classList.remove('is-open');
			}

			// Show the promotions details and hide pricing
			this.isPromoOpen = true;
			this.isMobileInstallOpen = false;
			this.$refs.promotionWrapper[promoIndex].style.minHeight = minHeight;
			this.$refs.promoArrow[promoIndex].classList.add('is-open');
			this.$refs.promoDetails[promoIndex].classList.remove('is-hidden');
			this.openPromoIndex = promoIndex;
		},
		openMobileInstall() {
			if (this.isMobileInstallOpen) {
				this.isMobileInstallOpen = false;
			} else {
				this.isMobileInstallOpen = true;
				this.isPromoOpen = false;
				// Data layer event for mobile install
				if (this.isOnDetailPage) {
					detailsMobileInstallDropdown(window.location.href, this.productSet);
				} else {
					productMobileInstallDropdown(window.location.href, this.productSet);
				}
			}
		},
		// needs to be async so that we have product info loaded + sourcing is done before going to schedule
		async checkInventory() { // eslint-disable-line complexity
			const { products } = this.productSet;

			this.isLoading = true;
			let skusAndQty = [];

			try {
				skusAndQty = await this.getInventory({
					products,
					qty: this.qtySelected.length ? this.qtySelected : this.defaultQty
				});
			} catch (err) {
				this.isLoading = false;
				throw new Error('No inventory');
			}

			if (!skusAndQty && !this.oos) {
				this.isLoading = false;
				throw new Error('No inventory');
			}
			this.isLoading = false;
			this.confirmTirePurchase(products, skusAndQty);
		},
		async confirmTirePurchase(products, skusAndQty) {
			if (!this.oos && skusAndQty) {
				skusAndQty[0].promos = this.promos;
				await this.addTireToCart(skusAndQty);
				this.goToSchedule(skusAndQty);
			} else {
				this.setProductName(products[0].catalogEntryDescription);
			}
		},
		goToSchedule() {// eslint-disable-line complexity
			this.trackProductAdded({
				position: this.tilePosition,
				sort: this.sortBy,
				filteredBy: this.filteredBy
			});

			// Sets data layer
			if (this.isOnDetailPage) {
				productSkuEvent('productDetailsPageEngageChoose', this.productSet);
				productSkuEvent('productDetailsPageEngageChooseTire', this.productSet);
			} else {
				productTileChoose({
					productSet: this.productSet,
					tilePosition: this.tilePosition
				});

				noDataEvent('productTileChooseTire');
			}

			// Determines whether to show schedule or appointment page (A/B)
			// TileShopBlock is used on both /product and /details, but A/B variant HTML is only
			// visible on /product
			// 1) if appointment or schedule has already been determined (i.e. you are on details page)
			// 2) Else you are on product page
			this.$router.push({
				path: '/app/schedule',
				query: extend(this.query, {
					localBest: this.productSet.localBestSeller || false,
					qty: this.qtySelected.length
						? this.qtySelected.join(',')
						: this.defaultQty.join(','),
					sku:
            this.productSet.products.length > 1 ? this.productSet.products.map((prod) => prod.sku).join() : this.productSet.products[0].sku
				})
			});
		}
	},

	computed: {
		...mapState('modal', ['oos']),
		...mapState('content/products', [
			'details',
			'priceBreakdown'
			// 'productTileMobileInstall'
		]),
		...mapState('dealer', ['selected']),
		...mapState('products', [
			'defaultQty',
			'qtySelected',
			'deliveryDate',
			'selectedProdName'
		]),
		...mapGetters('productResults', ['filterToShow', 'sortBy']),
		activeAppliedPromos() {
			return this.promos.filter(function (p) {
				return p.applied;
			});
		},
		installedPrice() {
			return this.$t('message.installedPrice');
		},
		hasAppliedPromo() {
			if (this.activeAppliedPromos.length) {
				return true;
			}

			return false;
		},
		isDisabled() {
			// Disable the button if there is 0 qty for front and rear
			return !this.locQty.rear && !this.locQty;
		},
		isStaggered() {
			if (this.productSet.products) {
				return this.productSet.products.length > 1;
			}

			return false;
		},
		promos() {
			// Combine the applied promotion with rebates to iterate over in the template
			let applicablePromotions = sortBy(
				this.productSet.promotions,
				'applied'
			).reverse();

			if (!applicablePromotions) {
				applicablePromotions = [];
			}

			return applicablePromotions;
		},
		// Prevents the tile from collapsing when a promotion is opened
		promoWrapperMinHeightClass() {
			return this.isStaggered ? 'tile--staggered-height' : 'tile--loose-height';
		},
		// eslint-disable-next-line complexity
		tireMessage() {
			let setNumInstalled = '';
			let totalQty = this.qtySelected.length
				? this.qtySelected.reduce((total, q) => total + q)
				: this.defaultQty;

			switch (totalQty) {
				case 4:
					setNumInstalled = 'message.setOfFourInstalled';
					break;
				case 3:
					setNumInstalled = 'message.setOfThreeInstalled';
					break;
				case 2:
					setNumInstalled = 'message.setOfTwoInstalled';
					break;
				case 1:
					setNumInstalled = 'message.oneInstalled';
					break;
				default:
					setNumInstalled = 'message.setOfFourInstalled';
					break;
			}

			return setNumInstalled;
		},
		tireQuantity() {
			if (!this.locQty.rear) {
                return parseInt(this.locQty.front); //eslint-disable-line
			}

			return parseInt(this.locQty.front + this.locQty.rear); //eslint-disable-line
		},
		isAvailableFacingWarehouse() {
			return this.productSet.products.every(
				(product) => product.isAvailableFacingWarehouse === true
			);
		},
		tireBrandAndModel() {
			return `${ this.frontTire.brand } ${ this.frontTire.catalogEntryDescription}`;
		},
		quantityInputLabel() {
			return `${this.$t('message.accessibilityTireQty')} ${ this.frontTire.brand } ${ this.frontTire.catalogEntryDescription}`;
		},
		quantityInputLabelFront() {
			return `${this.$t('message.frontTireQuantity')} ${ this.frontTire.brand } ${ this.frontTire.catalogEntryDescription}`;
		},
		quantityInputLabelRear() {
			return `${this.$t('message.rearTireQuantity')} ${ this.frontTire.brand } ${ this.frontTire.catalogEntryDescription}`;
		},
		productLabel() {
			return `${this.$t('message.choose') } ${ this.frontTire.brand } ${ this.frontTire.catalogEntryDescription}`;
		},
		showFinancing() {
			return this.selected.hasFinancingOTS && this.selected.financePartnerCode;
		}
	},

	created() { // eslint-disable-line complexity
		this.frontTire = this.productSet.products[0];

		const [front, rear] = isEmpty(this.qtySelected)
			? this.defaultQty
			: this.qtySelected;

		this.locQty = {
			front: this.isStaggered ? front || 0 : front,
			rear: this.isStaggered ? rear || 0 : rear
		};
	}
};
</script>
