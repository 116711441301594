<template>
    <MountingPortal mountTo="#dealerContact">
        <div class="header-contact-oem" v-html="contactHTML"></div>
    </MountingPortal>
</template>

<script>
import axios from 'axios';

export default {
	name: 'DealerContact',
	data() {
		return {
			contactHTML: ''
		};
	},
	methods: {
		async fetchContact() {
			const contactContent = await axios.get('/service/cms/contact');

			this.contactHTML = contactContent.data;
		}
	},
	watch: {
		'$store.state.consumerInfo.userLanguage': function() {
			// true fed to fetchContent to trigger re-fetching of content if userLanguage is changed
			this.fetchContact();
		}
	},
	created() {
		this.fetchContact();
	}
};
</script>
