<template>
    <button
        @click="handleClick"
        :class="classes"
        :disabled="disabled"
        :type="type"
    >

        <transition name="fade" mode="out-in">
            <div
                role="alert"
                aria-busy="true"
                :aria-label="$t('message.loading')"
                aria-live="assertive"
                :class="['stateful-button__spinner', {
                    'stateful-button__spinner--show': isLoading
            }]"></div>
        </transition>

        <slot></slot>
    </button>
</template>

<script>
export default {
	name: 'stateful-button',
	props: {
		disabled: {
			type: Boolean,
			default: false
		},
		isLoading: {
			type: Boolean,
			default: false
		},
		status: {
			type: [String, Boolean],
			default: ''
		},
		optClass: {
			type: String,
			default: '',
			required: false
		},
		type: {
			type: String,
			default: 'submit',
			required: false
		}
	},
	data() {
		return {
			classes: [`button button--primary stateful-button ${this.optClass}`, {
				'stateful-btn--error': !this.status,
				'is-loading': this.isLoading
			}]
		};
	},

	methods: {
		handleClick: function() {
			this.$emit('statefulButtonClicked');
		}
	}
};
</script>
