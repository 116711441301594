<template>
    <div class="header-cell">

        <!-- BRAND -->
        <p class="header-cell__tire-brand">
            {{ productSet.products[0].brand }}
        </p>

        <!-- TIRE MODEL -->
        <p class="header-cell__tire-name">
            {{ productSet.products[0].catalogEntryDescription }}
        </p>

        <p class="header-cell__tire-details">
            {{ productSet.products[0].size }} | {{ productSet.products[0].serviceDescription }}
        </p>

        <!-- STAGGERED PRICE -->
        <template v-if="isStaggered">
            <p>
                <span>{{ $t("message.front") }}: </span>
                <span class="header-cell__price">
                    <strong>
                        {{ $n(productSet.products[0].pricing.unitInstallPrice , 'currency') }}
                    </strong>
                    {{ $t("message.each") }}
                </span>
            </p>

            <p>
                <span>{{ $t("message.rear") }}: </span>
                <span class="header-cell__price">
                    <strong>
                        {{ $n(productSet.products[1].pricing.unitInstallPrice , 'currency') }}
                    </strong>
                    {{ $t("message.each") }}
                </span>
            </p>
        </template>

        <!-- LOOSE PRICE -->
        <p v-else class="header-cell__price">
            <strong>
                {{ $n(productSet.products[0].pricing.unitInstallPrice , 'currency') }}
            </strong>
            {{ $t("message.each") }}
        </p>

    </div>
</template>

<script>
import Shop from '@/components/CompareHeaderCellShop.vue';

import { mapState } from 'vuex';

export default {
	props: {
		productSet: {
			type: Object,
			required: true
		}
	},

	components: {
		Shop
	},

	computed: {
		...mapState('products', ['isStaggered']),

		// eslint-disable-next-line complexity

		tireBrand() {
			return this.productSet.products[0].brand;
		},
		tireModel() {
			return this.productSet.products[0].catalogEntryDescription;
		},
		tireBrandAndModel() {
			return `${ this.tireBrand } ${ this.tireModel }`;
		}
	}
};
</script>
