import { has, find, isEmpty, extend, pluck, flatten, max, filter, contains, map } from 'underscore';
import {
	findProductByID,
	findProductBySKU,
	checkForTruck,
	setupProducts,
	setWarehouseQty
} from './helpers.js';

import packagePrices from '../data/wheel-package-prices.json';


import axios from 'axios';

export const setSingleProduct = async ({ commit, getters }, { query, id }) => { //eslint-disable-line
	if (isEmpty(getters.allProducts) || query.sku) {
		// Indicate the qty set by the user. For first runs, leave a [0]
		// the service layer will substitute 4 for square and 2s for staggered.
		// Before first search, we do not know for sure if the search will result in loose or staggered
		query.qty = query.qty || getters.qtySelected || [0];

		if (query.sku) {
			try {
				if (Array.isArray(query.qty)) {
					query.qty = query.qty.join(',');
				}

				const { data } = await axios.get('/service/itemdetailsrequest', {
					retry: false,
					params: query
				});

				const singleProduct = await findProductBySKU(data.filteredPQR, query.sku);

				// add just our chosen tire
				if (data.filteredPQR.staggered.length) {
					commit('isStaggered', true);
				}
				if (singleProduct) {
					commit('singleProduct', singleProduct);

					return;
				}

				throw new Error(`No product for sku ${query.sku}`);

			} catch (err) {
				commit('singleProduct', []);
				throw err;
			}
		}

		if (query.qty.length > 1) {
			query.qty.join(',');
		} else {
			query.qty = [query.qty];
		}

		return axios.get('/service/products', { //eslint-disable-line
			params: query
		}).then(({ data }) => {
			const singleProduct = findProductByID(data.filteredPQR, id);

			if (data.filteredPQR.staggered.length) {
				commit('isStaggered', true);
			}

			if (singleProduct) {
				commit('singleProduct', singleProduct);
			} else {
				throw new Error(`No product for ${id}`);
			}
		}).catch(err => {
			commit('singleProduct', []);

			throw err;
		});
	}
	return commit('singleProduct', findProductByID(getters.allProducts, id)); //eslint-disable-line
};

export const setProducts = ({ commit, rootState }) => {

	commit('cart/order/setOrder', {

		// We need to attach our warehouses to the product object before they are set
		// And ready to be sent in
		products: rootState.cart.tiresInCart.products.map((prod, i) => {
			return extend({}, prod, { warehouses: rootState.cart.warehouses[i] });
		}),
		services: rootState.cart.servicesInCart
	}, { root: true });
};

export const setNoTires = ({ commit }, payload) => commit('noTires', payload);

export const getAllProducts = async ({ commit, getters }, payload) => { //eslint-disable-line
	// Reset noTires to default state so the value can be watched for changes on subsequent calls
	commit('noTires', false);
	// Indicate the qty set by the user. For first runs, leave a [0]
	// the node service layer will substitute 4 for square and 2s for staggered.
	// Before first search, we do not know for sure if the search will result in loose or staggered
	const qtys = isEmpty(getters.qtySelected)
		? [0]
		: getters.qtySelected;

	payload.qty = qtys.join(',');

	const { data } = await axios.get('/service/products', {params: {
		year: payload.productVehicleYear,
		make: payload.productVehicleMake,
		model: payload.productVehicleModel,
		trim: payload.productVehicleTrim,
		option: payload.productVehicleOption,
		tireSize: payload.tireSize,
		qty: payload.qty,
		width: payload.productTireWidth,
		ratio: payload.productTireRatio,
		diameter: payload.productTireDiameter
	}
	});
	const { loose, staggered, wpk } = data.filteredPQR;

	commit('showTruck', Boolean(checkForTruck(data.filteredPQR)));

	if (!loose.length && !staggered.length && !wpk.length) {
		commit('modal/toggleNoProdsModal', {}, { root: true });

		return commit('noTires', true);
	}

	if (wpk.length) {
		wpk.forEach(pack => {
			const packagePrice = packagePrices[pack.sku];

			pack.pricing = packagePrice;

			return pack;
		});
	}

	return setupProducts(commit, getters, data);
};

export const setCompareProducts = ({ commit, getters }, ids) => {
	let productIds = ids;

	// Splits the productIds if staggered
	if (getters.allProducts[0].products.length > 1) {
		productIds = map(ids, function (prodId) {
			return prodId.split(',')[0];
		});
	}
	const selectedProds = filter(getters.allProducts, function (singleProductSet) {

		return contains(productIds, singleProductSet.products[0].productId);
	});

	commit('setSingleProductSetToCompare', [selectedProds[0]]);
	commit('setProductSetsToCompare', selectedProds);
};

export const addProductSetToCompare = ({ commit, getters }, productSet) => {
	let compareProductSets = getters.productSetsToCompare;

	compareProductSets.push(productSet);
	commit('setProductSetsToCompare', compareProductSets);
};

export const removeProductSetFromCompare = ({ commit, getters }, productSet) => {
	let compareProductSets = getters.productSetsToCompare;

	compareProductSets.splice(compareProductSets.indexOf(productSet), 1);

	if (JSON.stringify(productSet) === JSON.stringify(getters.singleProductSetToCompare[0])) {
		commit('setSingleProductSetToCompare', [compareProductSets[0]]);
	}

	commit('setProductSetsToCompare', compareProductSets);
};

export const filterCompareItemsFromAllProducts = ({ commit, dispatch, getters }) => {
	const { allProducts, productSetsToCompare } = getters;

	commit('allProducts', allProducts);

	if (productSetsToCompare.length) {
		const compareTireIds = map(productSetsToCompare, (productSetToCompare) => {
			return productSetToCompare.products[0].productId;
		});

		dispatch('setCompareProducts', compareTireIds);
	}
};

export const refreshCompareProducts = ({ dispatch }, payload) => {

	dispatch('getAllProducts', payload).then(() => {
		dispatch('filterCompareItemsFromAllProducts');
	});
};

export const getEarliestGuaranteedTime = ({ commit }, shipment) => {
	const times = shipment.map(item => pluck(item.shipment, 'deliveryTime'));

	// Note: Use only the delivery date day and hours.
	// The timezone is the dealer's offset, but it's stored without a timezone.
	// This sets timezone to browser timezone for easy calculation of which is latest.
	// we'll then present it in a query as just day/hour
	// these ugly replaces make this safari mobile compatable without breaking other browsers
	commit('setDeliveryDate', max(flatten(times), time => new Date(time)));
};

export const getInventory = async ({ commit, dispatch }, { products, qty }) => { // eslint-disable-line complexity
	const productIDs = products.map(product => product.itemId);

	const uniqueIDs = [...new Set(productIDs)];

	const productMap = products.map(({ sku }, i) => ({
		sku,
		qty: qty[i]
	}));

	try {
		const { data: { itemSourcingResponse }, error } = await axios.post('/service/itemSourcingRequest', { products: uniqueIDs,
			qty });

		if (!itemSourcingResponse) {
			throw new Error(`No Items`);
		}

		const { items: inventoryResponse } = itemSourcingResponse;

		if (error) {
			throw new Error(`error: ${error}`);
		}
		// eslint-disable-next-line complexity
		if (!inventoryResponse) {
		// Logging occurs elseware
			throw new Error('No Inventory');
		}

		const warehouse = [];
		const warehouseList = [];
		let oos = false;

		// Get the shipment information (will be useful for the order created)
		inventoryResponse.forEach(item => {
			item.quantityLevels.forEach(qLevel => {
				const quantity = qLevel.maxQty;
				const shipmentInfo = find(qLevel.sourcingOptions, ({ hasBackOrder, isPartial }) => {
					return !hasBackOrder && !isPartial;
				});

				// Can't fill out shipment info if none of the warehouses can ship...this is not Out of Stock
				if (has(shipmentInfo, 'shipments')) {
					warehouseList.push(setWarehouseQty(shipmentInfo.shipments, quantity));
					warehouse.push({
						shipment: shipmentInfo.shipments,
						itemID: item.code
					});
				// Else the product is OOS so trigger our modal
				}
			});
		});
		// Need to make sure there are enough values returned to cover all the tires passed in
		if (warehouse.length && warehouse.length === qty.length && !oos) {
			dispatch('getEarliestGuaranteedTime', warehouse);
			commit('cart/setWarehouses', warehouseList, { root: true });

			return productMap;
		}
		commit('setDeliveryDate', '');
		commit('modal/toggleOOSModal', {}, { root: true });
		oos = true;

		return [];
	} catch (err) {
		commit('modal/toggleInventoryUnavailableModal', {}, { root: true });
		throw new Error('Inventory Unavailable');
	}

};

export const getWheelPackageInventory = async ({ commit, dispatch }, { products, qty }) => { // eslint-disable-line complexity
	const productIDs = products.map(product => product.itemId);

	const uniqueIDs = [...new Set(productIDs)];

	const productMap = products.map(({ sku }, i) => ({
		sku,
		qty: qty[i]
	}));

	const { data: { itemSourcingResponse }, error } = await axios.post('/service/itemSourcingRequest', { products: uniqueIDs,
		qty });

	if (!itemSourcingResponse) {
		throw new Error(`No Items`);
	}

	const { items: inventoryResponse } = itemSourcingResponse;

	if (error) {
		throw new Error(`error: ${error}`);
	}
	// eslint-disable-next-line complexity
	if (!inventoryResponse) {
		// Logging occurs elseware
		throw new Error('No Inventory');
	}

	const warehouse = [];
	const warehouseList = [];
	let oos = false;

	// Get the shipment information (will be useful for the order created)
	inventoryResponse.forEach(item => {
		item.quantityLevels.forEach(qLevel => {
			const quantity = qLevel.maxQty;
			const shipmentInfo = find(qLevel.sourcingOptions, ({ hasBackOrder, isPartial }) => {
				return !hasBackOrder && !isPartial;
			});

			// Can't fill out shipment info if none of the warehouses can ship...this is not Out of Stock
			if (has(shipmentInfo, 'shipments')) {
				warehouseList.push(setWarehouseQty(shipmentInfo.shipments, quantity));
				warehouse.push({
					shipment: shipmentInfo.shipments,
					itemID: item.code
				});
				// Else the product is OOS so trigger our modal
			}
		});
	});
	// Need to make sure there are enough values returned to cover all the tires passed in
	if (warehouse.length && warehouse.length === qty.length && !oos) {
		dispatch('getEarliestGuaranteedTime', warehouse);
		commit('cart/setWarehouses', warehouseList, { root: true });

		return productMap;
	}
	commit('setDeliveryDate', '');
	commit('modal/toggleOOSModal', {}, { root: true });
	oos = true;

	return [];
};
