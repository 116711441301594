import * as actions from './actions.js';

/**
 * Remove any service that is not an alignment or oil change
 * @param  {Array} services Data from dealerServiceOfferingsListRequest
 * @return {Array}          Filtered services
 */
const serviceReducer = services => {
	const allowedServices = [
		'oil change',
		'alignment',
		'permasafe',
		'nissan clean',
		'nissan shield',
		'infiniti clean',
		'infiniti shield',
		'mobile tire install'
	];

	if (services.length > 0) {
		return services.filter(service => {
			const title = service.title.toLowerCase();

			return service.isPromoted ? allowedServices.some(allowedService => title.indexOf(allowedService) >= 0) : null;

		});
	}

	return [];

};

/**
 * Adds .00 to a price if it doesn't have any cents
 * @param  {Array} services Data from dealerServiceOfferingsListRequest
 * @return {Array}          Services with fixed pricing
 */
const priceFix = services => {
	return services.map(service => {
		let price = service.price;

		if (price.length <= 2) {
			service.price = Number(price).toFixed(2);
		}

		return service;
	});
};


const store = {
	namespaced: true,
	state: {
		selectedTime: '',
		selectedTimeDealer: '',
		serviceOffers: [],
		selectedOffers: [],
		servicesInSession: []
	},
	// Mutations are commited and must be synchronous
	mutations: {
		setAvailableOfferings(state, payload) {
			state.serviceOffers = priceFix(serviceReducer(payload));
		},
		setSelectedServices(state, payload) {
			if (payload.length) {
				state.selectedOffers = priceFix(serviceReducer(payload));
			} else {
				state.selectedOffers = [];
			}
		},
		setServicesInSession(state, payload) {
			state.servicesInSession = payload;
		},
		selectedTime(state, payload) {
			state.selectedTime = payload;
		},
		selectedTimeDealer(state, payload) {
			state.selectedTimeDealer = payload;
		}
	},
	// Actions are dispatched, which then commit mutations. Can be asynchronous methods.
	actions
};

export default store;
