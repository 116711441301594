/* eslint-disable complexity */
export function calculateServicesCost(services) {
	if (!services || services.length <= 0) return 0;

	return services.reduce((totalServiceCost, currentService) => {
		return totalServiceCost + currentService.price;
	}, 0) || 0;
}

export function generateGlobalProductProperties(products) {
	if (!products || products.length <= 0) return {};

	return products.map((p) => {
		return {
			'brand': p.brand,
			'image_url': `${origin}/content/drsg/b_iso/${p.imageId}_b.jpg`,
			'product_id': p.productId,
			'sku': p.sku,
			'season': p.season,
			'original_equipment': Boolean(p.factoryInstalled),
			'size': p.size
		};
	});
}

export function generateGlobalProductPricing(products) {
	if (!products || products.length <= 0) return {};

	return products.map((p) => {
		return {
			'sku': p.sku,
			'product_id': p.productId,
			'qty': p.qty || null,
			'install_price': p.pricing.installPrice,
			'unit_install_price': p.pricing.unitInstallPrice,
			'unit_retail_price': p.pricing.unitRetailPrice,
			'retail_price': p.pricing.retailPrice,
			'savings': p.pricing.savings,
			'future_savings': p.pricing.futureSavings
		};
	});
}
