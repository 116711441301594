import * as actions from './actions.js';

const store = {
	namespaced: true,
	state: {
		consumerInfo: {},
		emailUserInput: '',
		lastNameUserInput: '',
		orderNumber: '',
		orderNumberToDisplay: '',
		resultsHaveBeenFound: false,
		showResults: false,
		userLanguage: ''
	},
	mutations: {
		setConsumerInfo(state, payload) {
			state.consumerInfo = payload;
		},
		setEmailUserInput(state, payload) {
			state.emailUserInput = payload;
		},
		setLastNameUserInput(state, payload) {
			state.lastNameUserInput = payload;
		},
		setOrderNumber(state, payload) {
			state.orderNumber = payload;
		},
		setOrderNumberToDisplay(state, payload) {
			state.orderNumberToDisplay = payload;
		},
		setResultsHaveBeenFound(state, payload) {
			state.resultsHaveBeenFound = payload;
		},
		setShowResults(state, payload) {
			state.showResults = payload;
		},
		setUserLanguage(state, payload) {
			state.userLanguage = payload;
		}
	},
	actions
};

export default store;
