<template>
    <aside class="appointmentServicePicker" v-if="servicesCurrentState" :class="'services-container'">
        <appointment-service-offering
            v-for="service in selected.serviceOfferings.filter(serv => !serv.transportID)"
            :service="service"
            :key="service.id"
            @input="updateAppointmentServices"
            v-model="selectedAppointmentServices"
            class="service-item"
            data-cy="transportOption"
        />
    </aside>
</template>

<script>
import { mapState } from 'vuex';
import AppointmentServiceOffering from '@/components/AppointmentServiceOffering';

export default {
	name: 'AppointmentServices',
	props: {
		query: {
			required: true,
			type: Object
		},
		areServicesOpen: {
			type: Boolean,
			required: true
		},
		timeChosen: {
			type: String,
			required: true
		},
		date: {
			type: Date,
			required: true
		},
		isMobile: {
			type: Boolean,
			required: true
		}
	},
	components: {
		AppointmentServiceOffering
	},
	data() {
		return {
			selectedAppointmentServices: [],
			servicesCurrentState: false
		};
	},
	computed: {
		...mapState('dealer', ['selected'])
	},
	methods: {
		updateAppointmentServices() {
			this.$emit('updateAppointmentServices', this.selectedAppointmentServices);
		}
	},
	watch: {
		areServicesOpen: {
			handler(val) {
				this.servicesCurrentState = val;
			},
			immediate: true
		}
	},
	created() {
		this.updateAppointmentServices();
	}
};
</script>
