import { get } from 'axios';
import ensureArray from '@/utils/ensure-array';
import path from '@/utils/path';

const articlePath = path([
	'contentDetailsResponse',
	'articles',
	'article'
]);

const contentBodyPath = path([
	'0',
	'articleContents',
	'articleContent',
	'contentBody'
]);

const store = {
	namespaced: true,
	state: {
		trivia: '',
		ad: '',
		headline: '',
		promo: '',
		dealerSpecialPromo: '',
		transportInformation: ''
		// mobileInstallContent1: '',
		// mobileInstallContent2: ''
	},
	mutations: {
		content(state, { prop, html }) {
			state[prop] = html;
		},
		resetTransportInfo(state, payload) {
			state.transportInformation = payload;
		}
	},
	actions: {
		cms({ commit, state }, { prop, slug, isUpdate }) {
			if (!state[prop] || isUpdate) {
				get(`/service/cms/${slug}`).then(({ data }) => {
					if (state[prop] !== data.html) {
						commit('content', {
							prop,
							html: data.html
						});
					}
				});
			}
		},
		doc({ commit, state }, { prop }) {
			if (!state[prop]) {
				get('/service/docs/', {
					params: {
						articleType: prop,
						path: '/app/schedule/',
						section: 'Right Turn White Label'
					}
				}).then(({ data }) => {
					const article = ensureArray(articlePath(data));
					const contentBody = contentBodyPath(article);

					if (contentBody) {
						commit('content', {
							prop,
							html: contentBody
						});
					}
				});
			}
		},
		fetchContent({ dispatch }, isUpdate) {

			dispatch('cms', {
				prop: 'ad',
				slug: 'schedule-ad',
				isUpdate
			});

			dispatch('cms', {
				prop: 'headline',
				slug: 'schedule-headline',
				isUpdate
			});
			dispatch('cms', {
				prop: 'transportInformation',
				slug: 'schedule-transport-information',
				isUpdate
			});
			dispatch('cms', {
				prop: 'trivia',
				slug: `schedule-trivia`,
				isUpdate
			});
			dispatch('cms', {
				prop: 'promo',
				slug: `schedule-trivia-promo`,
				isUpdate
			});
			dispatch('cms', {
				prop: 'dealerSpecialPromo',
				slug: 'schedule-special-promo',
				isUpdate
			});
			// Apr 2024: turning off mobile install content for now
			// dispatch('cms', {
			// 	prop: 'mobileInstallContent1',
			// 	slug: 'schedule-content-1',
			// 	isUpdate
			// });
			// dispatch('cms', {
			// 	prop: 'mobileInstallContent2',
			// 	slug: 'schedule-content-2',
			// 	isUpdate
			// });
			// dispatch('cms', {
			// 	prop: 'scheduleMobileModal',
			// 	slug: 'schedule-mobile-modal',
			// 	isUpdate
			// });
			// dispatch('cms', {
			// 	prop: 'scheduleMobileModalConfirmation',
			// 	slug: 'schedule-mobile-modal-confirmation',
			// 	isUpdate
			// });
			// dispatch('cms', {
			// 	prop: 'scheduleMobileUnAvailable',
			// 	slug: 'schedule-mobile-unavailable',
			// 	isUpdate
			// });
		}
	}
};

export default store;
