<template>
    <button
        @click="$emit('clicked')"
        type="button"
        role="tab"
        class="accordion-header"
        :class="{
            'accordion-open': componentCurrentState,
            'accordion-closed': !componentCurrentState,
            'accordion-disabled': !isActive
        }"
        :disabled="!isActive"
        :aria-disabled="!isActive ? 'true' : 'false'"
        :aria-selected="componentCurrentState"
    >
        <span class="accordion-header-tab">
            <span class="accordion-header-tab__title">{{ heading }}</span>
            <span class="accordion-header-tab__subtitle">{{ selectionText }}</span>
        </span>
        <font-awesome-icon
            v-if="isMobile"
            :icon="appointmentHeadingArrow"
            aria-hidden="false"
            focusable="false"
            size="lg"
            pull="right"
        />
    </button>
</template>

<script>
import { faAngleDown, faAngleUp, faCircleCheck } from '@fortawesome/free-solid-svg-icons';

export default {
	name: 'AppointmentHeading',
	props: {
		heading: {
			type: String,
			required: true
		},
		selectionText: {
			type: String,
			required: false,
			default: () => ''
		},
		componentCurrentState: {
			type: Boolean,
			required: true
		},
		selectionMade: {
			type: Boolean,
			required: true
		},
		isMobile: {
			type: Boolean,
			required: true
		},
		isActive: {
			type: Boolean,
			required: false
		}
	},
	data() {
		return {
			isOpen: false
		};
	},
	computed: {
		checkMark() {
			return faCircleCheck;
		},
		appointmentHeadingArrow() {
			if (this.componentCurrentState) {
				return faAngleUp;
			}

			return faAngleDown;
		}
	},
	watch: {
		isTypeOpen(bool) {
			this.isOpen = bool;
		},
		componentCurrentState(val) {
			this.isOpen = val;
		}
	}
};
</script>
