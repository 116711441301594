import { get } from 'axios';

const store = {
	namespaced: true,
	state: {
		recommendations: '',
		details: '',
		primary: '',
		secondary: '',
		ad: '',
		priceBreakdown: '',
		headline: ''
	},
	mutations: {
		content(state, { prop, html }) {
			state[prop] = html;
		}
	},
	actions: {
		// eslint-disable-next-line no-unused-vars
		cms({ commit, state }, { prop, slug, isUpdate }) {
			// isUpdate added to dispatches to trigger re-fetching of ALL content if language changes
			if (!state[prop] || isUpdate) {
				get(`/service/cms/${slug}`).then(({ data }) => {
					commit('content', {
						prop,
						html: data.html
					});
				});
			}
		},
		fetchContent({ dispatch }, isUpdate) {
			dispatch('cms', {
				prop: 'recommendations',
				slug: 'product-recommendations',
				isUpdate
			});

			dispatch('cms', {
				prop: 'primary',
				slug: 'product-1',
				isUpdate
			});

			dispatch('cms', {
				prop: 'secondary',
				slug: 'product-2',
				isUpdate
			});

			dispatch('cms', {
				prop: 'ad',
				slug: 'product-ad',
				isUpdate
			});

			dispatch('cms', {
				prop: 'priceBreakdown',
				slug: 'product-tile',
				isUpdate
			});

			dispatch('cms', {
				prop: 'packageBreakdown',
				slug: 'product-package-tile',
				isUpdate
			});

			dispatch('cms', {
				prop: 'headline',
				slug: 'product-headline',
				isUpdate
			});
			// Apr 2024: turning off mobile install content for now
			// dispatch('cms', {
			// 	prop: 'productTileMobileInstall',
			// 	slug: 'product-tile-mobile-install',
			// 	isUpdate
			// });
		}
	}
};

export default store;
