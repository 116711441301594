<template>
    <div
        :id="name"
        role="tabpanel"
        :class="['product-tabs__pane', {
            'product-tabs__pane--is-active': active,
            'product-tabs__pane--is-not-active': !active
    }]">
        <slot></slot>
    </div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			required: true
		},
		name: {
			type: String,
			required: true
		}
	},
	computed: {
		active() {
			const { $children, activated } = this.$parent;

			if ($children[activated]) {
				return $children[activated].name === this.name;
			}

			return false;
		}
	},
	mounted() {
		this.$parent.tabs.push(this);
	}
};
</script>
