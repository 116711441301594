<template>
    <MountingPortal mountTo="#dealerFooter">
        <div class="container-fluid footer-wrapper fs-unmask">
            <div class="footer-inner-wrapper">
                <footer class="row footer">
                    <div class="col-12 footer__column" v-html="footerHTML">
                    </div>
                </footer>
            </div>
        </div>
    </MountingPortal>
</template>

<script>
import axios from 'axios';

export default {
	name: 'DealerFooter',
	data() {
		return {
			footerHTML: ''
		};
	},
	methods: {
		async fetchFooter() {
			const footerContent = await axios.get('/service/cms/footer');

			this.footerHTML = footerContent.data;
		}
	},
	watch: {
		'$store.state.consumerInfo.userLanguage': function() {
			// true fed to fetchContent to trigger re-fetching of content if userLanguage is changed
			this.fetchFooter();
		}
	},
	created() {
		this.fetchFooter();
	}
};
</script>
