import { get } from 'axios';
import ensureArray from '@/utils/ensure-array';
import path from '@/utils/path';

const articlePath = path([
	'contentDetailsResponse',
	'articles',
	'article'
]);

const contentBodyPath = path([
	'0',
	'articleContents',
	'articleContent',
	'contentBody'
]);

const store = {
	namespaced: true,
	state: {
		details: '',
		oem: '',
		lbs: '',
		ad: '',
		advertisement: ''
	},
	mutations: {
		content(state, { prop, html }) {
			state[prop] = html;
		},
		resetAdvertisementContent(state) {
			state.advertisement = '';
		}
	},
	actions: {
		cms({ commit, state }, { prop, slug, isUpdate }) {
			if (!state[prop] || isUpdate) {
				get(`/service/cms/${slug}`).then(({ data }) => {
					commit('content', {
						prop,
						html: data.html
					});
				});
			}
		},
		doc({ commit, state }, { prop, brandCode }) {
			if (!state[prop]) {
				get('/service/docs/', {
					params: {
						articleType: prop,
						path: '/',
						productBrandCode: brandCode,
						section: 'Right Turn White Label'
					}
				}).then(({ data }) => {
					const article = ensureArray(articlePath(data));
					const contentBody = contentBodyPath(article);

					if (contentBody) {
						commit('content', {
							prop,
							html: contentBody
						});
					}
				});
			}
		},
		fetchContent({ dispatch }, isUpdate) {
			dispatch('cms', {
				prop: 'details',
				slug: 'detail-1',
				isUpdate
			});

			dispatch('cms', {
				prop: 'oem',
				slug: 'original-equipment-tire-details',
				isUpdate
			});

			dispatch('cms', {
				prop: 'lbs',
				slug: 'local-best-seller-tire-details',
				isUpdate
			});

			dispatch('cms', {
				prop: 'ad',
				slug: 'detail-ad',
				isUpdate
			});
		},
		fetchDocs({ dispatch }, brandCode ) {
			dispatch('doc', {
				prop: 'advertisement',
				brandCode: brandCode
			});
		}
	}
};

export default store;
