// this is the only option right now
const chatTypes = {
	live: 'Live'
};
const interactionTypes = {
	agent: 'Agent',
	customer: 'Customer'
};
const chatDefaultProperties = {
	'chat_type': chatTypes.live
};

export const RudderstackChatMixin = {
	methods: {
		trackChatInitiated() {
			this.$rudderstack.track('CHAT_INITIATED');
		},

		trackChatSessionStarted() {
			this.$rudderstack.track('CHAT_SESSION_STARTED', {
				...chatDefaultProperties
			});
		},

		trackChatSessionInteraction(interactionType) {
			this.$rudderstack.track('CHAT_SESSION_INTERACTION', {
				...chatDefaultProperties,
				'interaction_type': interactionType
			});
		},

		trackChatSessionClosed() {
			this.$rudderstack.track('CHAT_SESSION_CLOSED', {
				...chatDefaultProperties
			});
		}
	},

	created() {
		// Callback to fire when the help button is clicked.
		document.addEventListener('onHelpButtonClick', () => {
			this.trackChatInitiated();
		});

		// Fired when the chat request is successful.
		document.addEventListener('onChatRequestSuccess', () => {
			this.trackChatSessionStarted();
		});

		// Fired when the agent sends a message.
		document.addEventListener('onAgentMessage', () => {
			this.trackChatSessionInteraction(interactionTypes.agent);
		});

		// Fired when the chat visitor sends a message.
		document.addEventListener('onChasitorMessage', () => {
			this.trackChatSessionInteraction(interactionTypes.customer);
		});

		// Fired when Embedded Service Chat has ended and the application is closed.
		document.addEventListener('afterDestroy', () => {
			this.trackChatSessionClosed();
		});
	}
};
