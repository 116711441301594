<template>
    <section class="fs-unmask">
        <div v-if="isMobile" class="fixed-compare-header">
            <sticky-header />
        </div>

        <!-- TODO: Add to CMS Content / ADD TO MESSAGE.json -->
        <header class="header__page-heading">
            <h1>{{ $t("message.compareYourTires") }}</h1>
        </header>

        <modal-out-of-stock />

        <!-- moving results and coach out of the compare table -->
        <div>
            <!-- BACK TO RESULTS LINK -->
            <div class="compare__back">
                <div tabindex="0">
                    <a class="compare__back-link" @click="goToProduct" :title="$t('message.backToResults')">
                        <span>
                            {{ $t("message.backToResults") }}
                        </span>
                    </a>
                </div>
            </div>

            <coach />
        </div>

        <div class="compare" role="grid" tabindex="0" :aria-label="$t('message.compareTireSpecs')">

            <!-- TABLE HEADER (IMAGES, RATINGS, SHOPBLOCK, ETC.) -->
            <desktop-header
                :isMobile="isMobile"
                :selectedDealer="selectedDealer"
                :minimumQtyToCompare="minimumQtyToCompare"
            />

            <!-- SPEC TITLE ROWS AND SPEC VALUES -->
            <spec-row
                v-for="spec in specsToCompare"
                :key="spec"
                :spec="spec"
                :numberOfBlankCols="numberOfBlankCols"
                :specValues="specValues(spec, productSetsToCompare)"
                :highlightRow="highlightRow(spec, productSetsToCompare)"
                :hideRow="hideRow(spec, productSetsToCompare)"
                :productSetsToCompare="productSetsToCompare">
            </spec-row>
        </div>

        <!-- FINANCE DISCLAIMER -->
        <div v-if="selectedDealer.hasFinancingOTS && selectedDealer.financePartnerCode">
            <p class="finance-disclaimer" v-html="estimatedPaymentDisclaimer"></p>
        </div>
    </section>
</template>

<script>
import Coach from '@/components/CompareCoach.vue';
import SpecRow from '@/components/CompareSpecRow.vue';
import DesktopHeader from '@/components/CompareHeaderDesktop.vue';
import StickyHeader from '@/components/CompareHeaderSticky.vue';
import ModalOutOfStock from '@/components/ModalOutOfStock.vue';
import { fullTireMarkDescription } from '@/filters/oem-tire-mark';
import { structuredProductQuery } from '@/utils/product-query-builder';
import { comparePageImpression, noDataEvent } from '@/utils/setDataLayer';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { every, isEmpty, map } from 'underscore';
import { RudderstackChatMixin } from '@/rudderstack/track/mixins/chat';

export default {
	title: 'Compare',

	props: {
		query: {
			type: Object,
			required: true
		}
	},

	mixins: [RudderstackChatMixin],

	components: {
		Coach,
		SpecRow,
		DesktopHeader,
		ModalOutOfStock,
		StickyHeader
	},

	data() {
		return {
			windowWidth: window.innerWidth
		};
	},
	watch: {
		'productSetsToCompare': {
			handler() {
				if (this.getPowerReviews.enabled) {
					this.setAllProductReviews({ productIDs: this.getModelIds(this.productSetsToCompare).join(',') });
				}
			}
		}
	},
	computed: {
		...mapState('dealer', {
			selectedDealer: 'selected'
		}),
		...mapState('products', [
			'allProducts',
			'defaultSpecs',
			'hideSame',
			'isStaggered',
			'noTires',
			'productSetsToCompare',
			'showDiff',
			'singleProductSetToCompare',
			'defaultQty',
			'qtySelected',
			'specsToCompare'
		]),
		...mapState('initial', ['title']),
		...mapState('productResults', ['productReviews']),
		...mapState('search', ['dealership_id']),
		...mapGetters('initial', ['getPowerReviews']),

		newPageTitle() {
			// eslint-disable-next-line prefer-template
			const page = this.$t('message.comparePage') + ' | ' + this.title;

			return page;
		},
		financingDisclaimer() {
			if (this.selectedDealer.financePartner && this.selectedDealer.financePartner.code) {
				return this.selectedDealer.financePartner;
			}

			return {};
		},
		estimatedPaymentDisclaimer() {
			if (!this.selectedDealer.financePartner) {
				return null;
			}

			return this.selectedDealer.financePartner.estimatedPaymentDisclaimer;
		},
		isMobile() {
			return this.windowWidth <= 768;
		},
		minimumQtyToCompare() {
			return this.productSetsToCompare.length < 3;
		},
		numberOfBlankCols() {
			let count = 3;

			this.productSetsToCompare.forEach(function () {
				count--;
			});

			return count;
		},
		tireQuantity() {
			let counts = this.defaultQty;

			if (!isEmpty(this.qtySelected)) {
				counts = this.qtySelected;
			}

			return counts.reduce((total, q) => total + q, 0);
		}
	},
	methods: {
		...mapActions('products', [
			'getAllProducts',
			'filterCompareItemsFromAllProducts',
			'setCompareProducts'
		]),
		...mapActions('productResults', ['setAllProductReviews']),
		...mapActions('dealer', [
			'select'
		]),
		...mapMutations('products', [
			'setHideSame',
			'setShowDiff',
			'setSpecsToCompare'
		]),

		// Collects all promos for the tires (1 for square, 2 for staggered) in this set
		// Empty if none apply
		allPromosForAllTiresInASet(singleSet) {
			let tempPromos = [{}];

			if (singleSet.promotions) {
				tempPromos = singleSet.promotions.filter(function (p) {
					return p.applied;
				});
			}
			let promoSpecs = map(tempPromos, function (promo) {
				// add an empty object for each tire, if there are no promos
				if (!promo.title) {
					return promo;
				}
				// otherwise remove unneeded fields

				return {
					title: promo.title,
					description: promo.description,
					applied: promo.applied
				};
			});

			return promoSpecs;
		},
		// Maps only the titles ands descriptions of each promo
		allPromoTitlesAndDescriptions(productSet) {
			let promoSets = [];

			productSet.forEach(singleSet => {
				promoSets.push(this.allPromosForAllTiresInASet(singleSet));
			});

			return promoSets;
		},
		compareSpec(spec, productSet) {
			const values = this.specValues(spec, productSet);

			// Iterate through each spec to determine equivalence
			// eslint-disable-next-line complexity
			return every(values, function (value) {

				// We need special logic for financing spec comparison because of how specValues creates its object
				// It contains product data that breaks financing comparison, otherwise.

				// If the spec being compared is financing & the dealer offers financing (including Toyota CCs)
				// financing will include an installmentTagLine (ITL).  This is the info visible to the user in the Financing
				// viewport (with the "Learn More" drop down in its default collapsed state) on the compare page
				// This is the information we compare for financing specs in this case
				if (spec === 'financing' && values[0].productSet.financing) {
					// If there are ITLs, compare those
					return values[0].productSet.financing.installmentTagLine ?
						JSON.stringify(value.productSet.financing.installmentTagLine) ===
						JSON.stringify(values[0].productSet.financing.installmentTagLine) : true;
					// If there are no ITLs, we assume "No Financing" for each product
				} else if (spec === 'financing') {
					return true;
				}

				// ALL other specs can compare their provided data without needing to be massaged
				return JSON.stringify(value) === JSON.stringify(values[0]);
			});
		},
		fullTireMark(productSet) {
			return map(productSet, function (singleSet) {
				return fullTireMarkDescription(singleSet.products[0]);
			});
		},

		goToProduct() {
			noDataEvent('comparePageBackToResults');

			this.$router.push({
				path: '/app/product',
				query: this.query
			});
		},
		hideRow(spec, productSet) {

			if (spec === 'reviews') {
				return !Object.keys(this.productReviews).length;
			}

			// hideSame boolean is toggled when "Hide Similar Items" is clicked on Compare page,
			if (this.hideSame) {
				return this.compareSpec(spec, productSet);
			}

			return false;
		},
		highlightRow(spec, productSet) {

			// showDiff operates in much the same way as hideSame above with one key difference
			// Note the use of "!" on compareSpec in showDiff
			if (this.showDiff) {
				return !this.compareSpec(spec, productSet);
			}

			return false;
		},
		getModelIds(productSet) {
			return map(productSet, function (singleSet) {
				return singleSet.products[0].modelID;
			});
		},
		sizeValues(productSet) {
			if (this.isStaggered) {
				return map(productSet, function (singleSet) {
					return [singleSet.products[0].size, singleSet.products[1].size];
				});
			}

			return map(productSet, function (singleSet) {
				return singleSet.products[0].size;
			});
		},
		specValues(spec, productSet) { // eslint-disable-line complexity
			if (spec === 'size') {
				return this.sizeValues(productSet);
			}
			if (spec === 'promos') {
				return this.allPromoTitlesAndDescriptions(productSet);
			}
			if (spec === 'reviews') {
				return this.getModelIds(productSet);
			}
			if (spec === 'financing') {
				let tq = this.tireQuantity;
				let fd = this.financingDisclaimer;

				return map(productSet, function (singleSet) {
					return {
						productSet: singleSet,
						tireQuantity: tq,
						financingDisclaimer: fd
					};
				});
			}

			if (spec === 'oemTireMarkDescription') {
				return this.fullTireMark(productSet);
			}

			return map(productSet, function (singleSet) {
				return singleSet.products[0][`${spec}`];
			});
		},
		updateWindowWidth() {
			this.windowWidth = window.innerWidth;
		},

		/**
		 * @returns {undefined|Object} Return first product found in allProducts or undefined
		 */
		checkQueryIdsAreInAllProducts() {
			// query can be an array or a string (if only 1 id is passed)
			// if staggered - "123,456" (each item in array)
			const isQueryArray = Array.isArray(this.query.id);

			const staggeredOrLooseQueryIds = this.query.id.reduce((acc, curr) => {
				// ex: '123,456' -> ['123', '456']
				const idsAsArray = curr.split(',');

				acc.push(...idsAsArray);

				return acc;
			}, []);

			return this.allProducts.find((item) => {
				const { productId } = item.products[0];

				if (isQueryArray) {
					return staggeredOrLooseQueryIds.includes(productId);
				}

				return this.query.id === productId;
			});
		}
	},
	created() { // eslint-disable-line complexity
		this.setSpecsToCompare(this.defaultSpecs);

		if (isEmpty(this.selectedDealer)) {
			const { uid, make } = this.query;

			this.select({
				uid,
				make
			});
		}

		if (isEmpty(this.productSetsToCompare) || isEmpty(this.allProducts)) {
			this.getAllProducts(structuredProductQuery(this.query))
				.then(() => {
					if (this.noTires) {
						this.$router.push({
							path: '/app/no-products',
							query: this.query
						});
					}

					/**
					 * DRSG may send over products (query `id`) that aren't allowed on OTS.
					 * If no matching query id's in the allProducts array - redirect to the 500 page
					 * We just need 1 product to display the page.
					 */
					if (!this.checkQueryIdsAreInAllProducts()) {
						this.$router.push({
							path: '/error/500',
							query: this.query
						});
					}

					this.setCompareProducts(this.query.id);

					if (this.getPowerReviews.enabled) {
						this.setAllProductReviews({ productIDs: this.getModelIds(this.productSetsToCompare).join(',') });
					}
					comparePageImpression(this.productSetsToCompare);
				})
				.catch((err) => {
					this.$router.push({
						path: '/error/500',
						query: this.query
					});
					throw new Error(err);
				});
		} else {
			this.filterCompareItemsFromAllProducts();
			comparePageImpression(this.productSetsToCompare);
		}

		this.$rudderstack.page(this.$route.meta.pageCategory, this.$route.meta.pageCategory, {
			...this.$route.query,
			'dealership_id': this.dealership_id
		});
	},
	mounted() {
		if (window.dataLayer) {
			window.dataLayer.push({ event: 'optimize.activate' });
		}


		window.addEventListener('resize', this.updateWindowWidth);

		document.title = this.newPageTitle;

		// Using Intersection Observer to detect when Compare Row Header
		// is no longer in view, then apply 'is-fixed' class to new hidden
		// header with fewer product details to increase viewport visability
		// for users using devices zoomed to 200%
		const fixed = document.querySelector('.fixed-compare-header');
		const trigger = document.querySelector('.compare-row__header');

		const handler = (entries) => {
			if (!entries[0].isIntersecting) {
				fixed.classList.add('is-fixed');
			} else {
				fixed.classList.remove('is-fixed');
			}
		};

		const observer = new window.IntersectionObserver(handler);

		observer.observe(trigger);

	}
};
</script>
