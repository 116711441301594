import * as actions from './actions.js';
import _ from 'underscore';

const store = {
	namespaced: true,
	state: {
		year: 0,
		make: '',
		model: '',
		trim: '',
		option: '',
		years: [],
		makes: [],
		models: [],
		trims: [],
		options: [],
		tireSizes: [],
		tireSizeMakes: []
	},
	getters: {
		sortYears: state => state.years.sort().reverse(),
		sortMakes: state => state.makes.sort(),
		sortModels: state => state.models.sort(),
		sortTrims: state => state.trims.sort(),
		uniqWidth: state => _.unique(state.tireSizes, x => x.width),
		tireSizes: state => state.tireSizes,
		tireSizeMakes: state => state.tireSizeMakes.sort(),
		optionsMap: state => {
			if (!state.options.length) {
				return [];
			}

			const [head] = state.options;

			// It's entirely possible that a list of options return where the first one is "".
			// Return all only if there are no other options to choose from (our data is awesome like that)
			if (state.options.length === 1 && _.isEmpty(head)) {
				return ['All'];
			}

			return state.options.sort();
		}
	},
	// Mutations are commited and must be synchronous
	mutations: {
		carlineState(state, payload) {
			_.extend(state, payload);
		},
		carlineYears(state, payload) {
			state.years = payload;
		},
		carlineMakes(state, payload) {
			state.makes = payload;
		},
		carlineModels(state, payload) {
			state.models = payload;
		},
		carlineTrims(state, payload) {
			state.trims = payload;
		},
		carlineOptions(state, payload) {
			state.options = payload;
		},
		make(state, payload) {
			state.make = payload;
		},
		year(state, payload) {
			state.year = payload;
		},
		model(state, payload) {
			state.model = payload;
		},
		trim(state, payload) {
			state.trim = payload;
		},
		option(state, payload) {
			state.option = payload;
		},
		tireSizes(state, payload) {
			state.tireSizes = payload;
		},
		tireSizeMakes(state, payload) {
			state.tireSizeMakes = payload;
		}
	},
	// Actions are dispatched, which then commit mutations. Can be asynchronous methods.
	actions
};

export default store;
