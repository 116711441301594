import * as actions from './actions.js';

const store = {
	namespaced: true,
	state: {
		appointment: {
			type: {
				name: String(''),
				id: Number(0)
			},
			date: String(''),
			time: {
				offset: 0,
				apptDateTime: String(''),
				blockStartTime: String(''),
				appointmentDate: String(''),
				appointmentTime: String(''),
				blockDurationMinutes: Number(0)
			},
			services: Array,
			provider: String(''),
			mobileInstallZip: String(''),
			comments: String('')
		},
		mobileInstallationID: Number(13),
		isSubmittingAppointment: false
	},
	getters: {
		isMobileInstallation: state => state.appointment.type.id === state.mobileInstallationID,
		mobileInstallationID: state => state.mobileInstallationID
	},
	// Mutations are commited and must be synchronous
	mutations: {
		setAppointment(state, payload) {
			state.appointment = payload;
		},
		setMobileInstallZip(state, payload) {
			state.mobileInstallZip = payload;
		},
		setConsumerComments(state, payload) {
			state.appointmentconsumerComments = payload;
		},
		setSelectedServices(state, payload) {
			state.services = payload;
		},
		setIsSubmittingAppointment(state, payload) {
			state.isSubmittingAppointment = payload;
		}
	},
	// Actions are dispatched, which then commit mutations. Can be asynchronous methods.
	actions
};

export default store;
