<template>

    <article>
        <!-- Dealer Name -->
        <h3 class="dealer-list__name">
            <a
                @click="$emit('dealerSelected', dealer)"
                class="dealer-list__name-link"
                href="#"
            >
                {{ dealer.dealershipname }}
            </a>
        </h3>
        <!-- CTA Button -->
        <div class="dealer-list__select-btn">
            <stateful-button
                role="button"
                :isLoading="isButtonLoadingSpinnerShowing"
                :aria-label="`Choose ${dealer.dealershipname}`"
                @statefulButtonClicked="handleStatefulButtonClick"
                type="button"
                class="button button--primary button--full"
                data-cy="dealerSelectButton"
            >
                {{ $t("message.choose") }}
            </stateful-button>
        </div>
        <!-- </div> -->

        <!-- Dealer Info -->
        <div class="dealer-list__dealer-info">
            <!-- Address -->
            <div class="dealer-list__info-wrapper">
                <p class="dealer-list__address-line-1">
                    {{ dealer.address }}
                </p>
                <p class="dealer-list__address-line-2">
                    {{ dealer.city }}, {{ dealer.state }}
                    {{ dealer.zip }}
                </p>
            </div>
            <!-- Distance -->
            <h4 class="dealer-list__distance-title">
                {{ $t("message.distance") }}
            </h4>
            <div class="dealer-list__distance">{{ Number(this.dealerDistance).toFixed(1) }} {{ unitOfMeasure }}</div>
            <!-- Hours -->
            <h4 class="dealer-list__hours-title">{{ $t("message.hours") }}</h4>
            <ul class="dealer-list__hours-list">
                <li v-for="(time, key) in setHours(dealer.hoursOfOperation)" :key="key">
                    {{ setDays(time) }}: {{ key }}
                </li>
            </ul>
        </div>

        <!-- Dealer Amenities -->
        <div
            v-if="dealer.amenities && dealer.amenities.length"
            class="dealer-list__amenities-select"
        >
            <!-- Amenities -->
            <div class="dealer-list__amenities">
                <template>
                    <h4 class="dealer-list__amenities-titles">
                        {{ $t("message.amenities") }}
                    </h4>
                    <ul class="dealer-list__amenities-list">
                        <li
                            class="dealer-list__amenities-item"
                            v-for="(amenity, specIndex) in dealer.amenities"
                            :key="specIndex"
                        >
                            {{ amenity.name }}
                        </li>
                    </ul>
                </template>
            </div>
            <div v-if="dealer.financePartnerCode && dealer.hasFinancingOTS" class="dealer-list__financing">
                <template>
                    <h4 class="dealer-list__financing-titles">
                        {{ $t("message.financing") }}:
                    </h4>
                    <!-- disclaimerText2 is only used by CCs -->
                    <!-- Non-CC Finance Partner - Requires Logo -->
                    <div v-if="!dealer.financePartner.disclaimerText2">
                        <p>
                            {{ $t("message.availableThrough") }}
                            {{ dealer.financePartner.description
                            }}<sup>{{ footnoteMap[dealer.financePartnerCode] }}</sup>
                        </p>
                        <img
                            :src="`/dist/assets/finance/${dealer.financePartnerCode.toLowerCase()}_logo.png`"
                            :alt="this.financeAltText"
                        />
                    </div>
                    <!-- CC Finance Partner - No Logo -->
                    <div v-else>
                        <p>
                            {{ $t("message.availableThrough") }}
                            {{ dealer.financePartner.disclaimerText2
                            }}<sup>{{ footnoteMap[dealer.financePartnerCode] }}</sup>
                        </p>
                    </div>
                </template>
            </div>
        </div>
    </article>

</template>

<script>
import { mapActions, mapState } from 'vuex';
import { map, groupBy } from 'underscore';
import StatefulButton from '@/components/StatefulButton.vue';

export default {
	props: {
		dealer: {},
		index: 0,
		footnoteMap: {}
	},

	components: {
		StatefulButton
	},

	data() {
		return {
			// We're using the store to check if the data is loading,
			// but we're in a v-for and need to keep track of which button was clicked.
			isDealerTileLoading: false
		};
	},

	computed: {
		...mapState('products/verify', ['isLoading']),
		...mapState('dealer', ['zipCode', 'loading']),

		financeAltText() {
			return `${this.$t('message.financing')} ${this.$t('message.availableThrough') } ${ this.dealer.financePartner.description}`;
		},
		isNextPageDataLoading() {
			// Check if either the dealer or products/verify is loading from the store.
			return (this.loading || this.isLoading);
		},
		isButtonLoadingSpinnerShowing() {
			return this.isNextPageDataLoading && this.isDealerTileLoading;
		},
		country () {
			// TODO: This is being used as part of the Band-Aid fixes below.
			// It can be removed when dealerDistance() and unitOfMeasure() get axed.

			// eslint-disable-next-line no-unused-vars
			const [language, country] = this.$i18n.locale.split('-');

			return country;
		},
		dealerDistance () {
			// TODO: Band-aid that will have to be removed when time permits.
			// For now, this is how we convert mi. to km when needed for Canadian consumption.
			let distance = this.dealer.distance;

			if (this.country !== 'us') {
				distance = distance * 1.609344;
			}

			return distance;
		},
		unitOfMeasure () {
			// TODO: Band-aid that will have to be removed when time permits.
			// For now, this is how we determine unit of measure based on locale.
			if (this.country === 'us') {
				return this.$t('message.milesAbbrev');
			}

			return this.$t('message.kilometersAbbrev');
		}
	},

	methods: {
		...mapActions('dealer', ['allDetails', 'select']),

		setDays: function (arr) {
			const daysOfWeek = [this.$t('message.sun'), this.$t('message.mon'), this.$t('message.tues'), this.$t('message.wed'), this.$t('message.thurs'), this.$t('message.fri'), this.$t('message.sat')];

			return map(arr, function (x) {
				return daysOfWeek[x.dayOfWeekId];
			}).join('/');
		},
		setHours: function (hours) {
			return groupBy(hours, (day) => { //eslint-disable-line

				if (day.isClosed) {
					return this.$t('message.closed');
				}
				// Canada uses the 24 hour clock
				if (this.dealer.country === 'ca') {
					return `${day.openTime} - ${day.closeTime}`;
				}

				const startTime = day.openTime.charAt(0) === '0' ? day.openTime.slice(1) : day.openTime;
				const startTimeHoursAndMinutes = startTime.split(':');
				const endTimeHoursAndMinutes = day.closeTime.split(':');
				// If dealer opens at noon or later, set to PM
				const start = parseInt(startTimeHoursAndMinutes[0]) >= 12 ? `${startTime}pm` : `${startTime}am`; //eslint-disable-line
				const end = (parseInt(endTimeHoursAndMinutes[0]) - 12) >= 0 ? `${parseInt(endTimeHoursAndMinutes[0]) - 12}:${endTimeHoursAndMinutes[1]}pm` : ` ${day.closeTime}am`; //eslint-disable-line

				return `${start} - ${end}`;
			});
		},
		handleStatefulButtonClick: function() {
			this.isDealerTileLoading = true;

			this.$emit('dealerSelected', this.dealer);
		}
	}
};
</script>
