/* eslint-disable complexity */
import axios from 'axios';
// import taxRatesMI from './miPilotTaxRates';

// SMI-JANK Until tax service takes a whole address, make a
// Promise to return taxes for MI. It's gross. It's ugly. It works great.
// If we could feed in an address, the regulartax service would tell us tax at the customer address
// as-is, it assumes point-of-sale is the dealer, which could be wrong.

// const taxRequestMI = (taxArr, zip) => {

// 	return new Promise(resolve => {
// 		// Calculate parts total from the array.
// 		let partsTotal = taxArr.reduce((acc, data) => {
// 			if (data.taxType === 'parts') {
// 				return acc + (data.unitprice * data.quantity);
// 			}

// 			return acc;
// 		}, 0);

// 		// lookup tax rate
// 		let rate = {'rate': 0,
// 			'multiCounty': true};

// 		if (taxRatesMI[zip] !== {}) {
// 			rate = taxRatesMI[zip];
// 		}

// 		// prepare an object similar to the one cybersource returns.
// 		let totalTaxAmt = (partsTotal * rate.rate).toFixed(2);
// 		// NC state tax is 4.75. Leads is expecting a breakdown, so calculate that, and subtract so pennies line up
// 		let stateTaxAmt = (partsTotal * 0.0475).toFixed(2);
// 		// County tax is the remainder
// 		let countyTaxPrc = ((rate.rate - 0.0475) * 100).toFixed(2);
// 		let countyTaxAmt = (totalTaxAmt - stateTaxAmt).toFixed(2);

// 		let taxData = {
// 			totalCityTaxAmount: '0.00',
// 			totalCityTaxPercent: '0.00',
// 			totalCountyTaxAmount: countyTaxAmt,
// 			totalCountyTaxPercent: countyTaxPrc,
// 			totalStateTaxAmount: stateTaxAmt,
// 			totalStateTaxPercent: '4.75',
// 			totalTaxAmount: totalTaxAmt,
// 			multiCounty: rate.multiCounty
// 		};

// 		resolve({'data': taxData});
// 	});
// };

// 'END' SMI-JANK

export const getProductTaxInfo = ({ commit }, { product, service: services }) => {
	const productPrices = product.products.map(prod => prod.pricing.installPriceAfterSavings);

	const productTotals = productPrices.reduce((total, price) => {
		return total + price;
	});

	const servicesTotal = services.length
		? services.map(serv => serv.price).reduce((total, price) => total + price)
		: 0;

	const grandTotalAmount = (productTotals + servicesTotal).toFixed(2);
	const subTotalAmount = (productTotals + servicesTotal).toFixed(2);


	// Step 2: Call tax service.
	// Here's the default object. It has zero taxes, with the pre-tax total filled out.
	// If taxes errors, that's okay, taxes are estimated, this will show the pre-tax price
	let taxObj = {
		supplementalChargesAmount: 0,
		subTotalAmount,
		grandTotalAmount,
		taxRateParts: 0,
		taxRateLabor: 0,
		taxes: {
			state: {
				description: '',
				percentage: 0,
				amount: 0
			},
			city: {
				description: '',
				percentage: 0,
				amount: 0
			},
			county: {
				description: '',
				percentage: 0,
				amount: 0
			}
		}
	};

	// SMI-JANK - use payload to determine if we need to use the janky promise created at the top of the file.
	// If we could feed in an address, the tax service would tell us tax at the customer address.
	// As-is, it assumes point-of-sale is the dealer, which could be wrong. Thus we jank.

	// if (payload.mobileInstallationZip && payload.mobileInstallationZip !== '') {
	// 	taxService = taxRequestMI(taxArr, payload.mobileInstallationZip);
	// } else {
	// }
	// to undo, replace the taxservice on this next line with the axios.post('/service/taxrequest'), above
	// SMI-JANK - END -
	const productPayload = {
		products: product.products.map(prod => {
			return {
				pricing: prod.pricing,
				qty: prod.qty,
				programCategory: prod.programCategory
			};
		})
	};

	axios.post('/service/taxrequest', {product: productPayload,
		services})
		.then(({ data }) => {
			taxObj = data;
		}).catch(err => {

			// do nothing. We'll submit a zero-tax object in case of errors.
			// the tax request service will log its error with greylog
			// this will show in the console
			return err;

		}).finally(function() {
			// Save the taxes to order payment. A watcher on the component will emit when it sees this is done.
			commit('cart/order/orderPayment', taxObj, { root: true });
		});

};
