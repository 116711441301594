<template>
    <div class="col-12 OTS_MessageBox">
        <div class="OTS_MessageBox_wrapper" >
            <div class="OTS_MessageBox_headersection" >
                <img v-if="messageIcon" :src="messageIcon" :alt="messageIconAlt" class="OTS_MessageBox_icon" />
                <h5 v-if="headerText" class="OTS_MessageBox_heading" >
                    {{ headerText }}
                </h5>
                <div v-if="messageText" class="OTS_MessageBox_subtext" v-html="messageText"></div>
            </div>

            <button v-if="ctaText" class="button OTS_MessageBox_CTA" @click="handleButtonClick" >
                <span >
                    {{ ctaText }}
                </span>
            </button>
        </div>
    </div>
</template>

<script>
export default {
	name: 'MessageBox',
	props: {
		ctaText: {
			type: String,
			default: '',
			required: false
		},
		messageIcon: {
			type: [String, Function],
			default: '',
			required: false
		},
		messageIconAlt: {
			type: String,
			default: '',
			required: false
		},
		headerText: {
			type: String,
			default: '',
			required: false
		},
		messageText: {
			type: String,
			default: '',
			required: false
		}
	},
	data() {
		return {
		};
	},
	methods: {
		handleButtonClick: function() {
			this.$emit('messageBoxButtonClicked');
		}

	}
};
</script>
