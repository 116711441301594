import Language from '../Language';

export default new Language(
	'Estonian',
	['Jaanuar', 'Veebruar', 'Märts', 'Aprill', 'Mai', 'Juuni', 'Juuli', 'August', 'September', 'Oktoober', 'November', 'Detsember'],
	['Jaan', 'Veebr', 'Märts', 'Apr', 'Mai', 'Juuni', 'Juuli', 'Aug', 'Sept', 'Okt', 'Nov', 'Dets'],
	['P', 'E', 'T', 'K', 'N', 'R', 'L']
)
// eslint-disable-next-line
;
