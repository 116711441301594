<template>
    <div class="compare-table__spec">
        <div v-if="!isMobileInstallAvailable">
            {{ $t('message.noMobileInstall') }}
        </div>

        <div v-else class="compare-row__mi">
            <button tabindex="-1" class="compare-row__mi-button"
                    @click="toggleShow()"
                    aria-label="Show Mobile Install Description">

                <span class="compare-row__mi-link">
                    <span class="compare-row__mi-linktext">
                        {{ $t("message.eligible") }}
                    </span>
                    <i class="icon icon--angle-down compare__arrow"
                       :class="{ 'compare__arrow--active': showDescription }"/>
                </span>
            </button>
            <div v-show="showDescription"
                 class="compare-row__mi-text">
                {{ $t("message.mobileInstallDescription_1") }}
                {{ selected.dealershipname }}
                {{ $t("message.mobileInstallDescription_2") }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
	props: {
		isMobileInstallAvailable: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			showDescription: false
		};
	},

	computed: {
		...mapState('products', [
			'productSetsToCompare',
			'singleProductSetToCompare'
		]),
		...mapState('dealer', ['selected'])
	},
	watch: {
		productSetsToCompare() {
			this.showDescription = false;
		},
		singleProductSetToCompare() {
			this.showDescription = false;
		}
	},
	methods: {
		toggleShow() {
			this.showDescription = !this.showDescription;
		}
	}
};
</script>
