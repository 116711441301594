import Language from '../Language';

export default new Language(
	'Swedish',
	['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December'],
	['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
	['Sön', 'Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör']
)
// eslint-disable-next-line
;
