import Language from '../Language';

export default new Language(
	'Indonesian',
	['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'],
	['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des'],
	['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab']
)
// eslint-disable-next-line
;
