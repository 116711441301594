<template>
    <div class="navigation__container"
         :class="isSticky && $windowSizeMixinWindowWidthIsMobile ? 'navigation__container--sticky': ''">
        <button
            class="navigation__link--active"
            type="button"
            @click="goBack()"
            v-show="navigateBackTo"
            v-html="navigateBackTo" />
        <span class="navigation__title">  {{ currentPageTitle }}</span>
        <button
            :class=" forwardButtonActive ? 'navigation__link--active': 'navigation__link'"
            type="button"
            @click="goForward()"
            :disabled="!forwardButtonActive"
            v-show="navigateForwardTo"
            v-html="navigateForwardTo"/>
    </div>

</template>

<script>

import { windowSizeMixin } from '@/mixins/windowSizeMixin';

export default {
	props: {
		navigateBackTo: {
			type: String
		},
		navigateForwardTo: {
			type: String
		},
		currentPageTitle: {
			required: true,
			type: String
		},
		forwardButtonActive: {
			required: true,
			type: Boolean
		},
		query: {
			required: true,
			type: Object
		}
	},
	mixins: [windowSizeMixin],
	data() {
		return {
			screenSize: 0,
			offsetTop: 100000,
			scrollY: 0,
			isSticky: false
		};
	},
	methods: {
		goBack() {
			this.$emit('goBack');
		},
		goForward() {
			this.$emit('goForward');
		},
		handleScroll() {
			if (window.scrollY >= this.offsetTop) {
				this.isSticky = true;
			} else {
				this.isSticky = false;
			}
		}
	},
	mounted() {
		const navBar = document.querySelector('.navigation__container');

		window.addEventListener('scroll', this.handleScroll, null);

		this.offsetTop = navBar.offsetTop;
	}
};
</script>
