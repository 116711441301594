import Language from '../Language';

export default new Language(
	'Spanish',
	['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
	['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
	['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb']
)
// eslint-disable-next-line
;
