const _ = require('underscore');

module.exports = item => {
	if (_.isUndefined(item)) {
		return [];
	}

	if (_.isArray(item)) {
		return item;
	}

	return [item];
};
