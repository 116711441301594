import prettyLgNum from '@/filters/pretty-lg-numbers/index.js';
import { vm } from '@/index.js';
// These are all the proper titles for specifications across our app
// Use when needed to translate data properties like ecoTire into readable titles like "Fuel Efficient"
const accessibilitySpecKeysAndTitles = () => {
	return {
		season: 'Seasonality',
		ecoTire: 'Fuel Efficient',
		warrantyMiles: 'Mileage Warranty',
		performanceType: 'Tire Category',
		isRunflat: 'Run-Flat',
		gradeTreadWear: 'Treadwear',
		speedRating: 'Speed Rating',
		tractionGrade: 'Traction Grade',
		treadDepth: 'Tread Depth',
		sidewall: 'Sidewall',
		factoryInstalled: 'Original Equipment Tire'
	};
};

const renameSpecTitlesForCompare = ( spec ) => {
	const titles = {
		financing: 'Financing',
		promos: 'Promotions',
		season: 'Seasonality',
		factoryInstalled: 'Original Equipment Tire - Tire originally installed on vehicle',
		oemTireMarkDescription: 'Vehicle Manufacturer-Approved Tires – ' +
			'Tires that meet the highest performance standards',
		ecoTire: 'Fuel Efficient',
		warrantyMilesNumeric: 'Manufacturer Treadwear Warranty',
		warrantyYears: 'Warranty Against Manufacturer Defect',
		roadHazardFlag: 'Road Hazard Coverage',
		performanceType: 'Tire Category',
		isRunflat: 'Run-Flat - Enables driving at reduced speeds and limited distances with a punctured tire',
		gradeTreadWear: 'Treadwear - Higher number indicates longer wearing tire (100-800)',
		tractionGrade: 'Traction - Tire\'s ability to stop on wet roads. ' +
			'Applicable to same manufacturer tires. (Excellent, Best, Better, or Good)',
		treadDepth: 'Initial Tread Depth',
		serviceIndex: 'Load Index - Indicates the load a tire can carry',
		speedRating: 'Speed Rating - Indicates the maximum speed a tire should not exceed',
		size: 'Size',
		sidewall: 'Sidewall'
	};

	return titles[spec];
};

const renameSpecTitlesForDetails = ( spec ) => {
	const titles = {
		oemTireMarkDescription: 'Vehicle Manufacturer-Approved Tires – ' +
			'Tires that meet the highest performance standards',
		ecoTire: 'Fuel Efficient',
		size: 'Size',
		weight: 'Weight',
		performanceType: 'Type',
		serviceDescription: 'Service Description',
		speedRating: 'Speed Rating',
		sidewall: 'Sidewall',
		temp: 'Temp',
		tractionGrade: 'Traction',
		gradeTreadWear: 'Treadwear',
		maxRimWidth: 'Max Rim Width',
		diameter: 'Overall Diameter',
		treadDepth: 'Tread Depth'
	};

	return titles[spec];
};

const renameSpecTitlesForFeaturesToCompare = ( spec ) => {
	const titles = {
		financing: 'Financing',
		promos: 'Promotions',
		season: 'Seasonality',
		factoryInstalled: 'Original Equipment Tire',
		oemTireMarkDescription: 'Vehicle Manufacturer-Approved Tires',
		ecoTire: 'Fuel Efficient',
		warrantyMilesNumeric: 'Manufacturer Treadwear Warranty',
		warrantyYears: 'Manufacturer Defect Warranty',
		roadHazardFlag: 'Road Hazard Coverage',
		performanceType: 'Tire Category',
		isRunflat: 'Run-Flat Capable',
		gradeTreadWear: 'Treadwear',
		tractionGrade: 'Traction',
		treadDepth: 'Initial Tread Depth',
		serviceIndex: 'Load Index',
		speedRating: 'Speed Rating',
		size: 'Size',
		sidewall: 'Sidewall'
	};

	return titles[spec];
};

// These are helper methods to convert the values of these specs to be prettier
// For example converting booleans to 'Yes' and 'No' or properly converting traction grades
const convertBooleans = (specValue) => {

	return specValue ? vm.value.$t('message.yes') : vm.value.$t('message.no');


};

const convertTraction = (specValue) => { // eslint-disable-line complexity
	switch (specValue) {
		// eslint-disable-next-line no-duplicate-case
		case 'AA': return vm.value.$t('message.excellent');
		case 'A': return vm.value.$t('message.best');
		case 'B': return vm.value.$t('message.better');
		case 'C': return vm.value.$t('message.good');
		case 'None': return vm.value.$t('message.none');
		case 'No': return vm.value.$t('message.no');
		default: return specValue;
	}
};

// Makes the warrantyMilesNumeric look pretty when rendered
const convertWarrantyMsg = (specValue) => {
	if ((Number(specValue) || specValue === '0')) {
		return `${prettyLgNum(specValue)} ${vm.value.$t('message.warrantyMiles')}`;
	}

	return specValue;
};

const convertSpecValues = ( specValue, spec ) => { // eslint-disable-line complexity
	if (spec === 'tractionGrade') {
		return convertTraction(specValue);
	}

	if (spec === 'warrantyMilesNumeric') {
		return convertWarrantyMsg(specValue);
	}

	if (specValue === '') {
		return 'NA';
	}

	switch (specValue) {
		case 'None': return vm.value.$t('message.none');
		case 'No': return vm.value.$t('message.no');
		default: return specValue;
	}
};

export {
	accessibilitySpecKeysAndTitles,
	convertBooleans,
	convertSpecValues,
	renameSpecTitlesForCompare,
	renameSpecTitlesForDetails,
	renameSpecTitlesForFeaturesToCompare
};
