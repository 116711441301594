import * as actions from './dealerActions.js';

const store = {
	namespaced: true,
	state: {
		details: [],
		goToProduct: '',
		hasSearched: false,
		loading: false,
		locationInfo: {},
		locationList: [],
		selected: {},
		showing: [],
		zipCode: '',
		defaultZipRadius: '250'
	},
	// Mutations are commited and must be synchronous
	mutations: {
		dealerFromSession(state, payload) {
			state.selected = {
				dealershipname: payload
			};
		},
		goToProduct(state, payload) {
			state.goToProduct = payload;
		},
		loading(state, payload) {
			state.loading = payload;
		},
		locationInfo(state, payload) {
			state.locationInfo = payload;
		},
		locationList(state, payload) {
			state.hasSearched = true;
			state.locationList = payload;
		},
		selected(state, payload) {
			state.selected = payload;
		},
		showing(state, payload) {
			state.showing = payload;
		},
		updateZipCode(state, payload) {
			state.zipCode = payload;
		},
		updateDefaultZipRadius(state, payload) {
			state.defaultZipRadius = payload;
		}
	},
	// Actions are dispatched, which then commit mutations. Can be asynchronous methods.
	actions
};

export default store;
