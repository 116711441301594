import { get } from 'axios';

const store = {
	namespaced: true,
	state: {
		formLocation: '',
		formLocationConfirmation: ''
	},
	mutations: {
		content(state, { prop, html }) {
			state[prop] = html;
		}
	},
	actions: {
		cms({ commit, state }, { prop, slug, isUpdate }) {
			if (!state[prop] || isUpdate) {
				get(`/service/cms/${slug}`).then(({ data }) => {
					commit('content', {
						prop,
						html: data.html
					});
				});
			}
		},
		fetchContent({ dispatch }, isUpdate) {
			dispatch('cms', {
				prop: 'formLocation',
				slug: 'dealer-form-location',
				isUpdate
			});

			dispatch('cms', {
				prop: 'formLocationConfirmation',
				slug: 'dealer-form-location-confirmation',
				isUpdate
			});
		}
	}
};

export default store;
