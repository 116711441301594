<template>
    <MountingPortal mountTo="#dealerHeader">
        <h2 v-if="selected.dealershipname" class="header-dealer-name" v-html="dealershipnameHTML" role="none"></h2>
    </MountingPortal>
</template>

<script>
import { mapState } from 'vuex';

export default {
	name: 'DealerHeader',
	computed: {
		...mapState('dealer', ['selected']),

		// display dealership name in header
		dealershipnameHTML() {

			if (this.selected.dealershipname) {
				const dn = this.selected.dealershipname;

				// this is specific to Mercedes-Benz.
				// If you need to add another OEM, start the conversation about how
				// dealerName is being exported from JDE.
				// Also, check with all other teams and their usage of DealershipName.

				return dn.includes('MERCEDES-BENZ') ? dn.toLowerCase().replace('mercedes-benz of', '<span>mercedes-benz</span> <span class="lowercase">of</span>') : dn;
			}

			return '';
		}
	}
};
</script>
