import * as actions from './actions.js';
import { extend } from 'underscore';
import { orderSubmitSuccessPerSku, orderSubmitSuccessSummary } from '@/utils/setDataLayer/index';

const store = {
	namespaced: true,
	state: {
		defaultTransportation: {
			id: 10,
			name: 'Drop-off'
		},
		mobileInstallationID: 13,
		signature: '',
		leadID: '',
		statusID: '',
		details: {
			payment: {
				subTotalAmount: 0,
				supplementalChargesAmount: 0,
				taxRateLabor: 0,
				taxRateParts: 0,
				grandTotalAmount: 0,
				isFinancing: false,
				type: '',
				subscriptionID: '',
				taxes: {
					state: {
						description: '',
						percentage: 0,
						amount: 0
					},
					city: {
						description: '',
						percentage: 0,
						amount: 0
					},
					county: {
						description: '',
						percentage: 0,
						amount: 0
					}
				}
			},
			consumer: {
				firstName: '',
				lastName: '',
				address: {
					line1: '',
					line2: '',
					state: '',
					city: '',
					zipCode: '',
					country: ''
				},
				deliveryAddress: {
					firstName: '',
					lastName: '',
					line1: '',
					line2: '',
					state: '',
					city: '',
					zipCode: '',
					country: ''
				},
				email: '',
				phone: '',
				cellPhone: '',
				contactPreference: '',
				comments: ''
			},
			appointment: {
				requestedDate: '',
				blockStartTime: '',
				blockDurationMinutes: 60,
				apptID: '',
				apptProvider: 'lite',
				status: 1
			},
			vehicle: {
				year: 0,
				make: '',
				model: '',
				trim: '',
				option: '',
				carline: '',
				containsStaggeredSet: false
			},
			services: [],
			promotions: [],
			products: [
				{
					manufacturer: '',
					model: '',
					installPrice: 0,
					itemId: '',
					laborTax: 0,
					nonTax: 0,
					partsTax: 0,
					productId: 0,
					retailPrice: 0,
					quantity: 0,
					roadHazardPath: '',
					sku: '',
					onOffProgram: '',
					position: '',
					warehouse: [
						{
							delvbytime: '',
							quantity: 4,
							scheduleType: 'Default schedule - No Overrides',
							shipByTime: '',
							warehouse: ''
						}
					],
					warrantyPath: ''
				}
			],
			amenities: {
				id: null,
				description: ''
			},
			payer: {
				firstName: '',
				lastName: '',
				address: {
					line1: '',
					line2: '',
					state: '',
					city: '',
					zipCode: '',
					country: ''
				}
			},
			leadID: '',
			leadSearchType: 'searchyByVehicle',
			leadSource: {
				id: 3,
				description: 'Your Dealer Site'
			},
			coupons: []
		}
	},
	getters: {
		isWinter: state => state.details.products[0].winter,
		orderPayment: state => state.details.payment,
		orderDetails: state => state.details,
		defaultTransportation: state => state.defaultTransportation,
		// These are the ids of AUDI and Volvo's special transportiation options
		// The DB stores a non-null program for these. FO-581 will update the service to return that program
		// which can then be checked here
		isOEMSpecialTransportation: state => state.details.amenities.id === 11 || state.details.amenities.id === 12,
		isMobileInstallation: state => state.details.amenities.id === state.mobileInstallationID,
		mobileInstallationID: state => state.mobileInstallationID,
		mobileInstallationZip: state => state.details.consumer.deliveryAddress.zipCode
	},
	mutations: {
		consumerComments(state, payload) {
			state.details.consumer.comments = payload;
		},
		setStatusID(state, payload) {
			if (payload) {
				state.details.appointment.status = payload;
				state.statusID = payload;
			} else {
				state.details.appointment.status = '';
				state.statusID = '';
			}
		},
		setTransportation(state, payload) {
			state.details.amenities = payload;
		},
		setServices(state, payload) {
			state.details.services = payload;
		},
		setMobileInstallInfo(state, payload) {
			state.details.consumer.deliveryAddress = payload;
		},
		setMobileInstallZip(state, payload) {
			state.details.consumer.deliveryAddress.zipCode = payload;
		},
		setOrder(state, payload) {
			state.details = extend(state.details, payload);
		},
		consumerOrderQueueSubmission(state, payload) {
			// Sets dataLayer
			orderSubmitSuccessPerSku(payload);
			orderSubmitSuccessSummary(state);
		},
		setSignature(state, payload) {
			state.signature = payload;
		},
		setLeadID(state, payload) {
			state.leadID = payload;
			state.details = extend(state.details, { leadID: payload });
		},
		setFinancing(state, payload) {
			state.details.payment.isFinancing = payload;
		},
		setAppointmentDuration(state, payload) {
			state.details.appointment.blockDurationMinutes = payload;
		},
		orderPayment(state, payload) {
			state.details.payment = extend({}, state.details.payment, payload);
		},
		leadSearchType(state, { type }) {
			state.details.leadSearchType = type;
		}
	},
	actions
};

export default store;
