const ResourceNames = {
	// used for Honda Plus at the moment 6-19-2024
	useAllMakes: 'HasAllMakes',
	// program specific
	useChat: 'hasChat',
	// program specific (just Audi)
	useWheelPackages: 'b2cWheelPackagesToSell',
	// program specific
	useABTest: 'b2cABTest1',
	// program specific (Canada)
	useMultiLangSupport: 'b2cMultiLanguageSupport'
};

module.exports = ResourceNames;
