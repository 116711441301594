<template>
    <div :class="{'input-group' : bootstrapStyling}">
        <!-- Calendar Button -->
        <button
            v-if="calendarButton"
            class="vdp-datepicker__calendar-button"
            :class="{'input-group-prepend' : bootstrapStyling}"
            @click="showCalendar"
            :style="{'cursor:not-allowed;' : disabled}"
        >
            <span :class="{'input-group-text' : bootstrapStyling}">
                <i :class="calendarButtonIcon">
                    {{ calendarButtonIconContent }}
                    <span v-if="!calendarButtonIcon">&hellip;</span>
                </i>
            </span>
        </button>

        <!-- Input -->
        <input
            :type="inline ? 'hidden' : 'text'"
            :class="computedInputClass"
            :name="name"
            :ref="refName"
            :id="id"
            :value="formattedValue"
            :open-date="openDate"
            :placeholder="placeholder"
            :clear-button="clearButton"
            :disabled="disabled"
            :required="required"
            :readonly="!typeable"
            @click="showCalendar"
            @keyup="parseTypedDate"
            @blur="inputBlurred"
            autocomplete="off">

        <!-- Clear Button -->
        <button
            v-if="clearButton && selectedDate"
            class="vdp-datepicker__clear-button"
            :class="{'input-group-append' : bootstrapStyling}"
            @click="clearDate()"
        >
            <span :class="{'input-group-text' : bootstrapStyling}">
                <i :class="clearButtonIcon">
                    <span v-if="!clearButtonIcon">&times;</span>
                </i>
            </span>
        </button>
        <slot name="afterDateInput"></slot>
    </div>
</template>

<script>
import { makeDateUtils } from '@/components/Datepicker/DateUtils';

export default {
	props: {
		selectedDate: Date,
		resetTypedDate: [Date],
		format: [String, Function],
		translation: Object,
		inline: Boolean,
		id: String,
		name: String,
		refName: String,
		openDate: Date,
		placeholder: String,
		inputClass: [String, Object, Array],
		clearButton: Boolean,
		clearButtonIcon: String,
		calendarButton: Boolean,
		calendarButtonIcon: String,
		calendarButtonIconContent: String,
		disabled: Boolean,
		required: Boolean,
		typeable: Boolean,
		bootstrapStyling: Boolean,
		useUtc: Boolean
	},
	data () {
		const constructedDateUtils = makeDateUtils(this.useUtc);


		return {
			input: null,
			typedDate: false,
			utils: constructedDateUtils
		};
	},
	computed: {
		formattedValue () {
			if (!this.selectedDate) {
				return null;
			}
			if (this.typedDate) {
				return this.typedDate;
			}

			return typeof this.format === 'function'
				? this.format(this.selectedDate)
				: this.utils.formatDate(new Date(this.selectedDate), this.format, this.translation);
		},

		computedInputClass () {
			if (this.bootstrapStyling) {
				if (typeof this.inputClass === 'string') {
					return [this.inputClass, 'form-control'].join(' ');
				}

				return {'form-control': true,
					...this.inputClass};
			}

			return this.inputClass;
		}
	},
	watch: {
		resetTypedDate () {
			this.typedDate = false;
		}
	},
	methods: {
		showCalendar () {
			this.$emit('showCalendar');
		},

		// Attempt to parse a typed date
		parseTypedDate (event) {
			// close calendar if escape (27) or enter (13) are pressed
			if ([
				27,
				13
			].includes(event.keyCode)) {
				this.input.blur();
			}

			if (this.typeable) {
				const typedDate = Date.parse(this.input.value);

				if (!isNaN(typedDate)) {
					this.typedDate = this.input.value;
					this.$emit('typedDate', new Date(this.typedDate));
				}
			}
		},

		// nullify the typed date to defer to regular formatting called once the input is blurred

		inputBlurred () {
			if (this.typeable && isNaN(Date.parse(this.input.value))) {
				this.clearDate();
				this.input.value = null;
				this.typedDate = null;
			}

			this.$emit('closeCalendar');
		},

		// emit a clearDate event
		clearDate () {
			this.$emit('clearDate');
		}
	},
	mounted () {
		this.input = this.$el.querySelector('input');
	}
}
// eslint-disable-next-line
;
</script>
