<template>
    <div>
        <input
            :name="'appointmentServiceCheckbox-' + service.id"
            :id="'appointmentServiceCheckbox' + service.id"
            class="service-item__checkbox"
            type="checkbox"
            v-model="offering"
            :value="service"
        />
        <label :for="'appointmentServiceCheckbox' + service.id" class="service-item-details">
            <span class="service-item-details__title">{{ serviceTitle(service.title) }}</span>
            <span class="service-item-details__price">{{ $n(service.price, 'currency') }}</span>
            <span class="service-item-details__desc" v-if="!!service.description">
                {{ description }}
                <button
                    v-if="isAccordion"
                    @click="toggleAccordion"
                    type="button"
                    class="service-item-details__button"
                    :aria-expanded="isOpen ? 'true' : 'false'"
                >
                    {{ isOpen ? 'See Less' : 'See More' }}
                </button>
            </span>
        </label>
    </div>
</template>

<script>
export default {
	name: 'AppointmentServiceOffering',

	props: {
		'service': {
			type: Object,
			required: true
		},
		'value': {
			type: Array
		}
	},

	data() {
		return {
			isOpen: false,
			isAccordion: false
		};
	},

	computed: {
		offering: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			}
		},
		description() {
			return this.isOpen
				? this.service.description
				: this.serviceDescription(this.service.description);
		}
	},

	methods: {
		toggleAccordion() {
			this.isOpen = !this.isOpen;
		},
		serviceTitle(title) {
			const wordsInTitle = title.toLowerCase().split(' ');

			return wordsInTitle.map((word) => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
		},
		serviceDescription(description) {
			const maxWordCount = 15;
			const descWords = description.split(' ');

			if (descWords.length <= maxWordCount) {
				return description;
			}

			this.isAccordion = true;

			const preview = [
				...descWords.slice(0, maxWordCount),
				'...'
			].join(' ');

			return preview;
		}
	}
};
</script>
