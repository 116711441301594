import Language from '../Language';

export default new Language(
	'Dutch',
	['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'],
	['jan', 'feb', 'mrt', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
	['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za']
)
// eslint-disable-next-line
;
