import * as actions from './actions.js';

const serviceReducer = services => {
	const allowedServices = [
		'oil change',
		'alignment',
		'permasafe',
		'nissan clean',
		'nissan shield',
		'infiniti clean',
		'infiniti shield',
		'mobile tire install'
	];

	return services.filter(service => {
		const title = service.title.toLowerCase();

		return service.isPromoted ? allowedServices.some(allowedService => title.indexOf(allowedService) >= 0) : null;

	});
};

/**
 * Adds .00 to a price if it doesn't have any cents
 * @param  {Array} services Data from dealerServiceOfferingsListRequest
 * @return {Array}          Services with fixed pricing
 */
const priceFix = services => {
	return services.map(service => {
		let price = service.price;

		if (price.length <= 2) {
			service.price = Number(price).toFixed(2);
		}

		return service;
	});
};

const store = {
	namespaced: true,
	state: {
		availableOfferings: [],
		selectedOfferings: [],
		servicesInSession: []
	},
	// Mutations are commited and must be synchronous
	mutations: {
		setAvailableOfferings(state, payload) {
			state.availableOfferings = priceFix(serviceReducer(payload));
		},
		setSelectedServiceOfferings(state, payload) {
			if (payload.length) {
				state.selectedOfferings = priceFix(serviceReducer(payload));
			} else {
				state.selectedOfferings = [];
			}
		}
		// setServicesInSession(state, payload) {
		// 	state.servicesInSession = payload;
		// }
	},
	// Actions are dispatched, which then commit mutations. Can be asynchronous methods.
	actions
};

export default store;
