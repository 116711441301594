import Language from '../Language';

export default new Language(
	'Catalan',
	['Gener', 'Febrer', 'Març', 'Abril', 'Maig', 'Juny', 'Juliol', 'Agost', 'Setembre', 'Octubre', 'Novembre', 'Desembre'],
	['Gen', 'Feb', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Oct', 'Nov', 'Des'],
	['Diu', 'Dil', 'Dmr', 'Dmc', 'Dij', 'Div', 'Dis']
)
// eslint-disable-next-line
;
