import Language from '../Language';

export default new Language(
	'Brazilian',
	['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
	['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
	['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']
)
// eslint-disable-next-line
;
