<template>

    <aside class="appointmentDatePicker" v-if="calendarCurrentState">

        <MessageBox v-if="isNoTimeSlotsError"
                    :messageIcon="'/dist/assets/svgs/calendar.svg'"
                    :messageIconAlt="'Calendar Icon'"
                    :headerText="'No appointments available'"
                    :messageText="'We\'re fully booked this month. Please check the next available month for open slots.'"
                    :ctaText="'See next available appointments'"
                    @messageBoxButtonClicked="showNextAvailableAppointments"
        />
        <MessageBox v-else-if="isTimeSlotError"
                    :messageIcon="'/dist/assets/svgs/error.svg'"
                    :messageIconAlt="'Calendar Icon'"
                    :headerText="'We’re having a bit of trouble completing your request'"
                    :messageText="msgBoxError"
        />
        <Datepicker v-else
                    v-show="!isLoading"
                    v-model="appointmentDate"
                    :disabled-dates="{
                        to: buffedDeliveryDate,
                        from: maxFromStartDate,
                        dates: disabledDates
                    }"
                    :language="consumerLanguage"
                    :format="'MM-dd-yyyy'"
                    :required="true"
                    :inline="true"
                    :open-date="calendarOpenDate"
                    :input-class="'calendar__appointment-input'"
                    @selected="updateAppointmentDate"
                    @changedMonth="getTimeSlots"
        />
        <!-- Loading Animation -->
        <div v-if="isLoading" class="col-12">
            <div class="calendar__loader">
                <img
                    src="/dist/assets/loaders/tire-spinner.gif"
                    alt="Spinning loader animation"
                />
            </div>
        </div>
    </aside>
</template>

<script>
import { addHours, addDays, format, isAfter } from 'date-fns';
import axios from 'axios';
import { mapState } from 'vuex';
import fetchTimeSlots from '@/utils/fetchTimeSlots';
import MessageBox from '@/components/MessageBox.vue';
import Datepicker from '@/components/Datepicker/Datepicker.vue';
import {en, fr} from '@/components/Datepicker/locale';

export default {
	name: 'AppointmentDate',
	components: {
		Datepicker,
		MessageBox
	},
	props: {
		query: {
			required: true,
			type: Object
		},
		isCalendarOpen: {
			type: Boolean,
			required: true
		},
		selectedAppointmentType: {
			type: Object,
			required: true
		},
		isMobile: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			calendarOpenDate: '',
			appointmentDate: '',
			selectedAppointmentDate: '',
			disabledDates: [],
			deliveryBufferHours: 0,
			isLoading: true,
			minDeliveryBufferHours: 24,
			provider: 'SL',
			calendarCurrentState: false,
			en: en,
			fr: fr,
			isTimeSlotError: false,
			isNoTimeSlotsError: false,
			TimeSlotErrorMessage: '',
			languageCode: ''
		};
	},
	methods: {
		async getTimeSlots(startingDay) { // eslint-disable-line complexity
			let startDate = startingDay;

			if (startDate && startDate.timestamp) {
				startDate = new Date(startDate.timestamp);
			}
			this.isLoading = true;

			let { make, year, model, qty } = this.query;

			try {
				const timeSlots = await fetchTimeSlots({
					startDate: startDate && isAfter(startDate, this.buffedDeliveryDate) ? startDate : this.buffedDeliveryDate,
					make,
					transportationOption: this.selectedAppointmentType.id || 10,
					year,
					model,
					qty,
					deliveryDate: this.buffedDeliveryDate
				});


				if (typeof timeSlots === 'object') {
					this.provider = timeSlots.provider;
					if (!this.calendarOpenDate) {
						this.calendarOpenDate = timeSlots.openDates[0].date;
					}
					this.isNoTimeSlotsError = false;
					this.TimeSlotErrorMessage = '';
					this.disabledDates = timeSlots.closedDates;
					this.$emit('fetchedTimeSlots', timeSlots);
					this.$emit('toggleSubmitVisibility', true);
				}

			} catch (error) {

				// gracefully handle errors // if we have to more than 3 statements to match swap to a switch statement
				if (error.message === 'Error fetching timeslots') {
					console.log('got an error with timeslots - please try again later');
					this.isTimeSlotError = true;
					this.TimeSlotErrorMessage = 'Error retrieving timeslots - please try again later.';
				} else if (error.message === 'no time slots available this month') {
					this.isNoTimeSlotsError = true;
					this.TimeSlotErrorMessage = 'No time slots available this month - please try another month.';
					console.log('no time slots available this month - please try another month');
				} else {
					console.log('got an error with timeslots - please try again later', error);
					this.isTimeSlotError = true;
					this.TimeSlotErrorMessage = error.message;
				}
				this.disabledDates = [];
				this.calendarOpenDate = new Date();
				this.calendarOpenDate.setMonth(this.calendarOpenDate.getMonth() + 1, 1);
				this.$emit('fetchedTimeSlots', {'closedDates': [],
					'openDates': [],
					'provider': ''});
				this.$emit('toggleSubmitVisibility', false);

			}


			this.isLoading = false;
		},
		updateAppointmentDate(date) {
			const d = new Date(date);

			this.selectedAppointmentDate = format(new Date(d), 'PPP');
			this.$emit('updateAppointmentDate', d);
			this.calendarCurrentState = false;
		},
		showNextAvailableAppointments () {
			this.getTimeSlots(this.calendarOpenDate);
			this.isTimeSlotError = false;
			this.isNoTimeSlotsError = false;
			this.$emit('toggleSubmitVisibility', true);
		}
	},
	computed: {
		...mapState('products', ['deliveryDate']),
		...mapState('dealer', ['selected']),
		...mapState('consumerInfo', ['userLanguage']),
		...mapState('initial', ['title', 'phoneNumber']),
		...mapState('dealer', { selectedDealer: 'selected' }),
		msgBoxError() {
			return `You can try again later or reach out to us at the number below for help with your appointment.<br /><br />${ this.selectedDealer.phoneNumber || this.phoneNumber.phone}`;
		},
		isSearchBySize() {
			return Boolean(this.query.isbysize);
		},
		maxFromStartDate() {
			return addDays(this.buffedDeliveryDate, 90);
		},
		buffedDeliveryDate() {// eslint-disable-line complexity
			this.deliveryBufferHours = this.selected.otsSchedulingBufferHours;
			// first, if we're here with an invalid date, or a blank date, bandaid with a week from today
			let deliveryDateBase = addDays(new Date(), 7);

			// parse the given delivery date as gmt so we can apples-to-apples with schedule hours
			let valiDate = new Date(`${this.deliveryDate}Z`);

			if (valiDate && Object.prototype.toString.call(valiDate) === '[object Date]' && !isNaN(valiDate)) {
				deliveryDateBase = valiDate;
				// blank string delvery dates are not log-worthy.
				// Initial page load has a blank delivery date
				// and problems with the delivery date fetch are logged elsewhere, leaving DD blank
				// only log if it comes in weird, which might be a bad actor, or an actual problem
			} else if (this.deliveryDate !== '') {
				console.log('invalid date format when building calendar: ', this.deliveryDate);
				axios.get('/service/errorlog', {
					params: {
						message: 'invalid date format when building calendar',
						dealerUid: this.query.uid,
						deliveryDate: this.deliveryDate,
						userAgent: navigator.userAgent
					}
				});
			}

			// the delivery date day/hours are in the dealer's timezone. Interpret as UTC so we can add hours.
			// before displaying in the schedule, we'll be able to add offset hours to get back to the correct time.

			let buff = this.minDeliveryBufferHours;

			let dayOfWeek = new Date();
			// Mobile installation has no delivery buffer beyond our minimum

			if (!this.selectedAppointmentType.id || this.selectedAppointmentType.id !== 13) {
				// add dealer's hours
				// if they refresh on this page, we re-fetch dealer info,
				// but just in case, let's not lock the scheduling page with an undefined buffer
				buff += this.deliveryBufferHours ? this.deliveryBufferHours : 0;
			} else if (this.selectedAppointmentType.id && this.selectedAppointmentType.id === 13 && dayOfWeek.getDay() === 5) {
				// Add 24hr buffer only on MI order for fridays ONLY
				buff += 24;
			}


			return addHours(deliveryDateBase, buff);
		},
		// This method is used to determine the lanuage displayed on the datepicker (calendar)
		// If more languages need to be added later, add more to data() above
		consumerLanguage() {
			let language = '';

			// if userLanguage has a value (eg. en_ca), save the language portion of it to language
			// if it has no value, calendar defaults to English
			if (this.userLanguage) {
				language = this.userLanguage.split('-')[0];
			}

			return this.$data[`${language}`];

		}
	},
	watch: {
		isCalendarOpen(bool) {
			this.calendarCurrentState = bool;
		},
		selectedAppointmentType(newType, oldType) { // eslint-disable-line
			if ((this.provider !== 'SL' && this.provider !== 'FB' && oldType.id) || newType.id === 13 || oldType.id === 13) {
				this.selectedAppointmentDate = '';
				this.appointmentDate = '';
				this.getTimeSlots();
			}
		},
		'deliveryDate': {
			async handler() { //eslint-disable-line

				return this.getTimeSlots();
			},
			immediate: true
		}
	}
};
</script>
