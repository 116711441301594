<template>
    <dialog-modal
        @close="toggleFslModal"
        :isOpen="showFslModal"
        ariaLabel="FSL Mobile Install"
    >
        <h4>{{ $t('message.mobileInstall') }}</h4>

        <!-- Apr 2024: removing mobile install content -->
        <!-- <p v-html="scheduleMobileModal"></p> -->

        <button
            slot="footer"
            @click="handleFslConfirm"
            class="button button--primary"
        >
            {{ $t("message.getStarted") }}
        </button>
    </dialog-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import DialogModal from '@/components/DialogModal';
import { scheduleMobileInstallGetStarted, leavingPage } from '@/utils/setDataLayer';

export default {
	name: 'ModalFslMobileTransaction',

	components: {
		DialogModal
	},

	props: {
		query: {
			type: Object,
			required: true
		}
	},

	computed: {
		// ...mapState('content/schedule', ['scheduleMobileModal']),
		...mapState('products', ['isStaggered']),
		...mapState('cart', ['tiresInCart', 'warehouses']),
		...mapState('modal', ['showFslModal'])
	},

	methods: {
		...mapMutations('modal', ['toggleFslModal']),

		handleFslConfirm() {
			this.toggleFslModal(false);

			this.handleFSLClick();

			scheduleMobileInstallGetStarted(window.location.href, this.tiresInCart);

			this.$router.push({
				path: '/app/fsl-confirm',
				query: this.query
			});
		},
		handleFSLClick() {
			const lineItems = [];

			if (this.isStaggered) {
				lineItems.push(
					{
						skuName: this.tiresInCart.products[0].sku,
						skuQuantity: 2
					},
					{
						skuName: this.tiresInCart.products[1].sku,
						skuQuantity: 2
					}
				);
			} else {
				lineItems.push({
					skuName: this.tiresInCart.products[0].sku,
					skuQuantity: 4
				});
			}

			const urlParams = {
				'dealerUid': this.query.uid,
				'vehicleInfo': {
					'year': this.query.year,
					'model': this.query.model,
					'make': this.query.make,
					'trim': this.query.trim,
					'option': this.query.option,
					'vin': null
				},
				'lineItems': lineItems,
				'distributionCenter': this.warehouses[0].warehouseID || this.warehouses[0][0].warehouseID
			};

			const encodedURL = encodeURIComponent(JSON.stringify(urlParams));
			const leavingURL = `https://dealertire--fslstaging.sandbox.my.site.com/mobileinstall/s/?jsonParam=${encodedURL}`;

			leavingPage('url', leavingURL);
			window.open(leavingURL, 'FSL Mobile Install');
		}
	}
};
</script>
