<template>
    <DialogModal
        :isOpen="isOpen"
        @close="$emit('onClose')"
        class="dialog-carousel"
        :ariaLabel="modalCaption"
    >
        <button
            class="dialog-carousel__left-arrow"
            :class="{ 'dialog-carousel__hide': isFirst }"
            aria-label="Modal previous image"
            @click.prevent="previousSelected"
        >
            <IconChevron direction="left" width="45" height="45" />
        </button>

        <figure>
            <figcaption class="dialog-carousel__caption">{{ modalCaption }}</figcaption>
            <img
                class="dialog-carousel__img"
                @error="errorLoading=true;"
                :src="selectedProductImage"
                :alt="selectedProductImageAlt"
                :aria-label="selectedProductImageAlt"
            >
        </figure>

        <button
            class="dialog-carousel__right-arrow"
            :class="{ 'dialog-carousel__hide': isLast }"
            aria-label="Modal next image"
            @click.prevent="nextSelected"
        >
            <IconChevron width="45" height="45" />
        </button>
    </DialogModal>
</template>

<script>
import DialogModal from '@/components/DialogModal';
import IconChevron from '@/components/IconChevron';

export default {
	props: {
		isOpen: {
			type: Boolean,
			required: true
		},
		productSet: {
			type: Object,
			required: true
		},
		isWpkModal: {
			type: Boolean
		}
	},

	components: {
		DialogModal,
		IconChevron
	},

	data() {
		return {
			selected: 0,
			errorLoading: false
		};
	},

	mounted() {
		this.$nextTick(() => {
			this.$el.getElementsByClassName('dialog-carousel__right-arrow')[0].focus();
		});
	},


	methods: {
		// Goes to next/previous tire image in modal
		nextSelected() {
			this.selected += 1;
		},
		previousSelected() {
			this.selected -= 1;
		}
	},

	watch: {
		selected() {
			this.errorLoading = false;
		}
	},

	computed: {
		// If the current image is the last/first one of the list of tire images, returns true (modal navigation logic)
		isLast() {
			return (this.selected === (this.imageList.length - 1));
		},
		isFirst() {
			return (this.selected === 0);
		},
		// If the modal is for a tire product-tile, the images will be for the tire only
		// If the modal is for a wheel package wpk-tile, the images will be for the wheel package only
		imageList() {
			return this.isWpkModal ? this.wpkImageList : this.tireImageList;
		},
		tireImageList() {
			if (this.productSet.products[0].imageId) {

				return [
					`/content/drsg/b_xl_iso/${this.productSet.products[0].imageId}_b_xl.jpg`,
					`/content/drsg/c_xl_front/${this.productSet.products[0].imageId}_c_xl.jpg`,
					`/content/drsg/a_xl_side/${this.productSet.products[0].imageId}_a_xl.jpg`
				];
			}

			return ['/content/drsg/b_iso/default.jpg'];
		},
		wpkImageList() {
			if (this.productSet.imageId) {
				const imageLastChar = this.productSet.imageId.charAt(this.productSet.imageId.length - 1);
				const imageID = imageLastChar.search(/l|r/i) === -1 ?
					this.productSet.imageId : this.productSet.imageId.slice(0, -1);

				return [`/content/drsg/c_front/${imageID}_c.jpg`, `/content/drsg/a_side/${imageID}_a.jpg`];
			}

			return ['/content/drsg/c_front/default.jpg', '/content/drsg/a_side/default.jpg'];
		},
		// Current image displaying in modal
		selectedProductImage() {
			if (this.errorLoading) {
				return '/content/drsg/b_iso/default.jpg';
			}

			return this.imageList[this.selected];
		},
		selectedProductImageAlt() {
			const brand = this.productSet.products[0].brand;

			if (this.selected === 0) return `Angle view of ${brand} tire.`;
			if (this.selected === 1) return `Tread view of ${brand} tire.`;
			if (this.selected === 2) return `Side view of ${brand} tire.`;

			return `${this.selected} image of ${brand} tire`;
		},
		modalCaption() {
			return this.isWpkModal ?
				this.productSet.overview.split('utilizing')[1] :
				`${this.productSet.products[0].brand} ${this.productSet.products[0].catalogEntryDescription}`;
		}
	}
};

</script>
