<template>
    <!-- Param action, method, and target -->
    <form
        id="checkout_form"
        :action="getAction"
        :method="getMethod"
        name="checkout_form"
        target="submitPaymentPost"
        class="row billing"
        @submit.prevent="validateBeforeSubmit"
    >
        <!-- HIDDEN INPUTS -->
        <input type="hidden" name="profile_id" id="profile_id" :value="profileId" />
        <input type="hidden" name="access_key" id="access_key" :value="cybersource.accessKey" />
        <input type="hidden" name="bill_to_address_country" id="bill_to_address_country" value="US" />
        <input type="hidden" name="locale" id="locale" value="en" />
        <input type="hidden" name="auth_indicator" id="auth_indicator" value="0" />
        <input type="hidden" name="reference_number" id="reference_number" :value="leadID" />
        <input type="hidden" name="card_expiry_date" id="card_expiry_date" :value="formattedExpireDate" />
        <input type="hidden" name="transaction_uuid" id="transaction_uuid" :value="transactionUUID" />
        <input type="hidden" name="skip_decision_manager" id="skip_decision_manager" value="true" />
        <input type="hidden" name="payment_method" id="payment_method" value="card" />
        <input type="hidden" name="currency" id="currency" value="USD" />
        <input type="hidden" name="amount" id="amount" :value="amount" />
        <input type="hidden" name="transaction_type" id="transaction_type" value="authorization,create_payment_token" />
        <input type="hidden" name="signed_date_time" id="signed_date_time" :value="convertedDate" />
        <input type="hidden" name="unsigned_field_names" id="unsigned_field_names" :value="unsignedFields" />
        <input type="hidden" name="override_custom_receipt_page" id="override_custom_receipt_page" :value="origin" />
        <input type="hidden" name="signature" id="signature" :value="signature" />
        <input type="hidden" name="signed_field_names" id="signed_field_names" :value="signedFields" />
        <input type="hidden" name="ship_to_forename" id="ship_to_forename" :value="billing.firstName" />
        <input type="hidden" name="ship_to_surname" id="ship_to_surname" :value="billing.lastName" />
        <input type="hidden" name="copy_name" id="copy_name" value="on" />
        <input type="hidden" name="bill_to_address_line2" id="bill_to_address_line2" value="''" />
        <input type="hidden" name="confirm_email" :value="billing.email" />
        <!-- END HIDDEN INPUTS -->

        <!-- BILLING INFORMATION -->
        <fieldset class="col-12 col-md-11">
            <h5 class="billing__header">
                {{ $t("message.yourInformation") }}
            </h5>
            <small class="billing__description">
                {{ $t("message.yourPrivacyIsImportant") }}
                {{ $t("message.allFieldsRequired") }}
            </small>

            <div class="row">
                <div class="form-group col-sm-4" role="group" :aria-label="$t('message.billingLabel')">
                    {{ $t("message.billingLabel") }}
                    <ul class="input__list">

                        <!-- First Name -->
                        <li class="input billing--input">
                            <label for="bill_to_forename" class="input__label input">
                                {{ $t("message.fName") }}
                                <input
                                    name="bill_to_forename"
                                    id="bill_to_forename"
                                    autocomplete="given-name"
                                    type="text"
                                    maxlength="100"
                                    v-model="billing.firstName"
                                    data-vv-name="firstName"
                                    @blur="trackCheckoutStepModified({ checkout_step: 'firstName' })"
                                    v-validate="{
                                        required: true,
                                        regex: /^[a-zA-Z]+[a-zA-Z .,'-]*$/,
                                    }"
                                    :class="[
                                        'input__field',
                                        { 'input__field--error': errors.has('firstName') },
                                    ]"
                                    data-cy="billingFirstName"
                                />
                            </label>
                            <small
                                v-if="errors.has('firstName')"
                                role="alert"
                                class="input__error-reason"
                            >
                                {{ errors.first("firstName") }}
                            </small>
                        </li>
                        <!-- Last Name -->
                        <li class="input billing--input">
                            <label for="bill_to_surname" class="input__label">
                                {{ $t("message.lName") }}
                                <input
                                    name="bill_to_surname"
                                    id="bill_to_surname"
                                    autocomplete="family-name"
                                    type="text"
                                    maxlength="100"
                                    v-model="billing.lastName"
                                    @blur="trackCheckoutStepModified({ checkout_step: 'lastName' })"
                                    data-vv-name="lastName"
                                    v-validate="{
                                        required: true,
                                        regex: /^[a-zA-Z]+[a-zA-Z .,'-]*$/,
                                    }"
                                    :class="[
                                        'input__field',
                                        { 'input__field--error': errors.has('lastName') },
                                    ]"
                                    data-cy="billingLastName"
                                />
                            </label>

                            <small
                                v-if="errors.has('lastName')"
                                role="alert"
                                class="input__error-reason"
                            >
                                {{ errors.first("lastName") }}
                            </small>
                        </li>

                        <!-- Address/City/State/Zip -->
                        <li class="input billing--input">
                            <label for="bill_to_address_line1" class="input__label">
                                {{ $t("message.address") }}
                                <input
                                    name="bill_to_address_line1"
                                    id="bill_to_address_line1"
                                    autocomplete="street-address"
                                    type="text"
                                    maxlength="100"
                                    v-model="billing.address.line1"
                                    @blur="trackCheckoutStepModified({ checkout_step: 'address' })"
                                    data-vv-name="address"
                                    v-validate="{
                                        required: true,
                                        min: 3,
                                        regex: /^[a-zA-Z0-9 ,'\-#.]*$/,
                                    }"
                                    :class="[
                                        'input__field',
                                        {
                                            'input__field--error': errors.has(
                                                'address'
                                            ),
                                        },
                                    ]"
                                    data-cy="billingAddress1"
                                />
                            </label>
                            <small
                                v-if="errors.has('address')"
                                role="alert"
                                class="input__error-reason"
                            >
                                {{ errors.first("address") }}
                            </small>
                        </li>
                        <li for="bill_to_address_city" class="input billing--input">
                            <label class="input__label">
                                {{ $t("message.city") }}
                                <input
                                    name="bill_to_address_city"
                                    id="bill_to_address_city"
                                    autocomplete="city"
                                    type="text"
                                    maxlength="100"
                                    v-model="billing.address.city"
                                    @blur="trackCheckoutStepModified({ checkout_step: 'city' })"
                                    data-vv-name="city"
                                    v-validate="{
                                        required: true,
                                        regex: /^[a-zA-Z]+(?:[ -][a-zA-Z]+)*$/,
                                    }"
                                    :class="[
                                        'input__field',
                                        {
                                            'input__field--error': errors.has(
                                                'city'
                                            ),
                                        },
                                    ]"
                                    data-cy="billingCity"
                                />
                            </label>
                            <small
                                v-if="errors.has('city')"
                                role="alert"
                                class="input__error-reason"
                            >
                                {{ errors.first("city") }}
                            </small>
                        </li>
                        <li class="input billing--input-small">
                            <label for="bill_to_address_state" class="input__label">
                                {{ $t("message.state") }}
                                <select
                                    name="bill_to_address_state"
                                    id="bill_to_address_state"
                                    autocomplete="street-address"
                                    v-model="billing.address.state"
                                    data-vv-name="state"
                                    @blur="trackCheckoutStepModified({ checkout_step: 'state' })"
                                    v-validate="'required'"
                                    :class="[
                                        'input__field',
                                        'input__state',
                                        {
                                            'input__field--error': errors.has(
                                                'state'
                                            ),
                                        },
                                    ]"
                                    data-cy="billingState"
                                >
                                    <option
                                        v-for="state in statesList"
                                        :value="state"
                                        :key="state.id"
                                    >
                                        {{ state }}
                                    </option>
                                </select>
                            </label>
                            <small
                                v-if="errors.has('state')"
                                role="alert"
                                class="input__error-reason"
                            >
                                {{ $t("message.stateError") }}
                            </small>
                        </li>
                        <li class="input billing--input-small">
                            <label for="bill_to_address_postal_code" class="input__label">
                                {{ $t("message.zipCode") }}
                                <input
                                    name="bill_to_address_postal_code"
                                    id="bill_to_address_postal_code"
                                    autocomplete="postal-code"
                                    type="text"
                                    :minlength="zipMinLength"
                                    :maxlength="zipMaxLength"
                                    v-model="billing.address.zipCode"
                                    :readonly="useBillingForMobileInstall"
                                    data-vv-name="zipCode"
                                    @blur="trackCheckoutStepModified({ checkout_step: 'zipCode' })"
                                    v-validate="{
                                        required: true,
                                        regex:  zipRegex
                                    }"
                                    :class="[
                                        'input__field',
                                        {
                                            'input__field--error': errors.has(
                                                'zipCode'
                                            ),
                                        },
                                    ]"
                                    @keyup="uppercaseZip"
                                    data-cy="billingZipCode"
                                />
                            </label>
                            <small
                                v-if="errors.has('zipCode')"
                                role="alert"
                                class="input__error-reason"
                            >
                                {{ errors.first("zipCode") }}
                            </small>
                        </li>

                        <!-- Use Billing for Mobile Install-->
                        <label
                            class="input__label mobile__label"
                            v-if="isMobileInstallation"
                            for="useBillingInfoForMobileInstall"
                            :aria-label="$t('message.useBillingInfoForMobileInstall')"
                        >
                            <input autocomplete
                                   type="checkbox"
                                   name="useBillingInfoForMobileInstall"
                                   id="useBillingInfoForMobileInstall"
                                   v-model="useBillingForMobileInstall"
                            />
                            {{ $t("message.useBillingInfoForMobileInstall") }}
                        </label>

                        <!-- Phone/Email -->
                        <li class="input billing--input-medium">
                            <label for="bill_to_phone" class="input__label">
                                {{ $t("message.phone") }}
                                <input
                                    name="bill_to_phone"
                                    id="bill_to_phone"
                                    autocomplete="tel"
                                    type="tel"
                                    placeholder="(___)___-____"
                                    v-mask="'(###)###-####'"
                                    v-model="billing.phone"
                                    data-vv-name="phoneNumber"
                                    @blur="trackCheckoutStepModified({ checkout_step: 'phoneNumber' })"
                                    v-validate="{
                                        required: true,
                                        regex: /^\(\d{3}\)\d{3}-\d{4}$/,
                                    }"
                                    :class="[
                                        'input__field',
                                        { 'input__field--error': errors.has('phoneNumber') },
                                    ]"
                                    data-cy="billingPhone"
                                />
                            </label>
                            <small
                                v-if="errors.has('phoneNumber')"
                                role="alert"
                                class="input__error-reason"
                            >
                                {{ errors.first("phoneNumber") }}
                            </small>
                        </li>

                        <li class="input billing--input">
                            <label for="bill_to_email" class="input__label">
                                {{ $t("message.email") }}
                                <input
                                    name="bill_to_email"
                                    id="bill_to_email"
                                    autocomplete="email"
                                    type="email"
                                    maxlength="100"
                                    v-model="billing.email"
                                    data-vv-name="emailAddress"
                                    @blur="trackCheckoutStepModified({ checkout_step: 'emailAddress' })"
                                    v-validate="'required|email'"
                                    :class="[
                                        'input__field',
                                        { 'input__field--error': errors.has('emailAddress') },
                                    ]"
                                    data-cy="billingEmail"
                                />
                            </label>
                            <small
                                v-if="errors.has('emailAddress')"
                                role="alert"
                                class="input__error-reason"
                            >
                                {{ errors.first("emailAddress") }}
                            </small>
                        </li>
                    </ul>
                </div>
                <mobile-install-form
                    :checkoutFormSubmitted="submitted"
                    :useBillingForMobileInstall="useBillingForMobileInstall"
                    v-if="!useBillingForMobileInstall && isMobileInstallation"
                />
            </div>

            <!-- Credit Card (REFACTOR)-->
            <div v-if="hasCreditCardCheckout" class="credit-card">
                <div class="col-12">
                    <i class="credit-card__icon icon icon--lock col-1"></i>
                    <h4>{{ $t("message.creditCard") }}</h4>
                </div>

                <small class="credit-card__disclaimer-header col-12">{{
                    $t("message.creditCardDisclaimerHeader")
                }}</small>
                <small class="credit-card__disclaimer-body col-12">{{
                    $t("message.creditCardDisclaimerBody")
                }}</small>

                <!-- Hidden Inputs -->
                <input type="hidden" name="card_type" id="card_type" :value="card.type" />
                <input type="hidden" name="card_number" id="card_number" :value="formattedCC" />
                <input type="hidden" name="card_expirationmonth" id="card_expirationmonth" :value="creditCardMonth" data-cy="creditCardMonth" />
                <input type="hidden" name="card_expirationyear" id="card_expirationyear" :value="creditCardYear" data-cy="creditCardYear" />

                <!-- Credit Card Inputs -->
                <ul>
                    <!-- Card Number -->
                    <li class="input credit-card--input-card">
                        <label for="ccField" class="input__label">
                            {{ $t("message.cardNumber") }}
                            <input
                                name="ccField"
                                id="ccField"
                                autocomplete="cc-number"
                                type="text"
                                @blur="validCardNumber()"
                                placeholder="____ ____ ____ ____"
                                v-mask="'#### #### #### ####'"
                                v-model="card.number"
                                :class="[
                                    'input__field',
                                    { 'input__field--error': ccErroring.number },
                                ]"
                                data-cy="creditCardNumber"
                            />
                        </label>
                        <small v-if="ccErroring.number" role="alert" class="input__error-reason">
                            {{ $t("message.ccError") }}
                        </small>
                        <small v-if="ccErroring.decline" role="alert" class="input__error-reason">
                            {{ $t("message.ccDecline") }}
                        </small>
                    </li>

                    <!-- Card Expiration -->
                    <li class="input credit-card--input-small">
                        <label for="card_expire" class="input__label">
                            MM/YY
                            <input
                                name="card_expire"
                                id="card_expire"
                                autocomplete="cc-exp"
                                type="text"
                                @blur="validCardExpiry()"
                                placeholder="__/__"
                                v-mask="'##/##'"
                                v-model="card.expired"
                                :class="[
                                    'input__field',
                                    { 'input__field--error': ccErroring.expired },
                                ]"
                                data-cy="creditCardMMYY"

                            />
                        </label>
                        <small v-if="ccErroring.expired" role="alert" class="input__error-reason">
                            {{ $t("message.expirationError") }}
                        </small>
                    </li>

                    <!-- Card CVN -->
                    <li class="input credit-card--input-small">
                        <label for="card_cvn" class="input__label">
                            {{ $t("message.cvn") }}
                            <input
                                name="card_cvn"
                                id="card_cvn"
                                autocomplete="cc-csc"
                                type="text"
                                maxlength="4"
                                @blur="validCardCVV()"
                                v-model="card.cvn"
                                :class="['input__field', { 'input__field--error': ccErroring.cvn }]"
                                data-cy="creditCardCVV"

                            />
                        </label>
                        <small v-if="ccErroring.cvn" role="alert" class="input__error-reason">
                            {{ $t("message.cvnError") }}
                        </small>
                    </li>
                </ul>
            </div>


        </fieldset>

        <div
            v-if="financingProductAllowed"
            class="billing__financing-check"
        >
            <input
                type="checkbox"
                v-model="hasFinancing"
                data-cy="billingFinance"
                name="financingInterest"
                id="financingInterest"
            />
            <label for="financingInterest" v-html="this.financingDisclaimer.financingCheckbox"></label>
            <img v-if="!isCreditCard" :src="partnerLogoURL" :alt="partnerLogoName" />
        </div>

        <div  class="col-12 col-md-11 billing__button-wrapper">
            <stateful-btn
                :isLoading="isLoading && !errorDuringSubmission"
                :disabled="isLoading || (!taxesDone && disableSubmit)"
                :status="status"
                data-cy="orderSubmitButton"
            >
                <span>{{ $t("message.placeOrder") }}</span>
            </stateful-btn>

        </div>
        <div v-if="isTestEnv" class="col-12">
            <button
                @click="fillForm"
                class="button button--primary"
                type="button"
            >
                Fill Form
            </button>
            <br>
        <!-- <button
                v-if="hasCreditCardCheckout"
                @click="fillFormError"
                class="button button--primary"
                type="button"
            >
                Fill Form w/ Bad CC
            </button> -->
        </div>
    </form>
</template>

<script>
import { every } from 'underscore';
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import statefulBtn from '@/components/StatefulButton.vue';
import { formErrors } from '@/utils/setDataLayer';
import states from '@/store/data/states.json';
import provinces from '@/store/data/provinces.json';
import MobileInstallForm from '@/components/MobileInstallForm.vue';
import cardValidator from 'card-validator';
import { RudderstackCheckoutMixin } from '@/rudderstack/track/mixins/checkout';

export default {
	name: 'CheckoutForm',

	props: {
		taxesDone: {
			type: Boolean
		},
		submitForm: {
			required: true,
			type: Boolean
		},
		financingDisclaimer: {
			type: Object
		},
		financePartner: {
			type: Object
		},
		hasCreditCardCheckout: {
			required: true,
			type: Boolean
		},
		errorDuringSubmission: {
			type: Boolean,
			default: false
		},
		newAppointmentChosen: {
			type: Boolean,
			required: true
		},
		isWheelPackage: {
			type: Boolean,
			default: false,
			required: true
		},
		showFinancing: {
			type: Boolean,
			default: false,
			required: false
		}
	},

	mixins: [RudderstackCheckoutMixin],

	components: {
		'stateful-btn': statefulBtn,
		MobileInstallForm,
		states,
		provinces
	},

	data() {
		return {
			amount: 0,
			disableSubmit: true,
			isLoading: false,
			status: '',
			useBillingForMobileInstall: false,
			submitted: false,
			billing: {
				firstName: '',
				testFirstName: '',
				lastName: '',
				address: {
					line1: '',
					line2: '',
					city: '',
					state: '',
					zipCode: ''
				},
				deliveryAddress: {
					firstName: '',
					lastName: '',
					line1: '',
					line2: '',
					city: '',
					state: '',
					zipCode: ''
				},
				phone: '',
				email: ''
			},
			card: {
				number: '',
				expired: '',
				cvn: '',
				type: ''
			},
			ccErroring: {
				number: false,
				cvn: false,
				expired: false
			},
			hasFinancing: false
		};
	},

	watch: {
		// defaults billing as MI address
		'$store.state.appointment.appointment': function (newVal, oldVal) { //eslint-disable-line
			if (newVal.mobileInstallZip) {
				this.useBillingForMobileInstall = true;
				this.billing.address.zipCode = newVal.mobileInstallZip;
				this.billing.deliveryAddress.zipCode = newVal.mobileInstallZip;
			}
		},
		'useBillingForMobileInstall': function () {
			if (this.useBillingForMobileInstall) {
				this.billing.address.zipCode = this.appointment.mobileInstallZip;
			} else {
				this.billing.address.zipCode = '';
			}
		},
		newAppointmentChosen(newAppointment) {
			if (newAppointment) {
				this.isLoading = false;
			}
		}
	},

	methods: {
		...mapActions('cart/order', ['setConsumer']),
		...mapMutations('cart/order', ['setFinancing']),
		fillForm() {

			this.amount = 0;
			this.billing = {
				firstName: 'firstName',
				lastName: 'lastName',
				email: this.billing.email,
				address: {
					line1: '7012 Euclid Ave',
					city: 'Cleveland',
					state: 'OH',
					zipCode: '44103'
				},
				phone: '(216)432-0088'
			};

			this.card = {
				number: '4111111111111111',
				expired: '01/30',
				cvn: '123',
				type: '001'
			};
		},
		// fillFormError() {
		// 	this.fillForm();
		// 	// Use this to update the CC to simulate whatever error you want.
		// 	// This.amount = 1003; //returns INVALID MERCH ID decline
		// 	this.amount = 1003;
		// },
		setInstallZipForBilling() {
			this.billing.address.zipCode =
												this.details.consumer.deliveryAddress.zipCode;
		},
		setCardType(cardType) {
			const types = {
				'visa': '001',
				'mastercard': '002',
				'american-express': '003',
				'discover': '004'
			};

			if (types.hasOwnProperty(cardType)) {
				this.card.type = types[cardType];
			}
		},
		ccDecline() {
			// We got a decline
			this.isLoading = false;
			this.status = false;
			this.ccErroring.decline = true;

			// Sets dataLayer
			return formErrors({
				hasCardErrors: true
			});
		},
		ccDeclineHide() {
			this.ccErroring.decline = false;
		},
		validCardNumber() {
			const validNumber = cardValidator.number(this.card.number).isValid;

			this.ccErroring.number = !validNumber;

			return validNumber;
		},
		validCardExpiry() {
			const validExpiry = cardValidator.expirationDate(
				this.card.expired
			).isValid;

			this.ccErroring.expired = !validExpiry;

			return validExpiry;
		},
		validCardCVV() {
			const validCVV = this.creditCardType.toLowerCase() === 'american-express'
				? cardValidator.cvv(this.card.cvn, 4).isValid
				: cardValidator.cvv(this.card.cvn).isValid;

			this.ccErroring.cvn = !validCVV;

			return validCVV;
		},
		validateBeforeSubmit() {
			this.submitted = true;
			this.isLoading = true;

			let isCardValid = true;

			if (this.hasCreditCardCheckout) {
				isCardValid = every([
					this.validCardNumber(),
					this.validCardExpiry(),
					this.validCardCVV()
				]);

				this.trackPaymentInfoEntered();
			}

			this.setCardType(this.creditCardType);
			this.setFinancing(this.hasFinancing);
			// Validate the Consumer Address Inputs
			this.$validator.validateAll().catch((err) => {
				throw new Error(`validate form${ err}`);
			}).then((isConsumerInfoValid) => {
				// Valid consumer info and credit card. Credit card will not be vaild if it's blank.
				if (isConsumerInfoValid && isCardValid) {
					const consumerInfo = {
						firstName: this.billing.firstName,
						lastName: this.billing.lastName,
						address: {
							line1: this.billing.address.line1,
							line2: '',
							city: this.billing.address.city,
							state: this.billing.address.state,
							zipCode: this.billing.address.zipCode,
							country: 'US'
						},
						email: this.billing.email,
						phone: this.billing.phone.replace(/\D/g, ''),
						cellPhone: '',
						contactPreference: '',
						comments: this.appointment.comments,
						selectedFinancing: this.hasFinancing
					};

					if (this.isMobileInstallation) {
						consumerInfo.deliveryAddress = this.mobileInstallInfoChoice;
					}

					return this.setConsumer(consumerInfo).catch((err) => {
						throw new Error(`validate form${ err}`);
					}).then(() => {
						return this.$emit('validForm', {
							firstName: this.billing.firstName,
							lastName: this.billing.lastName,
							email: this.billing.email,
							phone: this.billing.phone.replace(/\D/g, '')
						});
					}).catch((err) => {
						throw new Error(`validate form${ err}`);
					});
				}

				// Invalid form or Credit Card
				this.isLoading = false;
				this.status = false;

				// Sets dataLayer
				return formErrors(this.createFormErrorObject(isConsumerInfoValid, isCardValid));
			}).catch((err) => {
				throw new Error(`validate form${ err}`);
			});
		},
		createFormErrorObject(isConsumerInfoValid, isCardValid) {
			let formErrorConfig = {
				hasFormErrors: !isConsumerInfoValid,
				formError: this.errors.all(),
				hasCardErrors: false
			};

			if (this.hasCreditCardCheckout) {
				formErrorConfig.hasCardErrors = !isCardValid;
				formErrorConfig.cardType = this.creditCardType;
				formErrorConfig.isExpired = !this.validCardExpiry();
				formErrorConfig.isValidCVV = this.validCardCVV();
			}

			return formErrorConfig;
		},
		uppercaseZip() {
			this.billing.address.zipCode = this.billing.address.zipCode.toUpperCase();
		}
	},

	computed: {
		...mapGetters('appointment', ['isMobileInstallation']),
		...mapState('cart/order', ['leadID', 'signature', 'details']),
		...mapState('cart', ['tiresInCart']),
		...mapState('appointment', ['appointment']),
		...mapState('initial', ['env', 'cybersource']),
		isTestEnv() {
			return !this.env.isProd;
		},
		signedFields() {
			return [
				'access_key',
				'profile_id',
				'transaction_uuid',
				'signed_field_names',
				'unsigned_field_names',
				'signed_date_time',
				'locale',
				'transaction_type',
				'reference_number',
				'amount',
				'currency',
				'payment_method',
				'bill_to_forename',
				'bill_to_surname',
				'bill_to_email',
				'bill_to_phone',
				'bill_to_address_line1',
				'bill_to_address_city',
				'bill_to_address_state',
				'bill_to_address_country',
				'bill_to_address_postal_code',
				'skip_decision_manager',
				'ship_to_forename',
				'ship_to_surname',
				'confirm_email',
				'override_custom_receipt_page'
			].join(',');
		},
		unsignedFields() {
			return [
				'auth_indicator',
				'card_number',
				'card_cvn',
				'card_type',
				'card_expirationmonth',
				'card_expirationyear',
				'card_expiry_date',
				'ccField',
				'copy_name'
			].join(',');
		},
		convertedDate() {
			return `${new Date().toISOString().split('.')[0]}Z`;
		},
		creditCardMonth() {
			const str = this.card.expired.split('/');

			return str[0];
		},
		creditCardType() {
			return this.card.number
				? cardValidator.number(this.card.number).card.type
				: '';
		},
		creditCardYear() {
			const str = this.card.expired.split('/');

			return `20${str[1]}`;
		},
		// eslint-disable-next-line complexity
		financingProductAllowed() {
			return this.tiresInCart.products && this.showFinancing && this.financingDisclaimer.financingCheckbox && !this.isMobileInstallation && !this.isWheelPackage;
		},
		statesList() {
			if (this.env.isCA) {
				return provinces;
			}

			return states;
		},
		getMethod() {
			if (this.hasCreditCardCheckout) {

				return 'POST';
			}

			return 'GET';
		},
		getAction() {
			if (this.hasCreditCardCheckout) {
				return this.cybersource.link;
			}

			// leave undefined for non-cc. It'll post to this document's url, which is correct.
			return null;

		},
		getTarget() {
			if (this.hasCreditCardCheckout) {
				return 'submitPaymentPost';
			}

			return '_self';
		},
		formattedCC() {
			return this.card.number.replace(/\s/g, '');
		},
		formattedExpireDate() {
			const expString = this.card.expired.split('/');

			return `${expString[0]}-20${expString[1]}`;
		},
		origin() {
			return `${window.location.origin}/app/checkout/receipt`;
		},
		isCreditCard() {
			return this.financingDisclaimer && this.financingDisclaimer.disclaimerText2;
		},
		partnerLogoName() {
			if (this.financingDisclaimer) {
				return `${this.$t('message.financing')} ${this.$t('message.availableThrough') } ${ this.financingDisclaimer.description}`;

			}

			return `finance partner logo`;
		},
		mobileInstallInfoChoice() {
			const { firstName, lastName } = this.billing;
			const { line1, city, state, zipCode } = this.billing.address;
			const billingInfo = {
				firstName,
				lastName,
				line1,
				line2: '',
				city,
				state,
				zipCode,
				country: 'US'
			};

			return this.useBillingForMobileInstall
				? billingInfo
				: this.details.consumer.deliveryAddress;
		},
		partnerLogoURL() {
			if (this.financingDisclaimer) {
				return `/dist/assets/finance/${this.financingDisclaimer.code}_logo.png`.toLowerCase();
			}

			return null;
		},
		profileId() {
			return this.cybersource.profileID;
		},
		transactionUUID() {
			return '0000-0000-0000-0000'.replace(/0/g, function () {
				return Math.floor(Math.random() * 16)
					.toString(16)
					.toUpperCase();
			});
		},
		zipMinLength() {
			if (this.env.isCA) {
				return 6;
			}

			return 5;
		},
		zipMaxLength() {
			if (this.env.isCA) {
				return 7;
			}

			return 5;
		},
		zipRegex() {
			// Check for country specific zip codes
			if (this.env.isCA) {
				return new RegExp(/^(?:[A-Z]\d[A-Z][ -]?\d[A-Z]\d)$/i);
			}

			// Default zip codes to US pattern
			return new RegExp(/^((\d{5}-\d{4})|(\d{5}))$/);
		}
	}
};
</script>
