<template>
    <div class="base-input" :class="{'base-input--error': error}">
        <input
            v-bind="{...$attrs}"
            :value="value"
            :type="type"
            @input="$emit('input', $event.target.value)"
            class="base-input__input"
            placeholder=" "
            v-mask="mask"
            :required="required"
            :aria-required="required"
        />

        <label class="base-input__label" :for="$attrs.id">
            {{ label }}
        </label>
        <small
            v-if="error"
            role="alert"
            class="base-input__error"
        >
            {{ error }}
        </small>
    </div>
</template>

<script>
export default {
	name: 'BaseInput',

	inheritAttrs: false,

	props: {
		label: {
			type: String,
			required: true
		},
		type: {
			type: String,
			default: 'text'
		},
		required: {
			type: Boolean,
			default: false
		},
		value: {
			type: String,
			required: true
		},
		error: {
			type: String,
			required: false
		},
		mask: {
			type: String,
			required: false,
			default: ''
		}
	}
};
</script>
