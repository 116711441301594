<template>
    <table
        :class="[calendarClass, 'vdp-datepicker__calendar']"
        v-show="showYearView"
        :style="calendarStyle"
        @mousedown.prevent
    >
        <slot name="beforeCalendarHeader"></slot>

        <tr class="calendar-navigation">
            <td>
                <button
                    @click="isRtl ? nextDecade() : previousDecade()"
                    class="prev"
                    :class="{'disabled': isLeftNavDisabled}"
                    :disabled="isLeftNavDisabled"
                    tabindex="0"
                    :aria-label="isRtl ? $t('message.next') : $t('message.prev')"
                >
                    &lt;
                </button>
            </td>

            <td class="calendar-navigation__month-button">
                <h2>{{ getPageDecade }}</h2>
            </td>


            <td>
                <button
                    @click="isRtl ? previousDecade() : nextDecade()"
                    class="next"
                    :class="{'disabled': isRightNavDisabled}"
                    :disabled="isRightNavDisabled"
                    tabindex="0"
                    :aria-label="isRtl ? $t('message.prev') : $t('message.next')"
                >
                    &gt;
                </button>
            </td>
        </tr>

        <tr>
            <td
                v-for="year in years"
                :key="year.timestamp"
                class="cell year-wrapper"
            >
                <button
                    class="year"
                    :class="{ 'selected': year.isSelected, 'disabled': year.isDisabled }"
                    tabindex="0"
                    :disabled="year.isDisabled"
                    @click.stop="selectYear(year)"
                    :aria-label="year.year"
                    :aria-pressed="year.isSelected ? 'true' : 'false'"
                >
                    {{ year.year }}
                </button>
            </td>
        </tr>
    </table>
</template>
<script>
import { makeDateUtils } from '@/components/Datepicker/DateUtils';

export default {
	props: {
		showYearView: Boolean,
		selectedDate: Date,
		pageDate: Date,
		pageTimestamp: Number,
		disabledDates: Object,
		highlighted: Object,
		calendarClass: [String, Object, Array],
		calendarStyle: Object,
		translation: Object,
		isRtl: Boolean,
		allowedToShowView: Function,
		useUtc: Boolean
	},
	computed: {
		years () {
			const d = this.pageDate;
			let years = [];
			// set up a new date object to the beginning of the current 'page'7
			let dObj = this.useUtc
				? new Date(Date.UTC(Math.floor(d.getUTCFullYear() / 10) * 10, d.getUTCMonth(), d.getUTCDate()))
				: new Date(Math.floor(d.getFullYear() / 10) * 10, d.getMonth(), d.getDate(), d.getHours(), d.getMinutes());

			for (let i = 0; i < 10; i++) {
				years.push({
					year: this.utils.getFullYear(dObj),
					timestamp: dObj.getTime(),
					isSelected: this.isSelectedYear(dObj),
					isDisabled: this.isDisabledYear(dObj)
				});
				this.utils.setFullYear(dObj, this.utils.getFullYear(dObj) + 1);
			}

			return years;
		},

		getPageDecade () {
			const decadeStart = Math.floor(this.utils.getFullYear(this.pageDate) / 10) * 10;
			const decadeEnd = decadeStart + 9;
			const yearSuffix = this.translation.yearSuffix;


			return `${decadeStart} - ${decadeEnd}${yearSuffix}`;
		},

		// Is the left hand navigation button disabled?
		isLeftNavDisabled () {
			return this.isRtl
				? this.isNextDecadeDisabled(this.pageTimestamp)
				: this.isPreviousDecadeDisabled(this.pageTimestamp);
		},

		// Is the right hand navigation button disabled?

		isRightNavDisabled () {
			return this.isRtl
				? this.isPreviousDecadeDisabled(this.pageTimestamp)
				: this.isNextDecadeDisabled(this.pageTimestamp);
		}
	},
	data () {
		const constructedDateUtils = makeDateUtils(this.useUtc);


		return {
			utils: constructedDateUtils
		};
	},
	methods: {
		// eslint-disable-next-line consistent-return
		selectYear (year) {
			if (year.isDisabled) {
				return false;
			}
			this.$emit('selectYear', year);
		},
		changeYear (incrementBy) {
			let date = this.pageDate;

			this.utils.setFullYear(date, this.utils.getFullYear(date) + incrementBy);
			this.$emit('changedDecade', date);
		},
		// eslint-disable-next-line consistent-return
		previousDecade () {
			if (this.isPreviousDecadeDisabled()) {
				return false;
			}
			this.changeYear(-10);
		},
		isPreviousDecadeDisabled () {
			if (!this.disabledDates || !this.disabledDates.to) {
				return false;
			}
			const disabledYear = this.utils.getFullYear(this.disabledDates.to);
			const lastYearInPreviousPage = Math.floor(this.utils.getFullYear(this.pageDate) / 10) * 10 - 1;


			return disabledYear > lastYearInPreviousPage;
		},
		// eslint-disable-next-line consistent-return
		nextDecade () {
			if (this.isNextDecadeDisabled()) {
				return false;
			}
			this.changeYear(10);
		},
		isNextDecadeDisabled () {
			if (!this.disabledDates || !this.disabledDates.from) {
				return false;
			}
			const disabledYear = this.utils.getFullYear(this.disabledDates.from);
			const firstYearInNextPage = Math.ceil(this.utils.getFullYear(this.pageDate) / 10) * 10;


			return disabledYear < firstYearInNextPage;
		},

		// Whether the selected date is in this year

		isSelectedYear (date) {
			return this.selectedDate && this.utils.getFullYear(this.selectedDate) === this.utils.getFullYear(date);
		},

		// Whether a year is disabled
		// eslint-disable-next-line complexity
		isDisabledYear (date) {
			let disabledDates = false;

			if (typeof this.disabledDates === 'undefined' || !this.disabledDates) {
				return false;
			}

			if (typeof this.disabledDates.to !== 'undefined' && this.disabledDates.to) {
				if (this.utils.getFullYear(date) < this.utils.getFullYear(this.disabledDates.to)) {
					disabledDates = true;
				}
			}
			if (typeof this.disabledDates.from !== 'undefined' && this.disabledDates.from) {
				if (this.utils.getFullYear(date) > this.utils.getFullYear(this.disabledDates.from)) {
					disabledDates = true;
				}
			}

			if (typeof this.disabledDates.customPredictor === 'function' && this.disabledDates.customPredictor(date)) {
				disabledDates = true;
			}

			return disabledDates;
		}
	}
};
</script>
