import { isEmpty } from 'underscore';

const hasStarMark = (tire) => {
	const shortTireMark = tire.oemTireMarkShortDescription.toLowerCase();
	const isStarMarked = shortTireMark === 'bmw' || shortTireMark === 'mini';

	return isStarMarked ? '\u2605' : tire.oemTireMarkCode;
};

const fullTireMarkDescription = (tire) => {
	return isEmpty(tire.oemTireMarkDescription) ?
		'None' :
		`${tire.oemTireMarkDescription} (${hasStarMark(tire)})`;
};

export {
	fullTireMarkDescription
};
