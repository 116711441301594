import axios from 'axios';
import { format, addHours, isAfter } from 'date-fns';

// eslint-disable-next-line complexity
const fetchTimeSlots = async ({ startDate, make, transportationOption, year, model, qty, deliveryDate }) => {
	const { data: fetchedTimeslots, error } = await axios.get('/service/timeslots', { params: {
		startDate: format(startDate, 'yyyy-MM-dd'),
		skipFallback: transportationOption === 13 ? true : '',
		make,
		transportationOption,
		year,
		model,
		qty
	}});


	if (error) {
		console.log('Error fetching timeslots:', error);
		axios.get('/service/errorlog', {
			params: {
				message: 'Error - fetching timeslots',
				loc: error.errorLocation,
				error: error.Error,
				startDate: format(startDate, 'yyyy-MM-dd'),
				deliveryDate: format(deliveryDate, 'yyyy-MM-dd'),
				make: make,
				model: model,
				year: year,
				qty: qty,
				transportationOption: transportationOption

			}
		});
		throw new Error('Error fetching timeslots:', error);
	}


	if ( typeof fetchedTimeslots !== 'object' || fetchedTimeslots.dates === null || fetchedTimeslots.dates.length === 0) {
		axios.get('/service/errorlog', {
			params: {
				message: 'Error - no time slots available this month',
				loc: 'fetchTimeSlots',
				startDate: format(startDate, 'yyyy-MM-dd'),
				deliveryDate: format(deliveryDate, 'yyyy-MM-dd'),
				make: make,
				model: model,
				year: year,
				qty: qty,
				transportationOption: transportationOption

			}
		});
		throw new Error('no time slots available this month');
	}


	const firstDateWithOffset = fetchedTimeslots.dates.find(date => !date.isClosed && date.timeslots.length > 0 && date.timeslots.find(slot => slot.offset));

	if ( typeof firstDateWithOffset !== 'object' || typeof firstDateWithOffset.timeslots !== 'object' || firstDateWithOffset.timeslots.length === 0) {
		axios.get('/service/errorlog', {
			params: {
				message: 'Error - no time slots available for dates this month',
				loc: 'fetchTimeSlots',
				startDate: format(startDate, 'yyyy-MM-dd'),
				deliveryDate: format(deliveryDate, 'yyyy-MM-dd'),
				make: make,
				model: model,
				year: year,
				qty: qty,
				transportationOption: transportationOption

			}
		});
		throw new Error('no time slots available this month');
	}


	const { offset } = firstDateWithOffset.timeslots.find(slot => slot.offset);

	const timeSlots = {
		interval: fetchedTimeslots.interval,
		provider: fetchedTimeslots.provider,
		openDates: [],
		closedDates: []
	};

	fetchedTimeslots.dates.forEach(date => {
		if (date.isClosed || !date.timeslots.length) {
			return timeSlots.closedDates.push(new Date(`${date.date}T12:00:00`));
		}

		date.timeslots.forEach(slot => {
			const convertedDeliveryDate = addHours(deliveryDate, offset * -1);
			const slotDate = addHours( new Date(`${date.date}T${slot.time}:00.000Z`), offset * -1);

			slot.isAvail = isAfter(slotDate, convertedDeliveryDate);
		});

		if (!date.timeslots.find(slot => slot.isAvail)) {
			return timeSlots.closedDates.push(new Date(`${date.date}T12:00:00`));
		}

		date.timeslots = date.timeslots.map(slot => {
			const slotTime = slot.time.charAt(0) === '0' ? slot.time.slice(1) : slot.time;
			// [0] is hours, [1] is minutes for the below array.
			const slotHoursAndMinutes = slotTime.split(':');
			const afterNoonSlotHour = (parseInt(slotHoursAndMinutes[0]) - 12) > 0 ? parseInt(slotHoursAndMinutes[0]) - 12 : slotHoursAndMinutes[0]; //eslint-disable-line

			slot.time = parseInt(slotHoursAndMinutes[0]) < 12 ? `${slotTime}am` : `${afterNoonSlotHour}:${slotHoursAndMinutes[1]}pm`; //eslint-disable-line

			// We are going to create a display time to use for i18n translation purposes
			// We don't care about the date portion for this section so we are using the current date
			// This is here to allow us to customize the display without affecting the underlying logic
			const todaysDate = new Date();

			todaysDate.setHours(slotHoursAndMinutes[0], slotHoursAndMinutes[1]);
			slot.displayTime = todaysDate.toJSON();

			return slot;
		});

		const amSlots = date.timeslots.filter(slot => {
			return slot.time.includes('am');
		});

		const pmSlots = date.timeslots.filter(slot => {
			return slot.time.includes('pm');
		});

		date.timeslots = {
			am: amSlots,
			pm: pmSlots
		};

		date.date = new Date(`${date.date}T12:00:00`);

		return timeSlots.openDates.push(date);
	});

	if (timeSlots.openDates.length < 1) {
		axios.get('/service/errorlog', {
			params: {
				message: 'Error - no open time slots available this month',
				loc: 'fetchTimeSlots',
				startDate: format(startDate, 'yyyy-MM-dd'),
				deliveryDate: format(deliveryDate, 'yyyy-MM-dd'),
				make: make,
				model: model,
				year: year,
				qty: qty,
				transportationOption: transportationOption

			}
		});
		throw new Error('no time slots available this month');
	}


	return timeSlots;
};

export default fetchTimeSlots;
