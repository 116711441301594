<template>
    <ul v-if="totalTabs.length > 1" class="pagination pagination--center">
        <li :class="['pagination__item', {hidden: tab === 1}]">
            <button class="pagination__link" @click="prev">{{ $t("message.prev") }}</button>
        </li>
        <li :class="['pagination__item', {active: number === tab}]" v-for="(number, index) in totalTabs" :key="index">
            <button
                :aria-label="`${$t('message.goToPage')} ${number} ${$t('message.of')} ${$t('message.dealerResults')}`"
                :aria-current="number === tab"
                class="pagination__link"
                @click="paginate(number)">
                {{number}}
            </button>
        </li>
        <li :class="['pagination__item', {hidden: tab === totalTabs.length}]">
            <button :aria-label="nextMessage" class="pagination__link" @click="next">{{ nextMessage }}</button>
        </li>
    </ul>
</template>

<script>
import _ from 'underscore';

export default {
	props: {
		itemCount: {
			type: Number,
			required: true
		},
		itemsPerPage: {
			type: Number,
			default: 5
		}
	},
	computed: {
		totalTabs() {
			return _.range(1, (1 + (this.itemCount / this.itemsPerPage)));
		},
		nextMessage() {
			return this.$t('message.next');
		}
	},
	data() {
		return {
			tab: 1
		};
	},
	methods: {
		paginate(number) {
			this.tab = number;
			this.$emit('paginate', {
				clickedSet: (number * this.itemsPerPage),
				itemsPerPage: this.itemsPerPage
			});
		},
		next() {
			if (this.tab < this.totalTabs.length) {
				this.paginate(this.tab + 1);
			}
		},
		prev() {
			if (this.tab > 1) {
				this.paginate(this.tab - 1);
			}
		}
	}
};
</script>
