import * as actions from './actions.js';
import taxes from './modules/taxes/index';
import order from './modules/order/index';
import { extend } from 'underscore';

const initialState = () => {
	return {
		tiresInCart: [],
		servicesInCart: [],
		warehouses: []
	};
};

const store = {
	namespaced: true,
	modules: {
		taxes,
		order
	},
	state: initialState(),
	// Mutations are commited and must be synchronous
	mutations: {
		// Do a quick reset of the cart store, since we reset the session
		// We should also probably reset the store related to it
		resetCart(state) {
			extend(state, initialState());
		},
		setTiresInCart(state, payload) {
			state.tiresInCart = payload;
		},
		setServicesInCart(state, payload) {
			state.servicesInCart = payload;
		},
		setWarehouses(state, payload) {
			state.warehouses = payload;
		}
	},
	actions
};

export default store;
