<template>
    <div role="tabpanel">
        <form class="search__form" :action="nextLocation" @submit="setDataLayer" :target="target">

            <div class="search__bysize-wrapper">
                <div class="search__bysize-content"
                     v-if="showSizeDisclaimer"
                     v-html="sizeDisclaimerContent">
                </div>

                <ul class="search__bysize"
                    :class="{ 'search__bysize--padding': showSizeDisclaimer }">

                    <li class="search__make-model search__make-model--margin-top">
                        <label class="search__label" for="makes">
                            {{ $t("message.make") }}
                        </label>
                        <select class="search__select"
                                id="makes"
                                name="make"
                                :disabled="!makes.length"
                                v-model="make"
                                @change="handleMakeChange"
                                data-cy="searchBySizeMake">
                            <option class="search__disabled-option" selected disabled hidden>Select Make...</option>
                            <option :value="item" v-for="item in makes" :key="item">
                                {{ item }}
                            </option>
                        </select>
                    </li>

                    <li class="search__width">
                        <label class="search__label" for="width">
                            {{ $t("message.width") }}
                        </label>
                        <select class="search__select search__select--uppercase"
                                id="width"
                                name="width"
                                :disabled="!widths.length"
                                v-model="width"
                                @change="handleWidthChange"
                                data-cy="searchBySizeWidth">
                            <option class="search__disabled-option" selected disabled hidden>Select Width...</option>
                            <option :value="item" v-for="item in widths" :key="item">
                                {{ item }}
                            </option>
                        </select>
                    </li>

                    <li class="search__ratio">
                        <label class="search__label" for="ratio">
                            {{ $t("message.ratio") }}
                        </label>
                        <select class="search__select"
                                id="ratio"
                                name="ratio"
                                :disabled="!ratios.length"
                                v-model="ratio"
                                @change="handleRatioChange"
                                data-cy="searchBySizeRatio">
                            <option class="search__disabled-option" selected disabled hidden>Select Ratio...</option>
                            <option :value="item" v-for="item in ratios" :key="item">
                                {{ item }}
                            </option>
                        </select>
                    </li>

                    <li class="search__diameter">
                        <label class="search__label" for="diameter">
                            {{ $t("message.tireDiameter") }}
                        </label>
                        <select class="search__select"
                                id="diameter"
                                name="diameter"
                                :disabled="!diameters.length"
                                v-model="diameter"
                                @change="handleDiameterChange"
                                data-cy="searchBySizeDiameter">
                            <option class="search__disabled-option" selected disabled hidden>
                                {{ $t("message.selectDiameter") }}
                            </option>
                            <option :value="item" v-for="item in diameters" :key="item">
                                {{ item }}
                            </option>
                        </select>
                    </li>

                    <li class="search__button">
                        <stateful-button
                            :disabled="!canSubmit"
                            :isLoading="isLoading"
                            class="button button--primary button--full"
                            type="submit"
                            data-cy="searchBySizeButton">
                            {{ $t("message.shopNow") }}
                        </stateful-button>
                        <div class="search__form-text">
                            {{ $t("message.allFieldsRequired") }}
                        </div>
                    </li>

                    <!-- Sets our bySize query parameter -->
                    <input name="isbysize" class="hidden" type="checkbox" v-model="bySize" aria-label="bySize"/>
                    <input name="uid" type="hidden" :value="dealerUID" />
                    <input name="defaultTransport" type="hidden" :value="defaultTransport" />
                    <input v-if="referrer" name="referrer" type="hidden" :value="referrer" />
                    <input v-if="analyticsId" name="analyticsId" type="hidden" :value="analyticsId" />
                    <input name="tiresize" type="hidden" :value="selectedSize" />
                </ul>
            </div>

            <div class="search__tire-img-wrapper">
                <img
                    class="search__tire-img"
                    :class="{ 'search__tire-img--extrawidth': showSizeDisclaimer }"
                    src="/dist/assets/tire-size.png"
                    :alt='$t("message.tireWall")'>
            </div>

        </form>
    </div>
</template>

<script>
import { extend, isEmpty } from 'underscore';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { bySizeSearchLoaded } from '@/utils/setDataLayer/index';
import StatefulButton from '@/components/StatefulButton.vue';
import { RudderstackSearchMixin } from '@/rudderstack/track/mixins/search';

export default {
	props: {
		query: {
			type: Object,
			required: true
		},
		target: {
			type: String,
			required: true
		}
	},

	mixins: [RudderstackSearchMixin],

	components: {
		StatefulButton
	},

	data() {
		return {
			diameter: '',
			make: '',
			ratio: '',
			width: '',
			bySize: true,
			nextLocation: '/app/dealer',
			isLoading: false
		};
	},

	watch: {
		makes(newMakes) {
			this.setState({
				make: newMakes[0]
			});
		},
		make(newMake) {
			this.setState({
				make: newMake,
				width: '',
				ratio: '',
				diameter: ''
			});
		},
		width(newWidth) {
			this.setState({
				width: newWidth,
				ratio: '',
				diameter: ''
			});
		},
		ratio(newRatio) {
			this.setState({
				ratio: newRatio,
				diameter: ''
			});
		},
		diameter(newDiameter) {
			// If we have a uid in the query string this is a 99.9% chance that the
			// User is coming from a dealer, so we will be skipping the dealer page
			if (this.query.uid && newDiameter) {

				// Call our store action in order to get some tire sizes
				// This will confirm if we need to go to our verify page or not
				this.fetchTireSizes({
					productVehicleMake: this.make,
					productTireWidth: this.width,
					productTireRatio: this.ratio,
					productTireDiameter: newDiameter,
					uid: this.query.uid
				}).then(() => {
					// If we have multiple sizes come back then we need to use the
					// Verify page in order to confirm which size we are using
					if (this.needsVerified) {
						this.nextLocation = '/app/verify';
					} else if (this.noTires) {
						// If no tires return send them off to our no products page directly
						this.nextLocation = '/app/no-products';
					} else {
						// Otherwise go ahead and go to the product page
						// Luckily if there is only 1 tire size it will be set
						// By our above action when it's called
						this.nextLocation = '/app/product';
					}

				});
			}
		}
	},

	methods: {
		...mapActions('content/shopBySize', [
			'fetchSizeDisclaimerContent'
		]),
		...mapActions('products/verify', [
			'fetchTireSizes'
		]),
		...mapActions('carline', ['getTireSizeCatalog', 'getCarlineCatalogMakes']),
		...mapMutations('modal', ['consentToCookies']),
		setState(state) {
			extend(this, state);
		},
		setDataLayer() {
			const tireDetails = {
				make: this.make,
				width: this.width,
				ratio: this.ratio,
				diameter: this.diameter,
				size: `${this.width}/${this.ratio}/${this.diameter}`
			};

			this.isLoading = true;
			this.consentToCookies(true);

			this.trackSearchCompleted({
				'search_type': 'Size',
				...tireDetails
			});
			bySizeSearchLoaded(tireDetails);
		},
		handleMakeChange() {
			this.trackSearchOptionClicked({
				'search_term': 'make',
				'search_option_selected': this.make
			});
		},
		handleWidthChange() {
			if (!this.width || this.width === '') return;

			this.trackSearchOptionClicked({
				'search_term': 'width',
				'search_option_selected': this.width
			});
		},
		handleRatioChange() {
			if (!this.ratio || this.ratio === '') return;

			this.trackSearchOptionClicked({
				'search_term': 'ratio',
				'search_option_selected': this.ratio
			});
		},
		handleDiameterChange() {
			if (!this.diameter || this.diameter === '') return;

			this.trackSearchOptionClicked({
				'search_term': 'diameter',
				'search_option_selected': this.diameter
			});
		}
	},

	computed: {
		...mapGetters('carline', [
			'tireSizes',
			'tireSizeMakes'
		]),
		...mapState('products/verify', [
			'needsVerified',
			'selectedSize'
		]),
		...mapState('products', [
			'noTires'
		]),
		...mapState('content/shopBySize', [
			'sizeDisclaimerContent'
		]),
		dealerUID() {
			return this.query.uid;
		},
		isNational() {
			return Boolean(!this.query.uid);
		},
		defaultTransport() {
			return this.query.defaultTransport;
		},
		referrer() {
			return this.query.referrer;
		},
		analyticsId() {
			return this.query.analyticsId;
		},
		runAction() {
			if (this.query.uid) {
				if (this.needsVerified) {
					return '/app/verify';
				}

				return '/app/product';
			}

			return '/app/dealer';
		},
		makes() {
			if (!this.tireSizeMakes.length) {
				return [];
			}

			return this.tireSizeMakes;
		},
		widths() {
			if (!this.tireSizes.length) {
				return [];
			}

			return [...new Set(this.tireSizes.map(tire => tire.width).filter(width => width !== ''))].sort();
		},
		ratios() {
			if (!this.width) {
				return [];
			}
			const tiresToCheck = this.tireSizes.filter(tire => tire.width === this.width);

			return [...new Set(tiresToCheck.map(tire => tire.aspectRatio))].sort();
		},
		diameters() {
			if (!this.width && !this.ratio) {
				return [];
			}
			const tiresToCheck = this.tireSizes.filter(tire => tire.aspectRatio === this.ratio && tire.width === this.width);

			return [...new Set(tiresToCheck.map(tire => tire.rimDiameter))];
		},
		canSubmit() { // eslint-disable-line complexity
			if (this.query.uid) {
				return Boolean(this.make && this.width && this.ratio && this.diameter && this.finalCheck); // eslint-disable-line complexity
			}

			return Boolean(this.make && this.width && this.ratio && this.diameter);
		},
		finalCheck() {
			return this.selectedSize || this.needsVerified || this.noTires;
		},
		showSizeDisclaimer() {
			return !isEmpty(this.sizeDisclaimerContent);
		}
	},

	created() {
		this.fetchSizeDisclaimerContent();
		this.getTireSizeCatalog({
			languageCode: '',
			languageLocale: ''
		});
		this.getCarlineCatalogMakes({ year: 0 });

	}
};
</script>
