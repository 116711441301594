import { last, find } from 'underscore';

export const setWarehouseQty = ( warehouseArr, qty ) => {
	let qtyToCheck = qty;
	const w = warehouseArr.map( warehouse => {
		if ( warehouse.warehouseQty >= qtyToCheck) {
			warehouse.warehouseQty = qtyToCheck;
			qtyToCheck = 0;
		} else {
			qtyToCheck -= warehouse.warehouseQty;
		}

		return warehouse;
	});

	return w.filter(warehouse => warehouse.warehouseQty);
};

// Verify Tire Sizes and all that lovely stuff
// Sanatize cleans out the letters in a tire size, but keeps track of both
export const sanitize = str => {
	return {
		noLetter: str.replace(/[A-Z]/gi, ''),
		noPrefix: str.replace(/(^|,)[A-Z]*/gi, '$1')
	};
};

// Local Best Seller
export const findLocalBestSeller = pList => {
	let bestRank = 9999;
	let currBestSeller = {};

	// Looks for the best ranked (high) product(s)
	// if there are matching ranks, find the cheapest one
	// return that one, as the best seller

	pList.forEach(prodObj => { // eslint-disable-line complexity
		const currProd = last(prodObj.products);
		let currentRank = Number(currProd.popularRank);

		if (currBestSeller.products &&
			currentRank === bestRank &&
			currProd.installPrice < last(currBestSeller.products).installPrice) {
			currBestSeller = prodObj;
		}

		if (currentRank !== 0 && currentRank < bestRank) {
			bestRank = currentRank;
			currBestSeller = prodObj;
		}
	});

	return currBestSeller;
};

export const localBestSeller = prodList => {
	const lbs = findLocalBestSeller(prodList);
	const len = prodList.length;
	let i = 0;

	for (i; i < len; i++) {
		if (last(lbs.products) && last(prodList[i].products).productId === last(lbs.products).productId) {
			prodList[i].localBestSeller = true;
			break;
		}
	}

	return prodList;

};
// END Local Best Seller

// PQR Search functionality
export const findProductByID = (prods, id) => {
	if (Array.isArray(prods)) {
		return find(prods, (prod) => {

			return prod.products && prod.products[0].productId === id;
		});
	}
	const { loose, staggered } = prods;

	if (staggered.length) {
		return find(staggered, ({ products }) => products[0].productId === id);
	}

	return find(loose, ({ products }) => products[0].productId === id);
};

// PQR Search functionality... but by sku
export const findProductBySKU = (prods, sku) => {
	if (Array.isArray(prods)) {
		return find(prods, ({ products }) => products[0].sku === sku);
	}

	const { loose, staggered } = prods;

	if (staggered.length) {
		const [frontSku] = sku.split(',');

		return find(staggered, ({ products }) => products[0].sku === frontSku);
	}

	return find(loose, ({ products }) => products[0].sku === sku);
};
// END PQR Search functionality

export const setupProducts = (commit, getters, data) => { // eslint-disable-line
	const {staggered, loose, wpk } = data.filteredPQR;

	if (wpk.length) {
		commit('wpks', wpk);
	}

	if (staggered.length) {
		commit('isStaggered', true);
		commit('noTires', false);

		return commit('allProducts', (localBestSeller(staggered)));
	}

	if (loose.length) {
		commit('isStaggered', false);
		commit('noTires', false);

		return commit('allProducts', (localBestSeller(loose)));
	}

	commit('allProducts', {});

	return commit('noTires', true);
};

export const checkForTruck = ({loose, staggered}) => {
	const types = ['TRK', 'COM'];

	if (staggered.length) {
		return find(staggered, ({products}) => {
			const [front, rear] = products;

			return types.indexOf(front.intendedUseCode.toUpperCase()) !== -1 &&
				types.indexOf(rear.intendedUseCode.toUpperCase()) !== -1;
		});
	}

	return find(loose, ({products}) => types.indexOf(products[0].intendedUseCode.toUpperCase()) !== -1);
};
