<template>
    <svg v-if="direction === 'right'" :width="width" :height="height" viewBox="0 0 24 24" fill="none">
        <path stroke-linejoin="round" stroke-linecap="round" stroke-width="1" stroke="#221b38" d="M8 20L16 12L8 4"></path>
    </svg>
    <svg v-else :width="width" :height="height" viewBox="0 0 24 24" fill="none">
        <path stroke-linejoin="round" stroke-linecap="round" stroke-width="1" stroke="#221b38" d="M16 20L8 12L16 4"></path>
    </svg>
</template>

<script>
export default {
	name: 'IconChevron',

	props: {
		direction: {
			type: String,
			default: 'right'
		},
		width: {
			type: String,
			default: '24'
		},
		height: {
			type: String,
			default: '24'
		}
	}
};
</script>

