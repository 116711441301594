<template>
    <div class="form-group col-sm-4">
        <label class="list__label" v-if="!useBillingForMobileInstall"> {{ $t('message.mobileInstallationAddress')}}
            <ul class="input__list">
                <li class="input billing--input">
                    <label class="input__label">
                        {{ $t("message.fName") }}
                        <input
                            name="install_to_forename"
                            type="text"
                            maxlength="100"
                            v-model="mobileInstall.firstName"
                            data-vv-name="firstName"
                            v-validate="{ required: true, regex: /^[a-zA-Z]+[a-zA-Z .,'-]*$/ }"
                            :class="['input__field', {'input__field--error': errors.has('firstName')}]">
                    </label>
                    <small v-if="errors.has('firstName')" class="input__error-reason">
                        {{ (errors.first('firstName')) }}
                    </small>
                </li>
                <li class="input billing--input">
                    <label class="input__label" for="bill_to_surname">
                        {{ $t("message.lName") }}
                        <input
                            name="install_to_surname"
                            type="text"
                            maxlength="100"
                            v-model="mobileInstall.lastName"
                            data-vv-name="lastName"
                            v-validate="{ required: true, regex: /^[a-zA-Z]+[a-zA-Z .,'-]*$/ }"
                            :class="['input__field', {'input__field--error': errors.has('lastName')}]">
                    </label>
                    <small v-if="errors.has('lastName')" class="input__error-reason">
                        {{ (errors.first('lastName')) }}
                    </small>
                </li>
                <li class=' input billing--input'>
                    <label class="input__label">
                        {{ $t('message.installAddress') }}
                        <input
                            type="text"
                            v-model="mobileInstall.line1"
                            maxlength="100"
                            data-vv-name="mobileInstallationAddress"
                            name="mobile_install_address"
                            v-validate="{ required: true, min: 3, regex: /^[a-zA-Z0-9 ,'\-#.]*$/ }"
                            :class="['input__field', {'input__field--error': errors.has('mobileInstallationAddress')}] "/>
                    </label>
                    <small v-if="errors.has('mobileInstallationAddress')" class="input__error-reason">
                        {{ errors.first('mobileInstallationAddress') }}
                    </small>
                </li>
                <li class="input billing--input">
                    <label class="input__label">
                        {{ $t("message.city") }}
                        <input
                            name="install_to_address_city"
                            type="text"
                            maxlength="100"
                            v-model="mobileInstall.city"
                            data-vv-name="city"
                            v-validate="{ required: true, regex: /^[a-zA-Z]+(?:[ -][a-zA-Z]+)*$/ }"
                            :class="['input__field', {'input__field--error': errors.has('city')}]">
                    </label>
                    <small v-if="errors.has('city')" class="input__error-reason">
                        {{ errors.first('city') }}
                    </small>
                </li>

                <li class="input billing--input-small">
                    <label class="input__label">
                        {{ $t("message.state") }}
                        <select
                            name="install_to_address_state"
                            v-model="mobileInstall.state"
                            data-vv-name="state"
                            v-validate="'required'"
                            :class="['input__field', 'input__state', {'input__field--error': errors.has('state')}]">
                            <option v-for="state in statesList" :value="state" :key="state.id"> {{state}}</option>
                        </select>
                    </label>
                    <small v-if="errors.has('state')" class="input__error-reason">
                        {{ $t("message.stateError") }}
                    </small>
                </li>
                <li class='input billing--input-small'>
                    <label class="input__label">
                        {{ $t('message.zipCode') }}
                    </label>
                    <input
                        type="text"
                        v-model="consumerZip"
                        maxlength="5"
                        data-vv-name="zipCode"
                        name="install_to_address_zip"
                        :readonly="true"
                        v-validate="'required|digits:5'"
                        :class="['input__field', {'input__field--error': errors.has('zipCode')}] "/>
                    <small v-if="errors.has('zipCode')" class="input__error-reason">
                        {{ (errors.first('zipCode')) }}
                    </small>
                </li>
            </ul>
        </label>
    </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import states from '@/store/data/states.json';

export default {
	data() {
		return {
			mobileInstall: {
				firstName: '',
				lastName: '',
				line1: '',
				line2: '',
				city: '',
				state: '',
				zipCode: ''
			}
		};
	},
	inject: {
		$validator: '$validator'
	},
	props: {
		checkoutFormSubmitted: Boolean,
		useBillingForMobileInstall: Boolean
	},
	methods: {
		...mapMutations('cart/order', ['setMobileInstallInfo'])
	},
	computed: {
		...mapState('cart/order', ['details']),
		...mapState('appointment', ['appointment']),
		statesList() {
			return states;
		},
		consumerZip() {
			this.mobileInstall.zipCode = this.appointment.mobileInstallZip;

			return this.mobileInstall.zipCode;
		}
	},
	watch: {
		'checkoutFormSubmitted': function() {
			this.setMobileInstallInfo(this.mobileInstall);
		}
	}
};
</script>
