import Language from '../Language';

export default new Language(
	'Croatian',
	['Siječanj', 'Veljača', 'Ožujak', 'Travanj', 'Svibanj', 'Lipanj', 'Srpanj', 'Kolovoz', 'Rujan', 'Listopad', 'Studeni', 'Prosinac'],
	['Sij', 'Velj', 'Ožu', 'Tra', 'Svi', 'Lip', 'Srp', 'Kol', 'Ruj', 'Lis', 'Stu', 'Pro'],
	['Ned', 'Pon', 'Uto', 'Sri', 'Čet', 'Pet', 'Sub']
)
// eslint-disable-next-line
;
