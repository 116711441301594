import Language from '../Language';

export default new Language(
	'Czech',
	['leden', 'únor', 'březen', 'duben', 'květen', 'červen', 'červenec', 'srpen', 'září', 'říjen', 'listopad', 'prosinec'],
	['led', 'úno', 'bře', 'dub', 'kvě', 'čer', 'čec', 'srp', 'zář', 'říj', 'lis', 'pro'],
	['ne', 'po', 'út', 'st', 'čt', 'pá', 'so']
)
// eslint-disable-next-line
;
