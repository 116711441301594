<template>
    <div class="vdp-datepicker" :class="[wrapperClass, isRtl ? 'rtl' : '']">
        <date-input
            :selectedDate="selectedDate"
            :resetTypedDate="resetTypedDate"
            :format="format"
            :translation="translation"
            :inline="inline"
            :id="id"
            :name="name"
            :refName="refName"
            :openDate="openDate"
            :placeholder="placeholder"
            :inputClass="inputClass"
            :typeable="typeable"
            :clearButton="clearButton"
            :clearButtonIcon="clearButtonIcon"
            :calendarButton="calendarButton"
            :calendarButtonIcon="calendarButtonIcon"
            :calendarButtonIconContent="calendarButtonIconContent"
            :disabled="disabled"
            :required="required"
            :bootstrapStyling="bootstrapStyling"
            :use-utc="useUtc"
            @showCalendar="showCalendar"
            @closeCalendar="close"
            @typedDate="setTypedDate"
            @clearDate="clearDate">
            <slot name="afterDateInput" slot="afterDateInput"></slot>
        </date-input>

        <!-- Day View -->
        <picker-day
            v-if="allowedToShowView('day')"
            :pageDate="pageDate"
            :selectedDate="selectedDate"
            :showDayView="showDayView"
            :fullMonthName="fullMonthName"
            :allowedToShowView="allowedToShowView"
            :disabledDates="disabledDates"
            :highlighted="highlighted"
            :calendarClass="calendarClass"
            :calendarStyle="calendarStyle"
            :translation="translation"
            :pageTimestamp="pageTimestamp"
            :isRtl="isRtl"
            :mondayFirst="mondayFirst"
            :dayCellContent="dayCellContent"
            :use-utc="useUtc"
            @changedMonth="handleChangedMonthFromDayPicker"
            @selectDate="selectDate"
            @showMonthCalendar="showMonthCalendar"
            @selectedDisabled="selectDisabledDate">
            <slot name="beforeCalendarHeader" slot="beforeCalendarHeader"></slot>
        </picker-day>

        <!-- Month View -->
        <picker-month
            v-if="allowedToShowView('month')"
            :pageDate="pageDate"
            :selectedDate="selectedDate"
            :showMonthView="showMonthView"
            :allowedToShowView="allowedToShowView"
            :disabledDates="disabledDates"
            :calendarClass="calendarClass"
            :calendarStyle="calendarStyle"
            :translation="translation"
            :isRtl="isRtl"
            :use-utc="useUtc"
            @selectMonth="selectMonth"
            @showYearCalendar="showYearCalendar"
            @changedYear="setPageDate">
            <slot name="beforeCalendarHeader" slot="beforeCalendarHeader"></slot>
        </picker-month>

        <!-- Year View -->
        <picker-year
            v-if="allowedToShowView('year')"
            :pageDate="pageDate"
            :selectedDate="selectedDate"
            :showYearView="showYearView"
            :allowedToShowView="allowedToShowView"
            :disabledDates="disabledDates"
            :calendarClass="calendarClass"
            :calendarStyle="calendarStyle"
            :translation="translation"
            :isRtl="isRtl"
            :use-utc="useUtc"
            @selectYear="selectYear"
            @changedDecade="setPageDate">
            <slot name="beforeCalendarHeader" slot="beforeCalendarHeader"></slot>
        </picker-year>
    </div>
</template>
<script>
import en from '@/components/Datepicker/locale/translations/en';
import DateInput from '@/components/Datepicker/DateInput.vue';
import PickerDay from '@/components/Datepicker/PickerDay.vue';
import PickerMonth from '@/components/Datepicker/PickerMonth.vue';
import PickerYear from '@/components/Datepicker/PickerYear.vue';
import utils, { makeDateUtils } from '@/components/Datepicker/DateUtils';
export default {
	components: {
		DateInput,
		PickerDay,
		PickerMonth,
		PickerYear
	},
	props: {
		value: {
			validator: val => utils.validateDateInput(val)
		},
		name: String,
		refName: String,
		id: String,
		format: {
			type: [String, Function],
			default: 'dd MMM yyyy'
		},
		language: {
			type: Object,
			default: () => en
		},
		openDate: {
			validator: val => utils.validateDateInput(val)
		},
		dayCellContent: Function,
		fullMonthName: Boolean,
		disabledDates: Object,
		highlighted: Object,
		placeholder: String,
		inline: Boolean,
		calendarClass: [String, Object, Array],
		inputClass: [String, Object, Array],
		wrapperClass: [String, Object, Array],
		mondayFirst: Boolean,
		clearButton: Boolean,
		clearButtonIcon: String,
		calendarButton: Boolean,
		calendarButtonIcon: String,
		calendarButtonIconContent: String,
		bootstrapStyling: Boolean,
		initialView: String,
		disabled: Boolean,
		required: Boolean,
		typeable: Boolean,
		useUtc: Boolean,
		minimumView: {
			type: String,
			default: 'day'
		},
		maximumView: {
			type: String,
			default: 'year'
		}
	},
	data () {
		const startDate = this.openDate ? new Date(this.openDate) : new Date();
		const constructedDateUtils = makeDateUtils(this.useUtc);
		const pageTimestamp = constructedDateUtils.setDate(startDate, 1);


		return {
			// Vue cannot observe changes to a Date Object so date must be stored as a timestamp
			// This represents the first day of the current viewing month
			pageTimestamp,
			// Selected Date
			selectedDate: null,
			// Flags to show calendar views
			showDayView: false,
			showMonthView: false,
			showYearView: false,
			// Positioning
			calendarHeight: 0,
			resetTypedDate: new Date(),
			utils: constructedDateUtils
		};
	},
	watch: {
		value (value) {
			this.setValue(value);
		},
		openDate () {
			this.setPageDate();
		},
		initialView () {
			this.setInitialView();
		}
	},
	computed: {
		computedInitialView () {
			if (!this.initialView) {
				return this.minimumView;
			}

			return this.initialView;
		},
		pageDate () {
			return new Date(this.pageTimestamp);
		},

		translation () {
			return this.language;
		},

		calendarStyle () {
			return {
				// eslint-disable-next-line no-undefined
				position: this.isInline ? 'static' : undefined
			};
		},
		isOpen () {
			return this.showDayView || this.showMonthView || this.showYearView;
		},
		isInline () {
			return Boolean(this.inline);
		},
		isRtl () {
			return this.translation.rtl === true;
		}
	},
	methods: {

		// Called in the event that the user navigates to date pages and
		// closes the picker without selecting a date.
		resetDefaultPageDate () {
			if (this.selectedDate === null) {
				this.setPageDate();

				return;
			}
			this.setPageDate(this.selectedDate);
		},

		// Effectively a toggle to show/hide the calendar
		// eslint-disable-next-line consistent-return
		showCalendar () {
			if (this.disabled || this.isInline) {
				return false;
			}
			if (this.isOpen) {
				return this.close(true);
			}
			this.setInitialView();
		},

		// Sets the initial picker page view: day, month or year
		setInitialView () {
			const initialView = this.computedInitialView;

			if (!this.allowedToShowView(initialView)) {
				throw new Error(`initialView '${this.initialView}' cannot be rendered based on minimum '${this.minimumView}' and maximum '${this.maximumView}'`);
			}
			switch (initialView) {
				case 'year':
					this.showYearCalendar();
					break;
				case 'month':
					this.showMonthCalendar();
					break;
				default:
					this.showDayCalendar();
					break;
			}
		},

		// Are we allowed to show a specific picker view?
		allowedToShowView (view) {
			const views = ['day', 'month', 'year'];
			const minimumViewIndex = views.indexOf(this.minimumView);
			const maximumViewIndex = views.indexOf(this.maximumView);
			const viewIndex = views.indexOf(view);

			return viewIndex >= minimumViewIndex && viewIndex <= maximumViewIndex;
		},

		// Show the day picker
		showDayCalendar () {
			if (!this.allowedToShowView('day')) {
				return false;
			}
			this.close();
			this.showDayView = true;

			return true;
		},

		// Show the month picker
		showMonthCalendar () {
			if (!this.allowedToShowView('month')) {
				return false;
			}
			this.close();
			this.showMonthView = true;

			return true;
		},

		// Show the year picker
		showYearCalendar () {
			if (!this.allowedToShowView('year')) {
				return false;
			}
			this.close();
			this.showYearView = true;

			return true;
		},

		// Set the selected date
		setDate (timestamp) {
			const date = new Date(timestamp);

			this.selectedDate = date;
			this.setPageDate(date);
			this.$emit('selected', date);
			this.$emit('input', date);
		},

		// Clear the selected date
		clearDate () {
			this.selectedDate = null;
			this.setPageDate();
			this.$emit('selected', null);
			this.$emit('input', null);
			this.$emit('cleared');
		},

		selectDate (date) {
			this.setDate(date.timestamp);
			if (!this.isInline) {
				this.close(true);
			}
			this.resetTypedDate = new Date();
		},

		selectDisabledDate (date) {
			this.$emit('selectedDisabled', date);
		},

		selectMonth (month) {
			const date = new Date(month.timestamp);

			if (this.allowedToShowView('day')) {
				this.setPageDate(date);
				this.$emit('changedMonth', month);
				this.showDayCalendar();
			} else {
				this.selectDate(month);
			}
		},

		selectYear (year) {
			const date = new Date(year.timestamp);

			if (this.allowedToShowView('month')) {
				this.setPageDate(date);
				this.$emit('changedYear', year);
				this.showMonthCalendar();
			} else {
				this.selectDate(year);
			}
		},

		// Set the datepicker value
		// eslint-disable-next-line complexity
		setValue (date) {
			if (typeof date === 'string' || typeof date === 'number') {
				let parsed = new Date(date);

				// eslint-disable-next-line no-param-reassign
				date = isNaN(parsed.valueOf()) ? null : parsed;
			}
			if (!date) {
				this.setPageDate();
				this.selectedDate = null;

				return;
			}
			this.selectedDate = date;
			this.setPageDate(date);
		},

		// Sets the date that the calendar should open on
		setPageDate (date) {
			if (!date) {
				if (this.openDate) {
					// eslint-disable-next-line no-param-reassign
					date = new Date(this.openDate);
				} else {
					// eslint-disable-next-line no-param-reassign
					date = new Date();
				}
			}
			this.pageTimestamp = this.utils.setDate(new Date(date), 1);
		},

		// Handles a month change from the day picker
		handleChangedMonthFromDayPicker (date) {
			this.setPageDate(date);
			this.$emit('changedMonth', date);
		},

		// Set the date from a typedDate event
		setTypedDate (date) {
			this.setDate(date.getTime());
		},

		// Close all calendar layers
		close (emitEvent) {
			// eslint-disable-next-line no-multi-assign
			this.showDayView = this.showMonthView = this.showYearView = false;
			if (!this.isInline) {
				if (emitEvent) {
					this.$emit('closed');
				}
				document.removeEventListener('click', this.clickOutside, false);
			}
		},

		// Initiate the component
		init () {
			if (this.value) {
				this.setValue(this.value);
			}
			if (this.isInline) {
				this.setInitialView();
			}
		}
	},
	mounted () {
		this.init();
	}
}
// eslint-disable-next-line
;
</script>
