// import { extend, isEmpty } from 'underscore';

// Takes a query string and returns a nicely structured version for our PQRs and Size Verifications
const structuredProductQuery = ( queryFromURL ) => { //eslint-disable-line
	const { year, make, model, trim, option, width, ratio, diameter, uid, tiresize, qty, sku} = queryFromURL;

	const properQueryForPQR = {
		productVehicleYear: year,
		productVehicleMake: make,
		productVehicleModel: model,
		productVehicleTrim: trim,
		productVehicleOption: option,
		productTireWidth: width,
		productTireRatio: ratio,
		productTireDiameter: diameter,
		uid: uid,
		tireSize: tiresize,
		qty: qty,
		sku: sku
	};

	return properQueryForPQR;
};

export { structuredProductQuery };
