import { staggeredConfirmation } from '@/utils/setDataLayer';
import * as actions from './actions';

const store = {
	namespaced: true,
	state: {
		// Whether or not we should be asking the
		// 'Are your tires different sizes?' question
		askSize: false,

		// Keep a store of both arrays of sizes
		allSizes: {
			loose: [],
			staggered: []
		},

		// The type of set we are using
		// Can be either loose or staggered
		selectedSet: '',

		// The sizes the user is going to be choosing from
		currentSizes: [],

		// The size that the user has chosen
		selectedSize: '',

		// Do we need to go to the verify page or not
		needsVerified: false,

		isLoading: false
	},
	// Mutations are commited and must be synchronous
	mutations: {
		setAskSize(state, payload) {
			state.askSize = payload;
		},
		setAllSizes(state, payload) {
			state.allSizes = payload;
		},
		setCurrentSizes(state, payload) {
			state.selectedSet = payload;
			state.selectedSize = '';

			if (payload) {
				const currSizes = state.allSizes[payload].map(size => size.split(','));

				state.currentSizes = currSizes;

				if (currSizes.length === 1) {
					state.selectedSize = currSizes[0].join(',');
				}
			} else {
				state.currentSizes = [];
			}
		},
		setNeedsVerified(state, payload) {
			state.needsVerified = payload;
		},
		selectSize(state, payload) {
			state.selectedSize = payload;

			// Sets data layer
			staggeredConfirmation({
				sizeQuestion: state.allSizes.loose.length && state.allSizes.staggered.length,
				size: payload
			});
		},
		setIsLoading(state, payload) {
			state.isLoading = payload;
		}
	},
	actions
};

export default store;
