import Language from '../Language';

export default new Language(
	'Finnish',
	['tammikuu', 'helmikuu', 'maaliskuu', 'huhtikuu', 'toukokuu', 'kesäkuu', 'heinäkuu', 'elokuu', 'syyskuu', 'lokakuu', 'marraskuu', 'joulukuu'],
	['tammi', 'helmi', 'maalis', 'huhti', 'touko', 'kesä', 'heinä', 'elo', 'syys', 'loka', 'marras', 'joulu'],
	['su', 'ma', 'ti', 'ke', 'to', 'pe', 'la']
)
// eslint-disable-next-line
;
