import axios from 'axios';

export const serviceOfferingsRequest = ({ commit }) => axios
	.get('/service/dealer/serviceOfferings')
	.then(({ data }) => {
		commit('setAvailableOfferings', data);
	}).catch(err => {
		commit('setAvailableOfferings', []);
		throw new Error(err);
	});

export const getServicesInSession = ( { commit }) => {
	axios
		.get('/service/cart')
		.then( ({ data }) => {
			commit('setSelectedServices', data.services);
		}).catch(err => {
			commit('setSelectedServices', []);
			throw new Error(err);
		});
};
export const addMobileInstallFee = ( { dispatch }) => axios
	.get('/service/dealer/serviceOfferings')
	.then(({ data }) => {
		const mobileInstallFee = data.filter(service => service.transportID);

		dispatch('cart/addServicesToCart', mobileInstallFee, { root: true });
	}).catch(err => {
		dispatch('cart/addServicesToCart', [], { root: true });
		throw new Error(err);
	});
