<template>
    <div>
        <h4>{{ $t("message.specs") }}</h4>
        <ul class="details__specs-list">
            <li v-if="isStag" class="row">
                <h5 class="col-4"></h5>
                <h5 class="col-4">{{ $t("message.front") }}</h5>
                <h5 class="col-4">{{ $t("message.rear") }}</h5>
            </li>
            <li class="row details__specs" v-for="(specificationKey, index) in specTitles" :key="index">
                <span class="col-sm-4 details__specs__item">{{ $t(`message.${specs[specificationKey]}`) }}</span>
                <span :class="[{ 'col-sm-4': isStag, 'col-sm-8': !isStag }, 'details__specs__item']"
                      v-for="(tire, tireIndex) in tireSet" :key="tireIndex">
                    {{ tire[specificationKey] | properSpecValue(specificationKey, tire) }}
                    <span v-if="specificationKey === 'weight'">{{ tire.weightUOM }}</span>
                </span>
            </li>
            <li class="row details__specs" v-if="warrantyDoc">
                <span class="col-sm-4 details__specs__item">{{ $t("message.warranty") }}</span>
                <a :href="warrantyDoc" target="_blank" class="details__specs__item">{{ $t("message.download") }}</a>
            </li>
        </ul>
    </div>
</template>

<script>
import { convertBooleans, renameSpecTitlesForDetails } from '@/utils/specification-conversion.js';
import { fullTireMarkDescription } from '@/filters/oem-tire-mark';

export default {
	props: {
		tireSet: {
			type: Array,
			required: true
		},
		warrantyDoc: {
			type: [Boolean, String],
			required: false
		}
	},
	data() {
		return {
			specs: {
				ecoTire: 'ecoTire',
				size: 'size',
				weight: 'weight',
				performanceType: 'performanceType',
				serviceDescription: 'serviceDescription',
				speedRating: 'speedRating',
				sidewall: 'sidewall',
				temp: 'temp',
				tractionGrade: 'traction',
				gradeTreadWear: 'treadWear',
				maxRimWidth: 'maxRim',
				diameter: 'diameter',
				treadDepth: 'treadDepth'
			}
		};
	},
	filters: {
		properTitle(spec) {
			return renameSpecTitlesForDetails(spec);
		},
		properSpecValue(val, specificationKey, tire) {
			if (typeof val === 'boolean') {
				return convertBooleans(val);
			}

			if (specificationKey === 'oemTireMarkDescription') {
				return fullTireMarkDescription(tire);
			}

			return val;
		}
	},
	computed: {
		isStag() {
			return this.tireSet.length > 1;
		},
		specTitles() {
			return Object.keys(this.specs);
		}
	},
	created() {
		if (this.tireSet[0].oemTireMarkDescription) {
			this.specs.oemTireMarkDescription = 'oemTireMarkDescription';
		}
	}
};
</script>
